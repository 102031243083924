import config from '../../../../config';
import { Owner, OwnerUtils } from '../../../Model/Owner';

export async function getOwners(get: (address: string) => Promise<any>): Promise<Owner[]> {
  const ownersUrl = `${config.apiGateway.ITR_API}/api/deviceOwners`;
  const response = await get(ownersUrl);
  if (!response || !Array.isArray(response))
    return [];

  return response.map<Owner>(OwnerUtils.fromResponse);
}
