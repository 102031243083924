import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { CalibrationDetails } from '../Model/TestEquipment';
import CalibratedIcon from './CalibratedIcon';


export const CalibratedCell: React.FC<GridCellProps> = props => {
  const dataItem: CalibrationDetails | undefined = props.field && props.dataItem[props.field];

  return (
    <td className="k-command-cell text-center">
      <CalibratedIcon calibration={dataItem}/>
    </td>
  );
};

