import React from "react";
import ProductStatusIcon from "../ProductStatusIcon";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";

export type ResultValue = 'pass' | 'fail' | 'n/a';

interface Checkbox {
    value: ResultValue;
    variant: string;
}

export interface ExcelStatusSelectProps {
    value: ResultValue[];
    onChange?: (e: ResultValue[]) => void;
}

const ExcelStatusSelect = (props: ExcelStatusSelectProps) => {
    const checkBoxes: Checkbox[] = [
        { value: 'pass', variant: 'success' as const },
        { value: 'fail', variant: 'danger' as const },
        { value: 'n/a', variant: 'warning' as const },
     ];

     return (
        <ToggleButtonGroup type="checkbox" onChange={props.onChange}>
           {checkBoxes.map((checkbox, idx) => {
            return(
              <ToggleButton
                 key={idx}
                 type="checkbox"
                 variant={checkbox.variant}
                 value={checkbox.value}
                 checked={props.value.includes(checkbox.value)}
                 >
                 <ProductStatusIcon value={checkbox.value} invert />
              </ToggleButton>
           )})}
        </ToggleButtonGroup>
     );
}




export default ExcelStatusSelect;