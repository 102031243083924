import * as React from 'react';
import { ChangeEvent } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { State as DataState } from '@progress/kendo-data-query';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Alert, Form } from 'react-bootstrap';

import { Owner, OwnerUtils } from '../Model/Owner';
import { BLANK_BANNER_MESSAGE, createErrorInfoMessage, InfoBanner, InfoMessage } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import FormButtons from '../Shared/FormButtons';
import { PageTitle } from '../Shared/PageTitle';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { getOwner } from '../Shared/Data/Owners/GetOwner';
import { DEFAULT_DATA_STATE } from '../Shared/KendoConstants';
import { parseNumber } from '../Shared/Utils/ParseNumber';
import { getErrorMessageOrDefault } from '../Shared/Utils/GetErrorMessageOrDefault';
import { addUpdateOwner } from '../Shared/Data/Owners/AddUpdateOwner';
import { newId } from '../../config';
import { getOwners } from '../Shared/Data/Owners/GetOwners';
import { caseInsensitiveEquals } from '../Shared/Utils/CaseInsensitiveEquals';
import { SimilarOwners } from './SimilarOwners';
import { OwnerLocations } from './OwnerLocations';
import OwnerLocation from '../Model/OwnerLocation';
import { saveOwnerLocations } from '../Shared/Data/Owners/Locations/SaveOwnerLocations';
import { getOwnerLocations } from '../Shared/Data/Owners/Locations/GetOwnerLocations';
import { ChangeType } from '../Shared/Utils/Changes/ChangeCallback';
import { mapChangeById } from '../Shared/Utils/Changes/MapChangeById';
import { deleteOwnerLocations } from '../Shared/Data/Owners/Locations/DeleteOwnerLocations';
import { duplicates } from '../Shared/Utils/Duplicates';
import { TestEquipmentSerialRanges } from './TestEquipmentSerialRanges';
import { SerialRange, SerialRangeUtils } from '../Model/SerialRange';
import { invalidSerialRangePrefixSeparators } from '../ProductTypes/InvalidSerialRangePrefixSeparators';
import { saveTestEquipmentSerialRanges } from '../Shared/Data/Owners/SaveTestEquipmentSerialRanges';
import CalibrationReminderContacts from './CalibrationReminderContacts';
import { validateEmailAddress } from '../Shared/Utils/ValidateEmailAddress';
import { ListMessage } from '../Shared/ListMessage';
import { saveCalibrationReminderContacts } from '../Shared/Data/Owners/SaveCalibrationReminderContacts';

type LocationState = {
  owners: Owner[],
  owner: Owner | undefined
} | null;

interface ViewOwnerProps extends RouteComponentProps<{ id: string }, StaticContext, LocationState> {
}

interface ViewOwnerState {
  bannerMessage: InfoMessage;
  dataState: DataState;
  owner: Owner | null;
  owners: Owner[];
  locations: OwnerLocation[];
  deletedLocationIds: number[];
}

export class ViewOwner extends ApiResponseHandler<ViewOwnerProps, ViewOwnerState> {
  public constructor(props: ViewOwnerProps) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
      innerState: {
        bannerMessage: BLANK_BANNER_MESSAGE,
        dataState: DEFAULT_DATA_STATE,
        owner: this.editExistingOwnerLocationState(),
        owners: this.ownersLocationState(),
        locations: [],
        deletedLocationIds: [],
      }
    };

    this.get = this.get.bind(this);
    this.put = this.put.bind(this);
    this.query = this.query.bind(this);
  }

  public async componentDidMount() {
    try {
      const ownerId = this.state.innerState.owner?.id;

      const [owner, owners, locations] = await Promise.all([
        this.creatingNewOwner ? OwnerUtils.default : this.getOwner(),
        this.getOwners(),
        ownerId == null ? [] : getOwnerLocations(ownerId, this.get)
      ]);

      if (owner == null && !this.creatingNewOwner)
        this.handleLoadingError('Unable to load device owner at this time');
      else if (owners.length === 0)
        this.handleLoadingError('Unable to load device owners at this time');
      else
        this.handleDataLoaded(owner, owners, locations);
    } catch (e) {
      this.handleLoadingError(getErrorMessageOrDefault(e, 'Unable to load device owner at this time'));
    }
  }

  private get creatingNewOwner(): boolean {
    return this.props.match.params.id === newId;
  }

  private editExistingOwnerLocationState = (): Owner | null =>
    this.creatingNewOwner
      ? OwnerUtils.default
      : this.props.location.state?.owner ?? null;

  private ownersLocationState = (): Owner[] => {
    const { owners, owner } = this.props.location.state ?? {};
    const locationStateOwners = owners ?? [];

    // Filter out the existing owner when editing
    return locationStateOwners.filter(o => o.name !== owner?.name);
  };

  private handleLoadingError = (message: string) =>
    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        bannerMessage: createErrorInfoMessage(message),
      }
    }));

  private handleDataLoaded = (owner: Owner | null, owners: Owner[], locations: OwnerLocation[]) =>
    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        owner,
        owners: owners.filter(o => o.name !== owner?.name),
        locations
      }
    }));

  private getOwner = () =>
    new Promise<Owner | null>((resolve, reject) => {
      // Fetch owner from API if data was not provided in location state (e.g. if this page was opened from a bookmark)
      const currentOwner = this.state.innerState.owner;
      if (currentOwner != null) {
        resolve(currentOwner);
        return;
      }

      const id = this.props.match.params.id;
      const parsedId = parseNumber(id);
      if (isNaN(parsedId)) {
        reject(`Invalid owner ID: ${parsedId}`);
        return;
      }

      getOwner(parsedId, this.get)
        .then(resolve)
        .catch(reject);
    });

  private getOwners = () =>
    new Promise<Owner[]>((resolve, reject) => {
      // Use existing owners if they were passed through location state
      const currentOwners = this.state.innerState.owners;
      if (currentOwners.length > 0) {
        resolve(currentOwners);
        return;
      }

      // Otherwise, fetch owners from API
      getOwners(this.get)
        .then(resolve)
        .catch(reject);
    });

  private handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const newName = e.target.value;
    this.setState(prevState => ({
      innerState: {
        ...prevState.innerState,
        owner: prevState.innerState.owner && {
          ...prevState.innerState.owner,
          name: newName
        }
      }
    }));
  };

  private handleFormButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    switch (e.currentTarget.value) {
      case 'cancel':
        this.props.history.push('/Owners');
        break;
      case 'save':
        this.saveOwner();
        break;
    }
  };

  private saveOwner = () => {
    let owner = this.state.innerState.owner;
    if (!owner)
      return;

    const validationMessage = this.validateForm();
    if (validationMessage.warn || validationMessage.error) {
      this.setState(prevState => ({
        innerState: {
          ...prevState.innerState,
          bannerMessage: validationMessage
        }
      }));

      return;
    }

    this.addOrUpdateOwner(owner)
      .then(ownerResponse => {
        if (!ownerResponse) {
          return;
        }

        owner = ownerResponse;
        this.setState(prevState => ({
          innerState: {
            ...prevState.innerState,
            owner: ownerResponse
          }
        }));
      })
      .then(() => this.saveSerialRanges(owner!))
      .then(() => this.saveOwnerLocations(owner!))
      .then(() => this.saveCalibrationReminderContacts(owner!))
      .then(() => this.props.history.push('/Owners?success=true'))
      .catch(e => this.handleAPIError(e, 'Unable to update owner at this time'));
  };

  // Only update owner entity if creating a new owner, or they are an ITR device owner
  private addOrUpdateOwner = (owner: Owner): Promise<Owner> =>
    this.creatingNewOwner || owner.createdInItr
      ? addUpdateOwner(owner, this.query)
      : Promise.resolve(owner);

  private saveSerialRanges = (owner: Owner) =>
    saveTestEquipmentSerialRanges(this.query, owner!.id, owner.testEquipmentSerialRanges)
      .catch(e => this.handleAPIError(e, 'Unable to save test equipment serial ranges at this time'));

  private saveOwnerLocations = async (owner: Owner): Promise<void> => {
    const { locations, deletedLocationIds } = this.state.innerState;

    // No need to update locations if a change has not been made
    if (locations.length === 0 && deletedLocationIds.length === 0)
      return;

    try {
      await Promise.all([
        saveOwnerLocations(owner.id, locations, this.query),
        deleteOwnerLocations(owner.id, deletedLocationIds, this.query)
      ]);
    } catch (e) {
      this.handleAPIError(e,  'Unable to save locations at this time');
    }
  };

  private saveCalibrationReminderContacts = async (owner: Owner): Promise<void> =>
    saveCalibrationReminderContacts(owner.id, owner.calibrationReminderContacts, this.query)
      .catch(e => this.handleAPIError(e, 'Unable to save calibration reminder contacts at this time'));

  private handleAPIError = (error: unknown, defaultErrorMessage: string) => {
    const message = getErrorMessageOrDefault(error, defaultErrorMessage);
    const bannerMessage = createErrorInfoMessage(message);
    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        bannerMessage: bannerMessage
      }
    }));
  };

  private validateForm = (): InfoMessage => {
    const { owner, owners, locations } = this.state.innerState;

    if (!owner)
      return createErrorInfoMessage('Owner has not been loaded.');

    if (!owner.name)
      return createErrorInfoMessage('You must give the owner a name.');

    if (owner.testEquipmentSerialRanges.length > 0) {
      const serialRangeMessage = this.validateSerialRanges(owner.testEquipmentSerialRanges);
      if (serialRangeMessage.warn || serialRangeMessage.error)
        return serialRangeMessage;
    }

    const locationMessage = this.validateLocations(locations);
    if (locationMessage.warn || locationMessage.error)
      return locationMessage;

    const contactsMessage = this.validateCalibrationReminderContacts(owner.calibrationReminderContacts);
    if (contactsMessage.warn || contactsMessage.error)
      return contactsMessage;

    const ownerWithSameName = owners.find(o => caseInsensitiveEquals(o.name, owner.name));
    if (ownerWithSameName)
      return createErrorInfoMessage(
        <>
          An owner with this name already exists, please edit <Alert.Link href={`/Owners/${ownerWithSameName.id}`}>this owner</Alert.Link> instead
        </>
      );

    return BLANK_BANNER_MESSAGE;
  };

  private validateSerialRanges = (ranges: SerialRange[]): InfoMessage => {
    // Discard any empty ranges
    const nonEmptyRanges = ranges.filter(range => !SerialRangeUtils.isEmpty(range));
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      testEquipmentSerialRanges: nonEmptyRanges
    }));

    // Ranges must have a prefix
    const rangesWithoutPrefix = nonEmptyRanges.filter(range => range.prefix.trim() === '');
    if (rangesWithoutPrefix.length > 0)
      return createErrorInfoMessage('Serial ranges must be given a prefix');

    // Prefixes must end with acceptable suffix
    const rangesWithInvalidPrefix = nonEmptyRanges.filter(range => !SerialRangeUtils.isValidPrefix(range));
    if (rangesWithInvalidPrefix.length > 0)
      return invalidSerialRangePrefixSeparators(rangesWithInvalidPrefix);

    const rangesWhereMaxLessThanMin = nonEmptyRanges.filter(range => range.max < range.min);
    if (rangesWhereMaxLessThanMin.length > 0)
      return createErrorInfoMessage('Serial range maximum must be greater than or equal to minimum');

    return BLANK_BANNER_MESSAGE;
  };

  private validateLocations = (locations: OwnerLocation[]): InfoMessage => {
    const locationNames = locations
      .filter(l => l.id === 0)
      .map(location => location.name);

    const duplicateLocationNames = duplicates(locationNames);

    if (duplicateLocationNames.length > 0)
      return createErrorInfoMessage(
        <ListMessage description="Please remove the following duplicate locations:" list={duplicateLocationNames}/>
      );

    return BLANK_BANNER_MESSAGE;
  };

  private validateCalibrationReminderContacts = (contacts: string[]): InfoMessage => {
    // Duplicates
    const duplicateContacts = duplicates(contacts);
    if (duplicateContacts.length > 0)
      return createErrorInfoMessage(
        <ListMessage description="Please remove the following duplicate contacts:" list={duplicateContacts}/>
      );

    // Invalid email addresses
    const invalidEmails = contacts
      .map(contact => ({
        contact,
        valid: validateEmailAddress(contact)
      }))
      .filter(result => !result.valid)
      .map(result => result.contact);

    if (invalidEmails.length > 0)
      return createErrorInfoMessage(
        <ListMessage description="The following contacts do not have a valid email address:" list={invalidEmails}/>
      );

    return BLANK_BANNER_MESSAGE;
  };

  private handleSerialRangesChange = (updated: SerialRange[]) =>
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      owner: prevInnerState.owner && {
        ...prevInnerState.owner,
        testEquipmentSerialRanges: updated
      }
    }));

  private handleLocationsChange = (type: ChangeType, change: OwnerLocation) => {
    switch (type) {
      case 'add':
        this.setState(prevState => ({
          innerState: {
            ...prevState.innerState,
            locations: prevState.innerState.locations.concat(change),
          }
        }));
        break;
      case 'update':
        this.setState(prevState => ({
          innerState: {
            ...prevState.innerState,
            bannerMessage: BLANK_BANNER_MESSAGE,
            locations: mapChangeById(prevState.innerState.locations, change),
          }
        }));
        break;
      case 'delete':
        this.setState(prevState => ({
          innerState: {
            ...prevState.innerState,
            bannerMessage: BLANK_BANNER_MESSAGE,
            locations: prevState.innerState.locations.filter(item => item.id !== change.id),
            deletedLocationIds: prevState.innerState.deletedLocationIds.concat(change.id),
          }
        }));
        break;
    }
  };

  private handleContactsChange = (contacts: string[]) =>
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      owner: prevInnerState.owner && {
        ...prevInnerState.owner,
        calibrationReminderContacts: contacts
      }
    }));

  render() {
    const { loading, innerState: { bannerMessage, owner, owners, locations } } = this.state;
    const canEditOwner = owner && (this.creatingNewOwner || owner.createdInItr);
    const title = this.creatingNewOwner ? 'Create Owner' : 'Edit Owner';

    return (
      <>
        <InfoBanner message={bannerMessage} />
        <BusyOverlay show={loading} />
        <PageTitle title={title} />
        {
          owner &&
            <Form>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        name="ownerName"
                        required={true}
                        value={owner.name}
                        type="text"
                        placeholder="Enter device owner name"
                        onChange={this.handleNameChange}
                        disabled={!canEditOwner}
                    />
                  {!this.creatingNewOwner && <Form.Text className="sm text-muted m-1">ID: {owner.id}</Form.Text>}
                  {canEditOwner && <SimilarOwners owner={owner} owners={owners} />}

                    <PanelBar>
                        <PanelBarItem title="Test Equipment">
                            <TestEquipmentSerialRanges
                                serialRanges={owner.testEquipmentSerialRanges}
                                onChange={this.handleSerialRangesChange}
                                className="p-3"
                            />
                        </PanelBarItem>

                        <PanelBarItem title="Locations">
                            <OwnerLocations
                                owner={owner}
                                locations={locations}
                                onChange={this.handleLocationsChange}
                                className="p-3"
                            />
                        </PanelBarItem>

                        <PanelBarItem title="Calibration Reminders">
                            <CalibrationReminderContacts
                                contacts={owner.calibrationReminderContacts}
                                onChange={this.handleContactsChange}
                                className="p-3"
                            />
                        </PanelBarItem>
                    </PanelBar>
                </Form.Group>
                <FormButtons handleButtonClick={this.handleFormButtonClick} />
            </Form>
        }
      </>
    );
  }
}
