import { HasId } from '../Types';
import { updateById } from './UpdateById';

/**
 * Immutably update a collection of items, matching on the ID when updating an item.
 * @param items Items to update
 * @param updated Updated item
 */
export const mapChangeById = <T extends HasId>(items: T[], updated: T): T[] =>
  items.map(updateById(updated.id, existingItem => ({
    ...existingItem,
    ...updated
  })));