import { useCallback } from 'react';

import { GridItemChangeEvent } from '@progress/kendo-react-grid';

import { HasId } from '../Types';
import { ChangeCallback } from './ChangeCallback';

/**
 * Callback to invoke when an item in a Kendo grid changes.
 * @param existingItems Existing items
 * @param onChange Callback to invoke when a change occurs
 */
export const useGridItemChangeCallback = <T extends HasId>(existingItems: T[], onChange: ChangeCallback<T>) =>
  useCallback(
    (e: GridItemChangeEvent) => {
      const { field, value, dataItem: { id } } = e;
      if (!field || id == null)
        return;

      const itemToEdit = existingItems.find(item => item.id === id);
      if (!itemToEdit)
        return;

      const updated = {
        ...itemToEdit,
        [field]: value
      };

      onChange('update', updated);
    }, [existingItems, onChange])