const externalSupplierComponents = ['Home', 'AddNewTesRecord'];
const internalUserComponents = ['Home', 'Products', 'AddNewTesRecord', 'Records', 'SuppliersList', 'FaultRepairTypes'];
const externalUserUrl = ['home', 'addrecord', 'dataEntry']
const internalUserUrl = ['home', 'addrecord', 'products', 'producttypes', 'dataentry','records', 'suppliers', 'faultrepairtypes']

const common = {
   adminClaim: 'IsAdmin',
   ITRConfiguratorClaim: 'ITRConfigurator',
   assetManufacturerClaim: 'AssetManufacturerView',
   ITRInternalUser: 'ITRInternalUser',
   ITRSubmitter: 'ITRSubmitter',
   ITRViewer: 'ITRViewer',
   viewAllClaim: 'viewAll',
   externalSupplierView: 'supplierView',
   ITRConfiguratorView: 'ITRConfiguratorView',
   ITRInternalUserView: 'ITRInternalUserView',
   externalSupplierComponents: externalSupplierComponents,
   internalUserComponents: internalUserComponents,
   externalUserUrl: externalUserUrl,
   internalUserUrl: internalUserUrl,
   // Acceptable separator for serial number ranges
   serialNumberPrefixSeparator:  ":",
   naTestResultText: "n/a",
};

const dev = {
   ...common,
   apiGateway: {
      AUTH_API: 'https://auth.sub360test.co.uk',
      META_API: 'https://meta.sub360test.co.uk',
      DATA_API: 'https://data.sub360test.co.uk',
      CONFIG_API: 'https://lvcap.sub360test.co.uk',
      SUBMISSION_API: 'https://submission.sub360test.co.uk',
      ITR_API: 'https://itr.sub360test.co.uk',
      MQTT_SETTING_KEY: 'test',
   },
};

const local = {
   ...dev,
   apiGateway: {
      ...dev.apiGateway,
      ITR_API: 'http://localhost:7000'
   }
}

const prod = {
   ...common,
   apiGateway: {
      AUTH_API: 'https://auth.substation360.co.uk',
      META_API: 'https://meta.substation360.co.uk',
      DATA_API: 'https://data.substation360.co.uk',
      CONFIG_API: 'https://lvcap.substation360.co.uk',
      SUBMISSION_API: 'https://submission.substation360.co.uk',
      ITR_API: 'https://itr.substation360.co.uk',
      MQTT_SETTING_KEY: 'test',
   },
};

const beta = {
   ...common,
   apiGateway: {
      AUTH_API: 'https://auth-beta.substation360.co.uk',
      META_API: 'https://meta-beta.substation360.co.uk',
      DATA_API: 'https://data-beta.substation360.co.uk',
      CONFIG_API: 'https://lvcap-beta.substation360.co.uk',
      SUBMISSION_API: 'https://submission-beta.substation360.co.uk',
      ITR_API: 'https://itr-beta.substation360.co.uk',
      MQTT_SETTING_KEY: 'test',
   },
};

const loadEnvironmentConfig = () => {
   switch (process.env.REACT_APP_TARGET_ENV ?? process.env.NODE_ENV) {
      case "local": return local;
      case "development": return dev;
      case "production": return prod;
      case "beta": return beta;
      default: return dev;
   }
};
const config = loadEnvironmentConfig();

export default config;

export const newId = 'New';

export const keys = {
   assetType: 'Instrument',
   dataEntryFormAttributeKey: 'DataEntryForm',
   fieldMappingsAttributeKey: 'RecordTypeFieldMappings',
   recordTypeAttributeKey: 'RecordTypeAttribute',
   supplierProductAttributeKey: 'SupplierProducts',
   NoSerialNumberPrefixKey: 'NoSerialNumberPrefix',
   ProductTypeItemsKey: 'ProductTypeItems'
};

/**
 * Thresholds to trigger similar name warnings.
 */
export const nameSimilarityThresholds = {
   min: 0.4,
   danger: 0.9,
}

export const sensorId = {
   form: 100009,
   file: 100400,
};

export const productTypeId = {
   testEquipment: 100210,
}
