import React, { useState } from "react";
import { DatePicker, DatePickerProps, ToggleButton, ToggleButtonProps } from "@progress/kendo-react-dateinputs";


const ExcelDatePicker = (props: DatePickerProps) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <DatePicker 
            {...props}
            format={'dd-MM-yyyy'}
            show={show}
            toggleButton={(props: ToggleButtonProps) => (
                <ToggleButton {...props} onClick={()=>setShow(!show)} />
            )}
            onChange={(event) => {
                if(props.onChange)
                    props.onChange(event)
                setShow(false);
            }}
        />
    );
}


export default ExcelDatePicker;