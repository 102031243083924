import React, { CSSProperties, FC, useState } from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';
import { Form } from 'react-bootstrap';

import { EDIT_FIELD } from '../Utils/SetInEdit';
import { useEmailValidationHandler } from './Hooks/UseEmailValidationHandler';
import { useGridCellChangeHandler } from './Hooks/UseGridCellChangeHandler';

const EMAIL_FORM_CONTROL_STYLE: CSSProperties = {
  boxSizing: 'border-box',
};

const EmailAddressCell: FC<GridCellProps> = (props) => {
  const { dataItem, field } = props;
  const [isValid, setIsValid] = useState(true);

  const handleChange = useGridCellChangeHandler(props);
  const handleValidation = useEmailValidationHandler(setIsValid);

  if (!field)
    return null;

  const emailAddress: string = dataItem[field];
  const inEdit: boolean = dataItem[EDIT_FIELD];

  return (
    <td>
      {
        inEdit
          ? (
            <Form.Control
              type="email"
              value={emailAddress}
              onChange={handleChange}
              onBlur={handleValidation}
              style={EMAIL_FORM_CONTROL_STYLE}
              isInvalid={!isValid}
            />
          ) : <span>{emailAddress}</span>
      }
    </td>
  );
};

export default EmailAddressCell;