import OwnerLocation from '../../../../Model/OwnerLocation';
import { APIQuery } from '../../Types';
import config from '../../../../../config';

export const saveOwnerLocations = (ownerId: number, locations: OwnerLocation[], query: APIQuery): Promise<void> => {
  if (locations.length === 0)
    return Promise.resolve();

  const existingLocations = locations.filter(l => l.id > 0);
  const updatePromise = updateOwnerLocations(ownerId, existingLocations, query);

  const newLocations = locations.filter(l => l.id <= 0);
  const addPromise = addOwnerLocations(ownerId, newLocations, query);

  return Promise.all([updatePromise, addPromise]).then();
};

const updateOwnerLocations = (ownerId: number, existingLocations: OwnerLocation[], query: APIQuery): Promise<void> => {
  if (existingLocations.length === 0)
    return Promise.resolve();

  const baseAddress = `${config.apiGateway.ITR_API}/api/deviceOwners/${ownerId}/locations`;
  const promises = existingLocations.map(location => {
    const address = `${baseAddress}/${location.id}`
    const body = toOwnerLocationRequest(location);
    return query(address, 'PUT', JSON.stringify(body));
  });

  return Promise.all(promises).then();
};

const addOwnerLocations = (ownerId: number, newLocations: OwnerLocation[], query: APIQuery) => {
  if (newLocations.length === 0)
    return Promise.resolve();

  const baseAddress = `${config.apiGateway.ITR_API}/api/deviceOwners/${ownerId}/locations`;
  const promises = newLocations.map(location => {
    const body = toOwnerLocationRequest(location);
    return query(baseAddress, 'POST', JSON.stringify(body));
  });

  return Promise.all(promises).then(() => {});
};

const toOwnerLocationRequest = (location: OwnerLocation) => ({ name: location.name });
