import * as React from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IProductType from '../Model/IProductType';
import FormButtons from '../Shared/FormButtons';
import IRecordType, { IRecordTypeFieldMappings, IRecordTypeComponentMapping } from '../Model/IRecordType';
import config, { newId, keys, sensorId } from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import RecordTypeComponents from './RecordTypeComponents';
import { CustomDropdownList } from '../Shared/DropdownList';
import { getProductTypes } from "../Shared/Data/GetProductTypes";
import { isKeyOf } from '../Shared/Utils/IsKeyOf';

export interface RecordTypeProps extends RouteComponentProps<any> {
   id: string;
}

export interface RecordTypeState {
   recordType: IRecordType;
   bannerMessage: InfoMessage;
   formLayout: string;
   fieldMappings: IRecordTypeFieldMappings;
   productTypes: IProductType[];
   categories: string[];
}

type RecordTypeMappingFieldKey = keyof IRecordTypeFieldMappings;

const ProblemReportCategoryText =  'Problem Report';
const CalibrationCategoryText= 'Calibration';
const DespatchFormCategoryText = 'Despatch';

const validation: Record<string, RecordTypeMappingFieldKey[]> = {
   test: ['serialField', 'resultField', 'dateOfRecordField'],
   despatch: ['serialField', 'jobNumberField', 'batchNumberField', 'serialisedItemsField', 'nonSerialisedItemsField', 'customerNameField', 'dateOfRecordField'],
   problemReport: ['serialField', 'faultItemsField', 'repairItemsField', 'dateOfRecordField'],
   calibration: ['serialField', 'dateOfRecordField', 'skipCalibrationField', 'calibrationCertificateField', 'calibrationFrequencyField',
      'calibrationHouseField', 'locationField', 'assetTagField', 'assetDescriptionField']
};

export const FaultItemsFieldPrefix = "faultItemsField";
export const RepairItemsFieldPrefix = "repairItemsField";
export const FaultDescriptivesField = "faultDescriptivesField";
export const RepairDescriptivesField = "repairDescriptivesField";

const validationText: Partial<Record<RecordTypeMappingFieldKey, string>> = {
   serialField: 'Serial Number',
   dateOfRecordField: 'Date of Test',
   resultField: 'Test Result',
   jobNumberField: 'Job Number',
   customerNameField: 'Customer',
   faultDescriptivesField: 'Fault Descriptives',
   repairDescriptivesField: 'Repair Descriptives',
   faultItemsField: 'Fault Items',
   repairItemsField: 'Repair Items',
   skipCalibrationField: 'Calibration Not Required',
   calibrationCertificateField: 'Calibration Certificate',
   calibrationFrequencyField: 'Calibration Frequency',
   calibrationHouseField: 'Calibration House',
   locationField: 'Location',
   assetTagField: 'Physical Serial Number',
   assetDescriptionField: 'Description',
};

class RecordType extends ApiResponseHandler<RecordTypeProps, RecordTypeState> {
   constructor(props: RecordTypeProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: {
            bannerMessage: { message: '', show: false, warn: false, error: false },
            recordType: { id: 0, name: '', captureType: 'file', category: 'Test' },
            fieldMappings: {
               serialField: '',
               parentSerialField: '',
               dateOfRecordField: '',
               resultField: '',
               components: [],
               jobNumberField: '',
               customerNameField: '',
               faultItemsField: '',
               faultDescriptivesField: '',
               repairDescriptivesField: '',
               repairItemsField: '',
               skipCalibrationField: '',
               calibrationCertificateField: '',
               calibrationFrequencyField: '',
               calibrationHouseField: '',
               locationField: '',
               assetTagField: '',
               assetDescriptionField: '',
               batchNumberField: '',
               serialisedItemsField: '',
               nonSerialisedItemsField: '',
            },
            categories: [],
            formLayout: '',
            productTypes: [],
         },
      };
   }

   componentDidMount() {
      let formLayout = this.state.innerState.formLayout;
      let recordType = this.state.innerState.recordType;
      let fieldMappings = this.state.innerState.fieldMappings;

      const id = this.props.match.params.id;

      const recordTypeUrl = config.apiGateway.META_API + '/api/DataPointTypes/' + id;

      this.getCategories();

      getProductTypes(url => this.get(url))
         .then((productTypes) => {
            if (id !== newId) {
               this.get(recordTypeUrl).then((response) => {
                  if (response) {
                     recordType = {
                        name: response.name,
                        id: response.id,
                        captureType: response.valueType,
                        category: response.units,
                     };

                     response.attributes.forEach((a: any) => {
                        if (recordType.captureType === 'form') {
                           if (a.key === keys.dataEntryFormAttributeKey) formLayout = JSON.stringify(JSON.parse(a.value), null, 2);
                        }
                        if (a.key === keys.fieldMappingsAttributeKey) fieldMappings = JSON.parse(a.value);
                        if(this.state.innerState.recordType.category === ProblemReportCategoryText)
                        {
                           fieldMappings.faultItemsField= FaultItemsFieldPrefix;
                           fieldMappings.repairItemsField= RepairItemsFieldPrefix;
                           fieldMappings.faultDescriptivesField = FaultDescriptivesField;
                           fieldMappings.repairDescriptivesField = RepairDescriptivesField;
                        }
                        
                     });
                  }

                  this.setState({
                     loading: false,
                     innerState: {
                        ...this.state.innerState,
                        recordType,
                        fieldMappings,
                        formLayout,
                        productTypes,
                     },
                  });
               });
            } else {
               this.setState({
                  loading: false,
                  innerState: {
                     ...this.state.innerState,
                     productTypes,
                  },
               });
            }
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   getCategories() {
      const categoriesUrl = config.apiGateway.META_API + '/api/Configuration/ITRCATEGORY/';

      let categories: string[] = [];

      this.get(categoriesUrl)
         .then((response) => {
            response.map((cat: any) => {
               categories.push(cat.value);
            });

            this.setState({
               innerState: {
                  ...this.state.innerState,
                  categories,
               },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleButtonClick(e: any) {
      if (e.target.value === 'save') this.saveRecordType();
      else this.props.history.push(`/RecordTypes`);
   }

   saveRecordType() {
      const recordType = this.state.innerState.recordType;

      if (!this.validateForm(recordType.category)) return;

      if (recordType.captureType === 'form') {
         if (!this.validateFormLayout()) {
            return;
         }
      }

      const recordTypeBody = JSON.stringify({
         sensorTypeId: recordType.captureType === 'form' ? sensorId.form : sensorId.file,
         valueType: recordType.captureType,
         units: recordType.category,
         name: recordType.name,
         description: 'description',
      });

      const mappingsAttribute = JSON.stringify({
         key: keys.fieldMappingsAttributeKey,
         value: JSON.stringify(this.state.innerState.fieldMappings),
         name: keys.fieldMappingsAttributeKey,
         description: keys.fieldMappingsAttributeKey,
      });

      const formLayoutAttribute = JSON.stringify({
         key: keys.dataEntryFormAttributeKey,
         value: this.state.innerState.formLayout,
         name: keys.dataEntryFormAttributeKey,
         description: keys.dataEntryFormAttributeKey,
      });

      this.props.match.params.id === newId
         ? this.saveNewRecordType(recordTypeBody, mappingsAttribute, formLayoutAttribute)
         : this.saveExistingRecordType(recordTypeBody, mappingsAttribute, formLayoutAttribute);
   }

   saveExistingRecordType(recordTypeBody: string, mappingsAttribute: string, formLayoutAttribute: string) {
      const recordTypeUrl = config.apiGateway.META_API + '/api/DataPointTypes/' + this.props.match.params.id;
      const recordTypeAttributesUrl = config.apiGateway.META_API + '/api/DataPointTypes/attributes/' + this.props.match.params.id;

      this.put(recordTypeUrl, recordTypeBody)
         .then(() => {
            this.delete(recordTypeAttributesUrl + `/${keys.fieldMappingsAttributeKey}`)
               .then(() => {
                  this.post(recordTypeAttributesUrl, mappingsAttribute)
                     .then(() => {
                        this.delete(recordTypeAttributesUrl + `/${keys.dataEntryFormAttributeKey}`)
                           .then(() => {
                              this.post(recordTypeAttributesUrl, formLayoutAttribute)
                                 .then(() => {
                                    this.props.history.push(`/RecordTypes`);
                                 })
                                 .catch((error) => {
                                    this.handleApiError(error);
                                 });
                           })
                           .catch((error) => {
                              this.handleApiError(error);
                           });
                     })
                     .catch((error) => {
                        this.handleApiError(error);
                     });
               })
               .catch((error) => {
                  this.handleApiError(error);
               });
         })

         .catch((error) => {
            this.handleApiError(error);
         });
   }

   saveNewRecordType(recordTypeBody: string, mappingsAttribute: string, formLayoutAttribute: string) {
      const recordTypeUrl = config.apiGateway.META_API + '/api/DataPointTypes/';
      const recordTypeAttributesUrl = config.apiGateway.META_API + '/api/DataPointTypes/attributes/';

      this.post(recordTypeUrl, recordTypeBody)
         .then((id) => {
            this.post(recordTypeAttributesUrl + id, mappingsAttribute)
               .then(() => {
                  this.post(recordTypeAttributesUrl + id, formLayoutAttribute)
                     .then(() => {
                        this.props.history.push(`/RecordTypes?success=true`);
                     })
                     .catch((error) => {
                        this.handleApiError(error);
                     });
               })
               .catch((error) => {
                  this.handleApiError(error);
               });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleChangeMappingValue(e: { target: { name: string, value: string } }) {
      let fieldMappings = this.state.innerState.fieldMappings;

      const key = e.target.name;
      if (!isKeyOf(fieldMappings, key))
         return;

      if (typeof fieldMappings[key] !== 'string')
         return;

      (fieldMappings[key] as string) = e.target.value;

      this.setState({
         innerState: {
            ...this.state.innerState,
            fieldMappings,
         },
      });
   }

   validateForm(category: string) {
      if (!this.state.innerState.recordType.name) return this.setValidationError('You must give the record type a name.');

      let catValidation: any;

      let fieldMappings = Object.entries(this.state.innerState.fieldMappings);
      let fieldText = Object.entries(validationText);

      switch (category) {
         case 'Test':
            catValidation = validation.test;
            break;
         case 'Despatch':
            catValidation = validation.despatch;
            break;
         case 'Problem Report':
            catValidation = validation.problemReport;
            break;
         case 'Calibration':
            catValidation = validation.calibration;
            break;
         default:
            return true;
      }

      try {
         fieldMappings.forEach(([metakey, metavalue]) => {
           
            if (catValidation.includes(metakey) && !metavalue) {
               fieldText.forEach(([textkey, textvalue]) => {
                  if (textkey === metakey) {
                     throw `${textvalue} is required.`;
                  }
               });
            }
         });
      } catch (error) {
         return this.setValidationError(error);
      }

      return true;
   }

   setValidationError(errorMessage: string) {
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: errorMessage,
               error: true,
            },
         },
      });

      return false;
   }

   handleChangeCaptureType(e: any) {
      this.setState({
         innerState: {
            ...this.state.innerState,
            recordType: { ...this.state.innerState.recordType, captureType: e.target.id },
         },
      });
   }

   handleChangeRecordCategory(e: any) {
      let fieldMappings = this.state.innerState.fieldMappings;
      if(e === ProblemReportCategoryText)
      {
         fieldMappings.faultItemsField= FaultItemsFieldPrefix;
         fieldMappings.repairItemsField= RepairItemsFieldPrefix;
         fieldMappings.faultDescriptivesField = FaultDescriptivesField;
         fieldMappings.repairDescriptivesField = RepairDescriptivesField;
      }
      this.setState({
         innerState: {
            ...this.state.innerState,
            recordType: {
               ...this.state.innerState.recordType,
               category: e,
               captureType: e === 'Calibration' ? 'form' : this.state.innerState.recordType.captureType
            },
            fieldMappings
         },
      });
   }

   handleChangeDataForm(e: any) {
      this.setState({
         innerState: {
            ...this.state.innerState,
            formLayout: e.target.value,
         },
      });
   }

   handleChangeName(e: any) {
      this.setState({
         innerState: {
            ...this.state.innerState,
            recordType: { ...this.state.innerState.recordType, name: e.target.value },
         },
      });
   }

   validateFormLayout(): boolean {
      if (!this.state.innerState.formLayout) return this.setValidationError('No data entry form created');

      let errorText = 'not found in data entry form';

      try {
         let formLayout = JSON.parse(this.state.innerState.formLayout);

         let fieldMappings = Object.entries(this.state.innerState.fieldMappings);
         fieldMappings.forEach(([metakey, metavalue]) => {
            if (metakey === 'components') {
               metavalue.forEach((c: IRecordTypeComponentMapping) => {
                  if (!c.productType.name) throw 'You cannot select an empty product type for a component.';

                  if (!c.serialField) throw 'You must specify a serial field for a component.';

                  if (!this.verifyLayoutVariable(c.serialField, formLayout.controls)) throw `Variable "${c.serialField}" ${errorText}`;
               });
            } else {
               if (metavalue) if (!this.verifyLayoutVariable(metavalue, formLayout.controls)) throw `Variable "${metavalue}" ${errorText}`;
            }
         });

         return true;
      } catch (error) {
         return this.setValidationError(error.toString());
      }
   }

   verifyLayoutVariable(variable: string, controls: any) {
      let found = false;

      controls.forEach((c: any) => {
         if (!found) {
            if (c.type === 'group' || c.type === 'row') found = this.verifyLayoutVariable(variable, c.controls);
            if (c.variable === variable 
               || (c.variable && c.variable.includes(variable) &&  (variable === FaultItemsFieldPrefix || variable === RepairItemsFieldPrefix)
               || ((variable === FaultDescriptivesField || variable === RepairDescriptivesField) && c.variable && (c.variable.includes(FaultItemsFieldPrefix) || c.variable.includes(RepairItemsFieldPrefix))))
               ) 
               {
                  found = true;
               }
               
         }
      });

      return found;
   }

   render() {
      const isCalibrationRecord = this.state.innerState.recordType.category === CalibrationCategoryText;
      const isProblemReport = this.state.innerState.recordType.category === ProblemReportCategoryText;
      const isDespatchForm = this.state.innerState.recordType.category === DespatchFormCategoryText;

      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <div className="title-container">
               <h1 className="page-header">{this.props.match.params.id === newId ? 'Create New' : 'Edit'} Record Type</h1>
            </div>
            <Form>
               <Form.Label>Name</Form.Label>
               <Form.Control
                  name="recordTypeName"
                  required={true}
                  value={this.state.innerState.recordType.name}
                  type="text"
                  placeholder="Enter record type name"
                  onChange={this.handleChangeName.bind(this)}
               />
               <Form.Text className="sm text-muted m-1">ID:{this.state.innerState.recordType.id}</Form.Text>
               <Form.Group>
                  <Container>
                     <Row>
                        <Col>
                           <Form.Label className="mt-3">Capture Type</Form.Label>
                           <Form.Group className="m-1">
                              <Form.Check
                                 inline
                                 label="File"
                                 type="radio"
                                 name="typeRadios"
                                 id="file"
                                 checked={this.state.innerState.recordType.captureType === 'file'}
                                 disabled={isCalibrationRecord}
                                 onChange={this.handleChangeCaptureType.bind(this)}
                              />
                              <Form.Check
                                 inline
                                 label="Form"
                                 type="radio"
                                 name="typeRadios"
                                 id="form"
                                 checked={this.state.innerState.recordType.captureType === 'form'}
                                 onChange={this.handleChangeCaptureType.bind(this)}
                              />
                           </Form.Group>
                        </Col>
                        <Col>
                           <Form.Label className="mt-3">Record Category</Form.Label>
                           <CustomDropdownList
                              data={this.state.innerState.categories}
                              defaultValue={this.state.innerState.recordType.category}
                              onChange={this.handleChangeRecordCategory.bind(this)}
                           />
                        </Col>
                     </Row>
                     {
                        !isDespatchForm &&
                        <>
                           <Row>
                              <Col>
                                 <Form.Label className="mt-3">Serial Number Field</Form.Label>
                                 <Form.Control
                                    name="serialField"
                                    value={this.state.innerState.fieldMappings.serialField}
                                    type="text"
                                    placeholder="Enter the name of the serial number field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                              <Col>
                                 <Form.Label className="mt-3">Parent Serial Number Field</Form.Label>
                                 <Form.Control
                                    name="parentSerialField"
                                    value={this.state.innerState.fieldMappings.parentSerialField}
                                    type="text"
                                    placeholder="Enter the name of the parent serial number field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <Form.Label className="mt-3">Date of {isCalibrationRecord ? 'Calibration' : 'Test'} Field</Form.Label>
                                 <Form.Control
                                    name="dateOfRecordField"
                                    value={this.state.innerState.fieldMappings.dateOfRecordField}
                                    type="text"
                                    placeholder="Enter the name of the date of test field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                              <Col>
                                 <Form.Label className="mt-3">Test Result Field</Form.Label>
                                 <Form.Control
                                    name="resultField"
                                    value={this.state.innerState.fieldMappings.resultField}
                                    type="text"
                                    placeholder="Enter the name of the test result field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <Form.Label className="mt-3">Job Number Field</Form.Label>
                                 <Form.Control
                                    name="jobNumberField"
                                    value={this.state.innerState.fieldMappings.jobNumberField}
                                    type="text"
                                    placeholder="Enter the name of the job number field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                              <Col>
                                 <Form.Label className="mt-3">Customer Field</Form.Label>
                                 <Form.Control
                                    name="customerNameField"
                                    value={this.state.innerState.fieldMappings.customerNameField}
                                    type="text"
                                    placeholder="Enter the name of the customer name field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                        </>
                     }
                     { isProblemReport &&
                     <Row>
                        <Col>
                           <Form.Label className="mt-3">FaultItem Field</Form.Label>
                           <Form.Control
                              name="faultItemsField"
                              value={FaultItemsFieldPrefix}
                              type="text"
                              placeholder="Enter the name of the fault/repair item field"
                              onChange={this.handleChangeMappingValue.bind(this)}
                              disabled
                           />
                        </Col>
                        <Col>
                           <Form.Label className="mt-3">FaultItem Field</Form.Label>
                           <Form.Control
                              name="faultDescriptivesField"
                              value= {FaultDescriptivesField}
                              type="text"
                              placeholder="Enter the name of the fault/repair item field"
                              onChange={this.handleChangeMappingValue.bind(this)}
                              disabled
                           />
                        </Col>
                        <Col>
                           <Form.Label className="mt-3">Repair Item Field</Form.Label>
                           <Form.Control
                              name="repairItemsField"
                              value={RepairItemsFieldPrefix}
                              type="text"
                              placeholder="Enter the name of the repair Items field"
                              onChange={this.handleChangeMappingValue.bind(this)}
                              disabled
                           />
                        </Col>
                        <Col>
                           <Form.Label className="mt-3">FaultItem Field</Form.Label>
                           <Form.Control
                              name="repairDescriptivesField"
                              value={RepairDescriptivesField}
                              type="text"
                              placeholder="Enter the name of the fault/repair item field"
                              onChange={this.handleChangeMappingValue.bind(this)}
                              disabled
                           />
                        </Col>
                     </Row>
                     }
                     {
                        isCalibrationRecord &&
                       <>
                          <Row>
                             <Col>
                                <Form.Label className="mt-3">Calibration Not Required Field</Form.Label>
                                <Form.Control
                                  name="skipCalibrationField"
                                  value={this.state.innerState.fieldMappings.skipCalibrationField}
                                  type="text"
                                  placeholder="Enter the name of the calibration not required field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                             <Col>
                                <Form.Label className="mt-3">Calibration Certificate Field</Form.Label>
                                <Form.Control
                                  name="calibrationCertificateField"
                                  value={this.state.innerState.fieldMappings.calibrationCertificateField}
                                  type="text"
                                  placeholder="Enter the name of the calibration certificate upload field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                          </Row>
                          <Row>
                             <Col>
                                <Form.Label className="mt-3">Calibration Frequency Field</Form.Label>
                                <Form.Control
                                  name="calibrationFrequencyField"
                                  value={this.state.innerState.fieldMappings.calibrationFrequencyField}
                                  type="text"
                                  placeholder="Enter the name of the calibration frequency field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                             <Col>
                                <Form.Label className="mt-3">Calibration House Field</Form.Label>
                                <Form.Control
                                  name="calibrationHouseField"
                                  value={this.state.innerState.fieldMappings.calibrationHouseField}
                                  type="text"
                                  placeholder="Enter the name of the calibration house field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                          </Row>
                          <Row>
                             <Col>
                                <Form.Label className="mt-3">Physical Serial Number Field</Form.Label>
                                <Form.Control
                                  name="assetTagField"
                                  value={this.state.innerState.fieldMappings.assetTagField}
                                  type="text"
                                  placeholder="Enter the name of the physical serial number field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                             <Col>
                                <Form.Label className="mt-3">Description Field</Form.Label>
                                <Form.Control
                                  name="assetDescriptionField"
                                  value={this.state.innerState.fieldMappings.assetDescriptionField}
                                  type="text"
                                  placeholder="Enter the name of the asset description field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                          </Row>
                          <Row>
                             <Col>
                                <Form.Label className="mt-3">Location Field</Form.Label>
                                <Form.Control
                                  name="locationField"
                                  value={this.state.innerState.fieldMappings.locationField}
                                  type="text"
                                  placeholder="Enter the name of the device location field"
                                  onChange={this.handleChangeMappingValue.bind(this)}
                                />
                             </Col>
                          </Row>
                       </>
                     }
                     {
                        isDespatchForm &&
                        <>
                           <Row>
                              <Col>
                                 <Form.Label className="mt-3">Main Serial Number</Form.Label>
                                 <Form.Control
                                    name="serialField"
                                    value={this.state.innerState.fieldMappings.serialField}
                                    type="text"
                                    placeholder="Enter the name of the main serial number field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                              <Col>
                                 <Form.Label className="mt-3">Job Identifier Field</Form.Label>
                                 <Form.Control
                                    name="jobNumberField"
                                    value={this.state.innerState.fieldMappings.jobNumberField}
                                    type="text"
                                    placeholder="Enter the name of the group field containing the job identifying fields"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                           <Row>
                           <Col>
                                 <Form.Label className="mt-3">Batch Number</Form.Label>
                                 <Form.Control
                                    name="batchNumberField"
                                    value={this.state.innerState.fieldMappings.batchNumberField}
                                    type="text"
                                    placeholder="Enter the name of the batch number field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                              <Col>
                                 <Form.Label className="mt-3">Serialised Items</Form.Label>
                                 <Form.Control
                                    name="serialisedItemsField"
                                    value={this.state.innerState.fieldMappings.serialisedItemsField}
                                    type="text"
                                    placeholder="Enter the name of the serialised items field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                           <Row>
                           <Col>
                                 <Form.Label className="mt-3">Non-Serialised Items</Form.Label>
                                 <Form.Control
                                    name="nonSerialisedItemsField"
                                    value={this.state.innerState.fieldMappings.nonSerialisedItemsField}
                                    type="text"
                                    placeholder="Enter the name of the non-serialised items field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                           </Col>
                           <Col>
                                 <Form.Label className="mt-3">Date of Packing Field</Form.Label>
                                 <Form.Control
                                    name="dateOfRecordField"
                                    value={this.state.innerState.fieldMappings.dateOfRecordField}
                                    type="text"
                                    placeholder="Enter the name of the date of packing field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <Form.Label className="mt-3">Customer Field</Form.Label>
                                 <Form.Control
                                    name="customerNameField"
                                    value={this.state.innerState.fieldMappings.customerNameField}
                                    type="text"
                                    placeholder="Enter the name of the customer name field"
                                    onChange={this.handleChangeMappingValue.bind(this)}
                                 />
                              </Col>
                           </Row>
                        </>
                     }
                  </Container>
                  <Form.Group>
                     <Form.Label className="mt-3">Components</Form.Label>
                     <RecordTypeComponents
                        fieldMappings={this.state.innerState.fieldMappings}
                        productTypes={this.state.innerState.productTypes}
                        update={(c: IRecordTypeComponentMapping[]) => {
                           this.setState({
                              loading: false,
                              innerState: {
                                 ...this.state.innerState,
                                 fieldMappings: { ...this.state.innerState.fieldMappings, components: c },
                              },
                           });
                        }}
                     />
                  </Form.Group>
                  <Form.Label className="mt-3">Data Entry Form</Form.Label>
                  <Form.Control
                     as="textarea"
                     name="dataEntryForm"
                     rows={10}
                     onChange={this.handleChangeDataForm.bind(this)}
                     value={this.state.innerState.formLayout}
                  />
               </Form.Group>
            </Form>
            <FormButtons handleButtonClick={this.handleButtonClick.bind(this)} />
         </React.Fragment>
      );
   }
}

export default withRouter(RecordType);
