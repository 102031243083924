import * as React from 'react';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalibrationDetails } from '../Model/TestEquipment';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CalibratedIconProps {
  calibration: CalibrationDetails | undefined;
}

const CalibratedIcon: React.FC<CalibratedIconProps> = ({ calibration }) => {
  const { icon, color } = useMemo((): { icon: IconProp, color: string } => {
    if (!calibration)
      return { icon: 'times-circle', color: 'red' };

    if (calibration.skipped)
      return { icon: 'minus-circle', color: 'grey' };

    return { icon: 'check-circle', color: 'green' };
  }, [calibration]);

  return <FontAwesomeIcon icon={icon} color={color} />;
};

export default CalibratedIcon;