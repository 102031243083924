import Endpoint from '../Endpoint';
import Dependency from './Dependency';

export interface DataEntryFormControlCheck {
  suppliers: boolean;
  producttypes: boolean;
  products: boolean;
  recordtypes: boolean;
  variables: Record<string, boolean | 'Pass' | 'Fail' | null>;
  errorString: string;
  faultrepairitems: boolean;
  faultdescriptives: boolean;
  repairdescriptives: boolean;
  locations: boolean;
  calibrators: boolean;
  serialChecks: Record<string, null>;
  endpoints: Record<string, Endpoint>;
  dependencies: Dependency[];
  testGroups: {};
  serialGroupResults: string[];
  nonSerialGroupResults: string[];
}

export const createDefaultEntryFormCheck = (): DataEntryFormControlCheck => ({
  suppliers: false,
  producttypes: false,
  products: false,
  recordtypes: false,
  faultrepairitems: false,
  faultdescriptives: false,
  repairdescriptives: false,
  locations: false,
  calibrators: false,
  variables: {},
  errorString: '',
  serialChecks: {},
  endpoints: {},
  dependencies: [],
  testGroups: {},
  serialGroupResults: [],
  nonSerialGroupResults: []
});