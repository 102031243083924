import ISurveyRepairType from "../../../Model/ISurveyRepairType";
import config from "../../../../config";
import { ApiFaultRepairDescriptive } from "../../../Model/API/ApiFaultRepairType";

export async function getFaultRepairDescriptive(get: (address: string) => Promise<any>, id: number): Promise<ISurveyRepairType | null> {
  const faultRepairTypesUrl = `${config.apiGateway.ITR_API}/api/faultRepairTypes/descriptives/${id}`;
  const response: ApiFaultRepairDescriptive | null = await get(faultRepairTypesUrl);
  if (!response)
    return null;

  return {
    id: response.id,
    name: response.name,
    itemDescriptive: 'Descriptive',
    faultRepair: response.descriptiveType,
  }
}