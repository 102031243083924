import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { GridActionsCell, GridActionsCellProps } from '../GridActionsCell';

type ActionCellProps<T> = GridActionsCellProps<T> & GridCellProps;

export function ActionCell<T>(props: ActionCellProps<T>) {
  const Cell = GridActionsCell({ ...props });
  return <Cell {...props} />;
}

export function createActionCell<T>(props: GridActionsCellProps<T>) {
  return (gridCellProps: GridCellProps) => <ActionCell {...gridCellProps} {...props}/>;
}