import * as React from 'react';
import { JwtManager } from './JwtManager';
import config from '../../config';

export default class TokenRefresher extends React.PureComponent {
    tokenManager = new JwtManager({});

    refreshToken(): Promise<any> {
        var refreshToken = this.tokenManager.getRefreshToken();
        if (!refreshToken)
            return Promise.reject(Error("No refresh token cookie."));
        var postBody = `grant_type=refresh_token&refresh_token=${refreshToken}`;
        return fetch(`${config.apiGateway.AUTH_API}/api/token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: postBody
        }).then(resp => {
            if (resp.ok) {
                return resp.json().then(js => {
                    this.tokenManager.storeAuthResponse(js.token, js.expires, js.refresh_token);
                    return js.token;
                });
            } else {
                return Promise.reject(Error(`Token couldn't be refresh, HTTP code ${resp.status}: ${resp.statusText}`));
            }
        });
    }
}