import React, { Component } from "react";
import "./Dropzone.css";

const UploadLogo = require("./baseline-cloud_upload-24px.svg") as string;

interface DropzoneProps {
  fileUploadtext: string;
  name: string;
  disabled: boolean;
  multiple: boolean;
  accept: string;
  onFilesAdded?: (files: File[]) => void;
}

interface DropzoneState {
  hightlight: boolean;
}

class Dropzone extends Component<DropzoneProps, DropzoneState> {
  fileInputRef: React.RefObject<HTMLInputElement>;
  static defaultProps: DropzoneProps;
  constructor(props: DropzoneProps) {
    super(props);
    this.state = { hightlight: false };
    
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  //function to open file browser
  openFileDialog() {
    if (this.props.disabled) return;
    if(this.fileInputRef.current !== null)
      this.fileInputRef.current.click();
  }

  onFilesAdded(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.disabled) return;
    const files = e.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  fileListToArray(list: FileList | null): File[] {
    if (!list)
      return [];

    const array: File[] = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i)!);
    }
    return array;
  }

  onDragOver(evt: any) {
    evt.preventDefault();
  
    if (this.props.disabled) return;
  
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event: any) {
    event.preventDefault();
  
    if (this.props.disabled) return;
  
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }
  render() {
    return (
        <div 
        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer"}}
        >
        <img
          alt="upload"
          className="Icon"
          src={UploadLogo}
        />
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          name={this.props.name}
          multiple={this.props.multiple}
          onChange={this.onFilesAdded}
          accept={this.props.accept}
        />
        <span>{this.props.fileUploadtext}</span>
      </div>
    )
  }
}

Dropzone.defaultProps = {
  fileUploadtext: '',
  disabled: false,
  multiple: true,
  name: 'fileUpload',
  accept:  '.docx, .png, .svg, .xls, .zip, .pdf',
}
export default Dropzone;