import React from 'react'
import { SerialInput } from '.././SerialInput';


export default function CustomSerialFilter(props: any) {

   const onProductCodeChange = (event: any) => {
      const value = event.target.value;
      const { firstFilterProps } = props;
      firstFilterProps.onChange({
         value: { productCode: value, serialNumber: firstFilterProps.value?.serialNumber },
         operator: 'eq',
         syntheticEvent: event.syntheticEvent,
      });
   };

   const onSerialChange = (event: any) => {
      const value = event.target.value;
      const { firstFilterProps } = props;
      firstFilterProps.onChange({
         value: { productCode: firstFilterProps.value?.productCode, serialNumber: value },
         operator: 'eq',
         syntheticEvent: event.syntheticEvent,
      });
   };

   return (
      <div>
         Serial Number Prefix
         <SerialInput type="text" onChange={onProductCodeChange} value={props.firstFilterProps.value?.productCode || ''} />
         Serial Number
         <SerialInput type="text" onChange={onSerialChange} value={props.firstFilterProps.value?.serialNumber || ''} />
      </div>
   );
}
