import React, { useRef } from 'react';
import TestEquipment from '../../Model/TestEquipment';
import { Button, Modal } from 'react-bootstrap';
import { TestEquipmentLabel } from './TestEquipmentLabel';
import { useReactToPrint } from 'react-to-print';

interface PrintTestEquipmentLabelModalProps {
  show: boolean;
  testEquipment: TestEquipment;
  qrCodePayload: string;
  onClose: () => void;
}

export const PrintTestEquipmentLabelModal: React.FC<PrintTestEquipmentLabelModalProps> = ({ show, testEquipment, qrCodePayload, onClose }) => {
  const labelRef = useRef(null);
  const handlePrint = useReactToPrint({ content: () => labelRef.current });

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Print Test Equipment Label</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
        <TestEquipmentLabel ref={labelRef} serialNumber={testEquipment.serialNumber} qrCodePayload={qrCodePayload} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handlePrint}>
          Print
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

