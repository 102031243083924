import * as React from "react";
import DropdownList from "react-widgets/lib/DropdownList";
import { placeholder, isGenericTypeAnnotation } from "@babel/types";
import { element } from "prop-types";

export interface DropdownListState {
   value?: any;
}

export interface DropdownListProps {
   data: any;
   onChange?: any;
   valueSavedReset?: boolean | undefined;
   selectText?: string;
   placeholder?: string;
   defaultValue?: any;
   reset?: boolean;
   sendEvent?: boolean; //to create a event object instead of sending value directly;
   parent?: string;
   name?: string;
   disabled?: boolean;
   className?: string;
}

export class CustomDropdownList extends React.Component<DropdownListProps, DropdownListState> {
   constructor(props: DropdownListProps) {
      super(props);
      {
         this.state = {
            value: undefined,
         };
         this.onDropdownChange = this.onDropdownChange.bind(this);
      }
   }

   componentDidUpdate(previousProps: DropdownListProps) {
      if (previousProps.data !== this.props.data) {
         this.setState({
            value: undefined,
         });
      }
   }

   onDropdownChange(value: any) {
      if (this.props.sendEvent) {
         // added for dynamic field generation - asset config page
         let events = document.createElement("INPUT");
         events.setAttribute("value", value);
         if (this.props.parent) events.setAttribute("data-parent", this.props.parent);
         if (this.props.name) events.setAttribute("name", this.props.name);
         this.setState({ value: value }, () => {
            if (this.props.onChange) this.props.onChange(events);
         });
      } else {
         this.setState({ value: value }, () => {
            if (this.props.onChange) this.props.onChange(value);
         });
      }
   }
   render() {
      return (
         <DropdownList
            filter="contains"
            valueField="id"
            textField={this.props.selectText && this.props.selectText !== "" ? this.props.selectText : "name"}
            defaultValue=""
            disabled={this.props.disabled}
            value={
               this.props.reset
                  ? ""
                  : this.props.defaultValue
                  ? this.props.defaultValue
                  : !this.props.valueSavedReset && this.state.value !== undefined
                  ? this.state.value
                  : ""
            }
            placeholder={this.props.placeholder}
            data={this.props.data}
            onChange={this.onDropdownChange}
            name={this.props.name}
         />
      );
   }
}
