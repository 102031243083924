import TestEquipment from '../../Model/TestEquipment';
import { useMemo } from 'react';
import { AssetQRCode } from '../../Model/AssetQRCode';
import config from '../../../config';

interface ITRQRCode extends AssetQRCode {
  id: number;
}

export const useTestEquipmentQRPayload = (testEquipment: TestEquipment) =>
  useMemo(() => JSON.stringify(toQRCode(testEquipment)), [testEquipment]);

const toQRCode = (testEquipment: TestEquipment): ITRQRCode => ({
  id: testEquipment.id,
  t: removeSeparatorFromPrefix(testEquipment.serialNumber.prefix),
  s: testEquipment.serialNumber.value,
});

const removeSeparatorFromPrefix = (prefix: string | undefined): string | undefined => {
  if (!prefix)
    return undefined;

  const { serialNumberPrefixSeparator } = config;
  if (prefix.endsWith(serialNumberPrefixSeparator))
    return prefix.substring(0, prefix.length - serialNumberPrefixSeparator.length);

  return prefix;
};