import { APICallback } from '../../Types';
import config from '../../../../../config';
import OwnerLocation from '../../../../Model/OwnerLocation';
import { nameSorter } from '../../../Utils/FieldSorter';

export const getOwnerLocations = async (ownerId: number, get: APICallback): Promise<OwnerLocation[]> => {
  const address = `${config.apiGateway.ITR_API}/api/deviceOwners/${ownerId}/locations`;
  const response: OwnerLocation[] | null = await get(address);
  if (!response)
    return [];

  return response.sort(nameSorter);
}