import { Button, Spinner } from "react-bootstrap";
import * as React from "react";

export function LoadingButton() {
  return (
    <Button variant="secondary" disabled>
      <Spinner
        animation="border"
        size="sm"
        role="status"
        aria-label="Loading document"
      />
    </Button>
  );
}