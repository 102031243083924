import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductStatusIcon = (props: any) => {
   return props.value.toLowerCase() === 'pass' ? (
      <FontAwesomeIcon icon={'check-circle'} color={props.invert ? 'white' : 'green'} size={props.size} />
   ) : props.value.toLowerCase() === 'fail' ? (
      <FontAwesomeIcon icon={'times-circle'} color={props.invert ? 'white' : 'red'} size={props.size} />
   ) : (
      <FontAwesomeIcon icon={'question-circle'} color={props.invert ? 'white' : 'orange'} size={props.size} />
   );
};

export default ProductStatusIcon;
