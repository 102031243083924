import IProductType from './IProductType';

// Data layout for Fault/Repair/Quotation codes
export default interface ISurveyRepairType {
  id: number,
  name: string,
  itemDescriptive: 'Item' | 'Descriptive' | '',
  faultRepair?: SurveyRepairCategory | '',
  productType?: IProductType
  productTypeName?: string
}

export type SurveyRepairCategory = 'Fault' | 'Repair' | 'Quotation';

// Configuration domains for supported survey/repair categories
export enum SurveyRepairConfigurationDomain {
  Fault = 'ITRFAULTDESCRIPTIVE',
  Repair = 'ITRREPAIRDESCRIPTIVE',
  Quotation = 'ITRQUOTATIONDESCRIPTIVE',
}

// Default description for new survey/repair configuration entities
export const SurveyRepairDomainDescriptions: Record<SurveyRepairConfigurationDomain, string> = {
  [SurveyRepairConfigurationDomain.Fault]: 'A fault descriptive',
  [SurveyRepairConfigurationDomain.Repair]: 'A repair descriptive',
  [SurveyRepairConfigurationDomain.Quotation]: 'A quotation descriptive',
};