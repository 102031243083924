import * as React from "react";
import { Button } from "react-bootstrap";

export interface FormButtonsProps {
   handleButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FormButtons: React.SFC<FormButtonsProps> = (props: FormButtonsProps) => {
   return (
      <React.Fragment>
         <Button className="ml-2 float-right" variant="secondary" type="button" value="cancel" onClick={props.handleButtonClick}>
            Cancel
         </Button>
         <Button className="ml-2 float-right" variant="primary" type="button" value="save" onClick={props.handleButtonClick}>
            Save
         </Button>
      </React.Fragment>
   );
};

export default FormButtons;
