import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";


interface CustomMultiSelectProps {
    data: any;
    onChange?: (e: MultiSelectChangeEvent) => void;
    value?: any;
    maxValues?: number;
    filterable?: boolean;
    disabled?: boolean;
    placeholder?: string;
    name?: string;
    textField?: string;
    autoClose?: boolean;
    className?: string;
    selectAll?: boolean;
    // this is the maximum number of values shown as selected in the multiselect textbox
    maxDisplayedValues?: number;
    style?: React.CSSProperties;
}


const CustomMultiSelect = (props: CustomMultiSelectProps) => {
    const selectAll = useMemo(() => (props.textField ? {[props.textField]: 'Select All'} : 'Select All'), [props.textField]);
    const [data, setData] = useState<Array<any>>(props.selectAll ? [selectAll, ...props.data] : props.data);
    const [value, setValue] = useState<Array<any>>(props.value ? props.value : []);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<number>(props.value ? props.value.length : 0);
    const displayText = useMemo(() => {
        const maxDisplayedValuesReached = props.maxDisplayedValues && selectedCount > props.maxDisplayedValues;
        if (maxDisplayedValuesReached || allSelected) {
            return [{
                text: `${selectedCount} items selected`,
                data: value
            }];
        } else {
            return value.map((v: any) => ({
                text: props.textField ? v[props.textField] : v,
                data: [v]
            }));
        }
    }, [selectedCount, props.maxDisplayedValues, allSelected, value, props.textField]);


    useEffect(() => setData(props.selectAll ? [selectAll, ...props.data] : props.data), [props.data, props.textField]);

    useEffect(() => {
        if (value.length === data.length - 1) {
            setValue([data[0], ...value]);
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [value, data])

    useEffect(() => {
        setValue(props.value)
        setSelectedCount(props.value.length);
    }, [props.value]);

    const onSelectChange = (e: MultiSelectChangeEvent) => {
        const allSelectedNow = value.some((v: any) => props.textField ? v[props.textField] === 'Select All' : v === 'Select All');
        const allSelectedNext = e.value.some((v: any) => props.textField ? v[props.textField] === 'Select All' : v === 'Select All');
        const belowMaxSelected = !props.maxValues ? 
            true : 
            (e.value.length <= props.maxValues && !allSelectedNext) || (data.length-1 <= props.maxValues && allSelectedNext);
        
        let newVal: any[] = [];
        if (belowMaxSelected && props.onChange) {
            if (allSelectedNext && !allSelectedNow) {
                newVal = props.data;
                setValue([data[0], ...newVal]);
                setAllSelected(true);
            } else if (!allSelectedNext && allSelectedNow) {
                newVal = [];
                setValue(newVal);
                setAllSelected(false);
            } else if(allSelectedNext && allSelectedNow) {
                if (e.value.length < data.length) {
                    newVal = e.value.filter((v:any) => {
                        if (props.textField)
                            return v[props.textField] !== data[0][props.textField];
                        else
                            return v !== data[0];
                    });
                    setValue(newVal);
                    setAllSelected(false);
                } else {
                    setValue([data[0], ...newVal]);
                    setAllSelected(true);
                }
            } else {
                newVal = e.value;
                setValue(newVal);
            }
            setSelectedCount(newVal.length);
            props.onChange({...e, value: newVal});
        }
    }

    const onFilterChange = (e: MultiSelectFilterChangeEvent) => {
        if(props.filterable)
            setData(filterBy(props.data.slice(0), e.filter));
    }


    const renderMultiSelect = () => {
        return (
            <MultiSelect 
                data={data}
                defaultValue={[]}
                disabled={props.disabled}
                value={value ? value : []}
                placeholder={props.placeholder}
                name={props.name}
                filterable={props.filterable}
                onFilterChange={onFilterChange}
                onChange={onSelectChange}
                textField={props.textField}
                autoClose={props.autoClose}
                className={props.className}
                tags={displayText}
                style={props.style}
            />
            
        );
    }

    const counterWrapper = (inElement: JSX.Element) => {
        return (
            <React.Fragment>
                <div style={{display:"inline-block", width:"95%"}} className="mr-1">
                    {inElement}
                </div>
                <p style={{display:"inline-block", width:"4%", textAlign:"center"}}>{props.value.length}/
                {props.maxValues}</p>
            </React.Fragment>
        );
    }


    return !props.maxValues ? renderMultiSelect() : counterWrapper(renderMultiSelect());
}


export default CustomMultiSelect;