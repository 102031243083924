import * as React from 'react';
import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { GridActionsCell } from '../Shared/GridActionsCell';
import { process } from '@progress/kendo-data-query';
import { IRecordTypeFieldMappings, IRecordTypeComponentMapping } from '../Model/IRecordType';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import IProductType from '../Model/IProductType';
import DropDownGridCell from '../DropDownGridCell';

export interface RecordTypeComponentsProps {
   fieldMappings: IRecordTypeFieldMappings;
   productTypes: IProductType[];
   update: any;
}

export interface RecordTypeComponentsState {
   dataState: any;
   editId?: number;
   lastId: number;
}

const dataState = {
   take: 3,
   skip: 0,
};

class RecordTypeComponents extends Component<RecordTypeComponentsProps, RecordTypeComponentsState> {
   actionCell: any;
   productTypeCell: any;

   constructor(props: RecordTypeComponentsProps) {
      super(props);
      this.state = { dataState, lastId: 0 };

      this.actionCell = GridActionsCell({ onDelete: this.deleteComponent.bind(this) });
      this.productTypeCell = (props: any) => (
         <DropDownGridCell {...props} fieldName="productType" list={this.props.productTypes} handleChange={this.handleChangeProductType.bind(this)} />
      );
   }

   deleteComponent(component: IRecordTypeComponentMapping) {
      this.props.fieldMappings.components = this.props.fieldMappings.components.filter((c) => c.id !== component.id);
      this.props.update(this.props.fieldMappings.components);
   }

   handleAddNewComponent() {
      let lastId = this.state.lastId + 1;

      this.props.fieldMappings.components.push({
         id: lastId,
         serialField: '',
         productType: { id: lastId, name: '' },
      } as IRecordTypeComponentMapping);

      this.setState({
         editId: lastId,
         lastId,
      });

      this.props.update(this.props.fieldMappings.components);
   }

   rowClick(event: any) {
      this.setState({
         editId: event.dataItem.id,
      });
   }

   itemChange(event: any) {
      const inEdit = event.dataItem.id;
      const data = this.props.fieldMappings.components.map((c) => (c.id === inEdit ? { ...c, [event.field]: event.value } : c));

      this.props.fieldMappings.components = data;

      this.setState({
         editId: inEdit,
      });

      this.props.update(this.props.fieldMappings.components);
   }

   handleChangeProductType(componentId: number, newProductType: IProductType) {
      let fieldMappings = this.props.fieldMappings;
      this.props.fieldMappings.components = fieldMappings.components.map((c) => (c.id === componentId ? { ...c, productType: newProductType } : c));
      this.props.update(this.props.fieldMappings.components);
   }

   render() {
      return (
         <React.Fragment>
            <Grid
               pageable
               {...this.state.dataState}
               data={process(
                  this.props.fieldMappings.components.map((c) => ({
                     ...c,
                     inEdit: c.id === this.state.editId,
                  })),
                  this.state.dataState
               )}
               onDataStateChange={(e) => {
                  this.setState({ dataState: e.data });
               }}
               editField="inEdit"
               onRowClick={this.rowClick.bind(this)}
               onItemChange={this.itemChange.bind(this)}>
               <Column
                  field="serialField"
                  title="Serial Field"
                  editor="text"
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={this.props.fieldMappings.components} expanded={true} />}
               />
               <Column
                  field="productType"
                  title="Product Type"
                  cell={this.productTypeCell}
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={this.props.fieldMappings.components} expanded={true} />}
               />

               <Column title="Actions" width="100em" cell={this.actionCell} filterable={false} />
            </Grid>
            <Button className="mt-2" variant="primary" onClick={() => this.handleAddNewComponent()}>
               Add new component
            </Button>
         </React.Fragment>
      );
   }
}

export default RecordTypeComponents;
