import { SkippedCalibrationDetails } from '../../../Model/TestEquipment';
import { TestEquipmentCard, TestEquipmentCardBody} from '../TestEquipmentCard';
import React from 'react';
import { CommonTestEquipmentCardProps } from './Types';
import TestEquipmentDescription from './TestEquipmentDescription';

interface CalibrationSkippedCardProps extends CommonTestEquipmentCardProps {
  assetTag: string;
  description: string;
  deviceOwner: string;
  location: string;
  calibration: SkippedCalibrationDetails;
}

export function CalibrationSkippedCard({ calibration, deviceOwner, location, description, ...cardProps }: CalibrationSkippedCardProps) {
  return (
    <TestEquipmentCard
      {...cardProps}
      variant='valid'
    >
      <TestEquipmentCardBody>
        <TestEquipmentDescription description={description}/>
        <p>Calibration not required</p>
      </TestEquipmentCardBody>
    </TestEquipmentCard>
  );
}

