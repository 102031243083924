import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import ProductStatusIcon from './ProductStatusIcon';

export function GridStatusCell() {
   return class extends GridCell {
      render() {
         const value = this.props.dataItem[this.props.field as string];
         return (
            <td className="k-command-cell text-center">
               <ProductStatusIcon value={value} />
            </td>
         );
      }
   };
}
