/**
 * Location associated with a device owner. Represents organisations/place where a device may currently be located.
 */
export default class OwnerLocation {
  public id: number;
  public name: string;

  constructor(id: number, name?: string) {
    this.id = id;
    this.name = name ?? "";
  }

  public isNew = (): boolean => {
    return this.id <= 0;
  }
}