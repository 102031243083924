import React from 'react';
import './Progress.css';

interface Progressprops {
  progress: number;
}

class Progress extends React.Component<Progressprops,{}> {
    constructor(props: Progressprops) {
      super(props);
      this.state = {};
    }
    render() {
      return (
        <div className="ProgressBar">
          <div
            className="Progress"
            style={{ width: this.props.progress + "%" }}
          />
        </div>
      );
    }
  }
  
  export default Progress;