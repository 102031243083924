import config from '../../config';

export interface AssetQRCode {
  // Asset type
  t: string | undefined;

  // Asset serial number without prefix
  s: string;
}

export const rebuildFullSerialNumber = (qrCode: AssetQRCode): string => {
  const prefix = qrCode.t
    ? qrCode.t + config.serialNumberPrefixSeparator
    : '';

  return prefix + qrCode.s;
};

export const parseSerialNumberFromAssetQRCode = (json: string) => {
  const parsed: AssetQRCode = JSON.parse(json);
  return rebuildFullSerialNumber(parsed);
};

/**
 * Parse a value which might be JSON for a QR code, or a fully formatted serial number.
 * In either case, the full serial number will be returned.
 * @param value
 */
export const parseUnknownSerialNumber = (value: string): string => {
  // Check if the value can be parsed as JSON
  try {
    const parsed: unknown = JSON.parse(value);
    if (isAssetQRCode(parsed)) {
      return rebuildFullSerialNumber(parsed);
    }
  } catch {
    // Assume the value is a formatted serial number if it can't be parsed as JSON
    return value;
  }

  return value;
};

const isAssetQRCode = (value: unknown): value is AssetQRCode =>
  typeof value === 'object'
  && value != null
  && 's' in value;