import { Button, ButtonGroup, Modal } from "react-bootstrap";
import React, { useCallback } from "react";
import { UploadedDocument } from "../Model/UploadedDocument";
import { triggerDocumentDownload } from "../Shared/Utils/TriggerDocumentDownload";

interface DocumentModalProps {
  contentDataURI: string;
  document: UploadedDocument;
  onClose: () => void;
}

export function DocumentModal(props: DocumentModalProps) {
  const { contentDataURI, document, onClose } = props;
  const { contentType, originalFilename } = document;

  const handleDownload = useCallback(() => triggerDocumentDownload(contentDataURI), [contentDataURI]);

  return (
    <Modal show={contentDataURI != null && contentDataURI !== ''} onHide={onClose}>
      <Modal.Header closeButton>
        {originalFilename}
      </Modal.Header>

      <Modal.Body>
        <embed
          src={contentDataURI}
          type={contentType}
          className="w-100"
          style={{ height: '60vh' }}
        />
      </Modal.Body>

      <Modal.Footer>
        <ButtonGroup>
          <Button variant="primary" onClick={handleDownload}>
            Download
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}