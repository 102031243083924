import React from 'react';
import { CustomDropdownList } from './Shared/DropdownList';

export interface CellProps {
   dataItem: any;
   list: any[];
   handleChange: (key: number, value: any) => void;
   fieldName?: string;
}

export interface CellState {}

class DropDownGridCell extends React.Component<CellProps, CellState> {
   onChange(key: number, value: any) {
      this.props.handleChange(key, value);
   }

   render() {
      var dataItem: any;
      if (this.props.fieldName) {
         dataItem = this.props.dataItem[this.props.fieldName];
      } else dataItem = this.props.dataItem;

      return (
         <td>
            <CustomDropdownList
               data={this.props.list}
               defaultValue={this.props.list.find((d: { id: number }) => d.id === dataItem.id)?.name}
               onChange={this.onChange.bind(this, dataItem.id)}
            />
         </td>
      );
   }
}

export default DropDownGridCell;
