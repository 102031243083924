import * as React from 'react';
import { Component } from 'react';
import ISupplierProduct from '../Model/ISupplierProduct';
import { GridItemChangeEvent, GridRowClickEvent } from '@progress/kendo-react-grid';
import { GridActionsCell } from '../Shared/GridActionsCell';
import { SerialRangeGrid } from '../ProductTypes/SerialRangeGrid';

export interface SupplierProductProps {
  dataItem: ISupplierProduct;
}

export interface SupplierProductState {
  dataState: any;
  lastId: number;
  editId?: number;
}

const dataState = {
  take: 3,
  skip: 0,
};

class SupplierProduct extends Component<SupplierProductProps, SupplierProductState> {
  actionCell: any;

  constructor(props: SupplierProductProps) {
    super(props);
    this.state = { lastId: 0, dataState: dataState };
  }

  componentDidMount() {
    this.actionCell = GridActionsCell({ onDelete: this.deleteSerialRange.bind(this) });

    this.setState({
      ...this.state,
      lastId: this.props.dataItem.serialRanges.length,
    });
  }

  private deleteSerialRange = (serialRange: any) => {
    this.props.dataItem.serialRanges = this.props.dataItem.serialRanges.filter((r: { id: number }) => r.id !== serialRange.id);
    this.props.dataItem.updateSerialRange(this.props.dataItem);
  }

  private handleAddNewSerialRange = () => {
    const lastId = this.state.lastId + 1;
    this.setState({ lastId, editId: lastId });
    this.props.dataItem.serialRanges.push({ id: lastId });
    this.props.dataItem.updateSerialRange(this.props.dataItem);
  }

  private rowClick = (event: GridRowClickEvent) => {
    console.log(event);
    this.setState({
      editId: event.dataItem.id,
    });
  }

  private itemChange = (event: GridItemChangeEvent) => {
    const { field } = event;
    if (!field)
      return;

    const inEditId = event.dataItem.id;
    this.props.dataItem.serialRanges = this.props.dataItem.serialRanges.map((range: { id: any }) =>
      range.id === inEditId ? { ...range, [field]: event.value } : range
    );
    this.props.dataItem.updateSerialRange(this.props.dataItem);
  }

  render() {
    return (
      <SerialRangeGrid
        serialRanges={this.props.dataItem.serialRanges}
        editId={this.state.editId}
        onAdd={this.handleAddNewSerialRange}
        onDelete={this.deleteSerialRange}
        onRowClick={this.rowClick}
        onItemChange={this.itemChange}
      />
    );
  }
}

export default SupplierProduct;
