import React from 'react'
import { Form } from 'react-bootstrap';
import NumericTestField, { NumericTestFieldProps } from './NumericTestField';
import SelectionTestField, { SelectionTestFieldProps } from './SelectionTestField';
import CheckboxTestField, { CheckboxTestFieldProps } from './CheckboxTestField';


import './FormTestField.css';

interface FormTestFieldProps extends NumericTestFieldProps, SelectionTestFieldProps, CheckboxTestFieldProps {
    testType?: "passFail" | "yesNo" | "numeric" | "selection";
    value: any;
}

const FormTestField = (props: FormTestFieldProps) => {

    let value = props.value;
    let passChoice = props.passChoice;

    switch (props.testType) {
        case "numeric":
            value = props.value ? props.value : {value: '', passOrFail: '-'};
            return (
                <NumericTestField 
                    {...props}
                    value={value}      
                />
            );
        case "selection":
            value = props.value ? props.value : {value: '', passOrFail: '-'};
            return (
                <SelectionTestField 
                    {...props}
                    value={value}      
                />
            );
        case "yesNo":
            value = props.value ? props.value : {value: '', passOrFail: '-'};
            // default to Yes being a pass
            if (passChoice?.toLowerCase() === 'no') passChoice = 'No';
            else passChoice = 'Yes';
            return (
                <CheckboxTestField
                    {...props}
                    value={value}
                    option1="Yes"
                    option2="No"
                    passChoice={passChoice}
                />
            );
        case "passFail":
            value = props.value ? props.value : {value: '', passOrFail: '-'};
            // default to pass being a pass
            if (props.passChoice?.toLowerCase() === 'fail') passChoice = 'Fail';
            else passChoice = 'Pass';
            return (
                <CheckboxTestField
                    {...props}
                    value={value}
                    option1="Pass"
                    option2="Fail"
                    passChoice={passChoice}
                />
            );
        default:
            return <p>A TestField was created here with an invalid test type</p>;
    }
}

export default FormTestField;