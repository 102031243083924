import TestEquipment from '../Model/TestEquipment';

type SortDirection = 'asc' | 'desc' | undefined;

export abstract class TestEquipmentSortUtils {
  public static sortByCalibration(equipment: TestEquipment[], direction: SortDirection) {
    return equipment.sort(TestEquipmentSortUtils.calibrationSorter(direction));
  }

  private static calibrationSorter(direction: SortDirection) {
    const sortDirectionMultiplier = TestEquipmentSortUtils.sortDirectionMultiplier(direction);

    return (left: TestEquipment, right: TestEquipment): number => {
      // Sort based on status first
      const leftDetails = TestEquipmentSortUtils.calibrationSortDetails(left);
      const rightDetails = TestEquipmentSortUtils.calibrationSortDetails(right);

      const difference = leftDetails.priority - rightDetails.priority;
      if (difference !== 0)
        return difference * sortDirectionMultiplier;

      // Use expiry date as a tie-breaker
      const expiryDifference = leftDetails.expiryTime - rightDetails.expiryTime;
      return expiryDifference * sortDirectionMultiplier;
    };
  }

  private static calibrationSortDetails(item: TestEquipment) {
    if (!item.calibration)
      return {
        priority: 0,
        expiryTime: 0,
      };

    if (item.calibration.skipped)
      return {
        priority: 1,
        expiryTime: 0,
      };

    return {
      priority: 2,
      expiryTime: item.calibration.expires.getTime()
    };
  }

  private static sortDirectionMultiplier(direction: SortDirection): 1 | -1 {
    return direction === 'desc' ? -1 : 1;
  }
}
