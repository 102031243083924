import React, { useCallback, useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridActionCallback } from '../GridActionsCell';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ActionButtonProps<T> {
  icon: IconProp;
  label: string;
  variant?: ButtonVariant;
  dataItem: T;
  onClick: GridActionCallback<T>;
  isVisible?: (item: T) => boolean;
}

export function ActionButton<T>({ dataItem, icon, isVisible, label, onClick, variant }: ActionButtonProps<T>) {
  const handleClick = useCallback(() => onClick(dataItem), [onClick, dataItem]);

  const visible = useMemo(() => isVisible == null || isVisible(dataItem), [isVisible, dataItem]);
  if (!visible)
    return null;

  return (
    <OverlayTrigger overlay={<Tooltip id={`${icon}-tooltip`}>{label}</Tooltip>}>
      <Button variant={variant ?? 'primary'} className="m-1" size="sm" onClick={handleClick}>
        <FontAwesomeIcon icon={icon}/>
      </Button>
    </OverlayTrigger>
  );
}