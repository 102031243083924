import * as React from 'react';
import { JwtManager } from '../Shared/JwtManager';
import { Container, Row, Col } from 'react-bootstrap';
import Search from './Search';

interface HomeState {
   redirect: boolean;
}

interface HomeProps {
   checkTokenIsValid: any;
}

export class Home extends React.Component<HomeProps, HomeState> {
   tokenManager = new JwtManager({});
   token?: string | null;
   constructor(props: HomeProps) {
      super(props);

      this.props.checkTokenIsValid();
   }

   public render() {
      return (
         <Container>
            <Row>
               <Col md>
                  <Search
                     title={'Product Search'}
                     subTitle={'Scan or enter a product serial number to search for that product.'}
                     target="/Products"
                  />
               </Col>
               <Col md>
                  <Search
                     title={'Record Search'}
                     subTitle={'Scan or enter a product serial number to search for all the records for that product.'}
                     target="/Records"
                  />
               </Col>
            </Row>
         </Container>
      );
   }
}
