export default interface Attribute {
  id: number;
  key: string;
  name: string;
  value: string;
  tableId: number;
  tableName: string;
}

export enum AttributeKeys {
  SupplierProducts = 'SupplierProducts',
  DeviceOwnerProducts = 'DeviceOwnerProducts',
  TestEquipmentSerialRange = 'TestEquipmentSerialRange',
  CalibrationReminderContacts = 'CalibrationReminderContacts',
}

export const attributeValueByKey = (attributes: Attribute[], key: AttributeKeys) =>
   attributes.find(a => a.key === key)?.value;
