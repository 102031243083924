import * as React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';

export default function CustomDateFilter(props: any) {
   const onChangeStart = (event: any) => {
      const value = event.target.value;
      const { firstFilterProps } = props;

      firstFilterProps.onChange({
         value,
         operator: 'after',
         syntheticEvent: event.syntheticEvent,
      });
   };

   const onChangeEnd = (event: any) => {
      const value = event.target.value;
      const { secondFilterProps } = props;

      secondFilterProps.onChange({
         value,
         operator: 'before',
         syntheticEvent: event.syntheticEvent,
      });
   };

   return (
      <div>
         Start Date:
         <DatePicker onChange={onChangeStart} defaultValue={new Date()} format={'dd-MM-yyyy'} />
         End Date:
         <DatePicker onChange={onChangeEnd} defaultValue={new Date()} format={'dd-MM-yyyy'} />
      </div>
   );
}
