import React, { ReactNode } from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { ActionButton, DeleteActionButton } from './ActionButton';

export type GridActionCallback<T> = (item: T) => void;
export type VisibleCallback<T> = (item: T) => boolean;

export interface GridActionsCellProps<T> {
  onSave?: GridActionCallback<T>;
  saveVisible?: VisibleCallback<T>;
  onEdit?: GridActionCallback<T>;
  editVisible?: VisibleCallback<T>;
  onDelete?: GridActionCallback<T>;
  deleteVisible?: VisibleCallback<T>;
  onFilter?: any;
  onReport?: any;
  additionalButtons?: (dataItem: T) => ReactNode[];
}

export function GridActionsCell<T>(props: GridActionsCellProps<T>) {
  const {
    onEdit,
    editVisible,
    onDelete,
    deleteVisible,
    onSave,
    saveVisible,
    onFilter,
    onReport,
    additionalButtons
  } = props;

  return class GridActionsCellClass extends GridCell {
    render() {
      const { dataItem } = this.props;

      return (
        <td className="k-command-cell">
          {onFilter &&
              <ActionButton
                  icon="filter"
                  label="Filter on this item"
                  dataItem={dataItem}
                  onClick={onFilter}
              />
          }
          {onSave &&
              <ActionButton
                  icon="save"
                  label="Save this item"
                  variant="success"
                  dataItem={dataItem}
                  onClick={onSave}
                  isVisible={saveVisible}
              />}
          {onEdit &&
              <ActionButton
                  icon="edit"
                  label="Edit this item"
                  dataItem={dataItem}
                  onClick={onEdit}
                  isVisible={editVisible}
              />
          }
          {onReport &&
              <ActionButton
                  icon="file-alt"
                  label="Show related data"
                  dataItem={dataItem}
                  onClick={onReport}
              />
          }
          {onDelete &&
              <DeleteActionButton
                  dataItem={dataItem}
                  onClick={onDelete}
                  isVisible={deleteVisible}
              />
          }
          {
            additionalButtons?.(dataItem)
          }
        </td>
      );
    }
  };
}

