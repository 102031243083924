import * as React from 'react';
import { useCallback, useState } from 'react';

import { Button } from 'react-bootstrap';
import { Grid, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { State as DataState } from '@progress/kendo-data-query';

import OwnerLocation from '../Model/OwnerLocation';
import { Owner } from '../Model/Owner';
import { DEFAULT_DATA_STATE } from '../Shared/KendoConstants';
import { ChangeCallback } from '../Shared/Utils/Changes/ChangeCallback';
import { useChangeCallbacks } from '../Shared/Utils/Changes/UseChangeCallbacks';
import { useGridItemChangeCallback } from '../Shared/Utils/Changes/UseGridItemChangeCallback';
import { useActionCell } from '../Shared/Grids/UseActionCell';
import { useFilterColumnMenu } from '../Shared/Grids/UseFilterColumnMenu';
import { useProcessedGridData } from '../Shared/Grids/UseProcessedGridData';
import { EDIT_FIELD } from '../Shared/Utils/SetInEdit';

interface OwnerLocationProps {
  owner: Owner;
  locations: OwnerLocation[];
  onChange: ChangeCallback<OwnerLocation>;
  className?: string;
}

export const OwnerLocations = ({ locations, onChange, className }: OwnerLocationProps) => {
  // Sort and filter state
  const [dataState, setDataState] = useState<DataState>(DEFAULT_DATA_STATE);
  const handleDataStateChange = useCallback((e: GridDataStateChangeEvent) => setDataState(e.data), []);

  // Item currently under edit
  const [editingId, setEditingId] = useState<number>();

  // Process data and mark item under edit
  const data = useProcessedGridData(locations, dataState, editingId);

  // Location change handlers
  const createNewLocation = useCallback(() => {
    const newId = Math.min(0, ...locations.map(l => l.id)) - 1;
    return new OwnerLocation(newId);
  }, [locations]);

  const [
    handleAdd,
    handleEdit,
    handleDelete,
  ] = useChangeCallbacks(locations, onChange, createNewLocation, setEditingId);

  // Grid components and handlers
  const handleGridItemChange = useGridItemChangeCallback(locations, onChange);
  const actionCell = useActionCell({ onEdit: handleEdit, onDelete: handleDelete });
  const filterColumnMenu = useFilterColumnMenu(locations);

  return (
    <div className={className}>
      <Grid
        pageable
        sortable
        data={data}
        {...dataState}
        onDataStateChange={handleDataStateChange}
        editField={EDIT_FIELD}
        onItemChange={handleGridItemChange}
      >
        <Column
          field="name"
          title="Name"
          columnMenu={filterColumnMenu}
        />
        <Column
          title="Actions"
          width="140em"
          cell={actionCell}
          filterable={false}
        />
      </Grid>
      <Button
        className="mt-3"
        variant="primary"
        onClick={handleAdd}
        disabled={locations.some(location => location.name === "")}
      >
        Add new location
      </Button>
    </div>
  );
};