import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoBanner, InfoMessage } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import InputFormControl from './InputFormControl';
import { IFormControl, IFormLayout } from '../Model/IFormLayout';
import queryString from 'query-string';
import FormButtons from '../Shared/FormButtons';
import config, { keys } from '../../config';
import IProduct from '../Model/IProduct';
import IProductType from '../Model/IProductType';
import ResultSummary from './ResultSummary';
import IRecordType from '../Model/IRecordType';
import IEndpoint, { IFormEndpoint, IUrlParam } from '../Model/IEndpoint';
import Endpoint from './Endpoint';
import { Button, Col, Row } from 'react-bootstrap';
import ProductStatusIcon from '../Shared/ProductStatusIcon';
import { getProductTypes } from '../Shared/Data/GetProductTypes';

import './DataEntryForm.css';
import OwnerLocation from '../Model/OwnerLocation';
import { Calibrator } from '../Model/Calibrator';
import { extractOutFields } from './ExtractOutFields';
import { createDefaultEntryFormCheck, DataEntryFormControlCheck } from './Models/DataEntryFormControlCheck';
import Dependency from './Models/Dependency';
import { FaultItemsFieldPrefix, RepairItemsFieldPrefix } from '../RecordTypes/RecordType';

export interface DataEntryFormProps extends RouteComponentProps<any> {}

interface TestGroup {
   groupVar:string;
   componentTests:string[];
}

export interface DataEntryFormState {
   bannerMessage: InfoMessage;
   formLayout: IFormLayout;
   resultObject: any;
   recordType: IRecordType;
   productType: IProductType;
   supplierId: number;
   deviceOwnerId: number;
   recordId: number;
   lists: any;
   apiResult: any;
   readonly: boolean;
   serialChecks: any;
   validationtext: string;
   endpoints: {[x:string]:IEndpoint};
   dependencies: Dependency[];
   testGroups: {[x:string]:TestGroup};
   testResultVariable: string;
   nonSerialLocked: boolean;
   serialGroupVariable: string;
   nonSerialGroupVariable: string;
   serialGroupResults: string[];
   nonSerialGroupResults: string[];
}

const defaultColSize = 2;
const maxColSize = 12;
class DataEntryForm extends ApiResponseHandler<DataEntryFormProps, DataEntryFormState> {
   constructor(props: DataEntryFormProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: {
            bannerMessage: { message: '', show: false, warn: false, error: false },
            formLayout: { title: 'Data Entry Form', controls: [] },
            resultObject: [],
            serialChecks: [],
            recordType: { id: -1, name: '', captureType: '', category: '' },
            productType: { id: 0, name: '', recordTypes: [] },
            supplierId: 0,
            deviceOwnerId: 0,
            recordId: 0,
            lists: [],
            apiResult: undefined,
            readonly: false,
            validationtext: '',
            endpoints: {},
            dependencies: [],
            testGroups: {},
            testResultVariable: '',
            nonSerialLocked: false,
            serialGroupVariable: '',
            nonSerialGroupVariable: '',
            serialGroupResults: [],
            nonSerialGroupResults: [],
         },
      };
   }

   componentDidMount() {
      const values = queryString.parse(this.props.location.search);
      let productTypeId = values.producttype ? parseInt(values.producttype.toString()) : 0;
      let recordTypeId = values.recordtype ? parseInt(values.recordtype.toString()) : 0;
      let recordId = values.record ? parseInt(values.record.toString()) : 0;
      let supplierId = values.supplier ? parseInt(values.supplier.toString()) : 0;
      let deviceOwnerId = values.owner ? parseInt(values.owner.toString()) : 0;
      let edit = values.edit === 'true';

      let formLayout = this.state.innerState.formLayout;
      let resultObject: any = [];
      let serialChecks: any = [];
      let lists: any = [];
      let endpoints: {[x:string]:IEndpoint} = {};
      let dependencies: Dependency[] = [];
      let testGroups: {[x:string]:TestGroup} = {};
      let testResultVariable: string = '';
      let serialGroupVariable: string = '';
      let nonSerialGroupVariable: string = '';
      let serialGroupResults: string[] = [];
      let nonSerialGroupResults: string[] = [];
      let recordType = this.state.innerState.recordType;
      let productType = this.state.innerState.productType;
      let readonly = this.state.innerState.readonly;

      const recordTypeUrl = config.apiGateway.META_API + '/api/DataPointTypes/' + recordTypeId;
      const suppliersUrl = config.apiGateway.META_API + '/api/assetmanufacturers/';
      const producttypeUrl = config.apiGateway.META_API + '/api/assetmodels/' + productTypeId;
      const productsUrl = config.apiGateway.META_API + '/api/assets/bytype/' + keys.assetType;
      const recordtypesUrl = config.apiGateway.META_API + '/api/DataPointTypes/';
      const productMappingsUrl = config.apiGateway.META_API + '/api/Assets/parentMappings/';
      const recordUrl = config.apiGateway.DATA_API + '/api/InstrumentTestRecord/Record/' + recordId;
      const locationsUrl = `${config.apiGateway.ITR_API}/api/DeviceOwners/${deviceOwnerId}/Locations`;
      const calibratorsUrl = `${config.apiGateway.ITR_API}/api/Calibrators`;

      const faultDescriptivesUrl = config.apiGateway.META_API + '/api/Configuration/ITRFAULTDESCRIPTIVE';
      const repairDescriptivesUrl = config.apiGateway.META_API + '/api/Configuration/ITRREPAIRDESCRIPTIVE';
      const faultRepairItemsUrl = `${config.apiGateway.META_API}/api/AssetModels/attributes/${productTypeId}/${keys.ProductTypeItemsKey}`;

      // Load form layout
      this.get(recordTypeUrl)
         .then((response) => {
            if (response) {
               const fieldMappingsAttribute = response?.attributes.find((a:any) => a.key === keys.fieldMappingsAttributeKey);
               const fieldMappings = fieldMappingsAttribute?.value ? JSON.parse(fieldMappingsAttribute.value) : null;
               testResultVariable = fieldMappings?.resultField ? fieldMappings.resultField : '';
               serialGroupVariable = fieldMappings?.serialisedItemsField ? fieldMappings.serialisedItemsField : '';
               nonSerialGroupVariable = fieldMappings?.nonSerialisedItemsField ? fieldMappings.nonSerialisedItemsField : '';
               formLayout = JSON.parse(response.attributes.find((a: any) => a.key === keys.dataEntryFormAttributeKey).value);
               recordType = {
                  name: response.name,
                  id: response.id,
                  captureType: response.valueType,
                  category: response.units,
               };

               const formControlCheck = this.iterateControls(formLayout.controls, serialGroupVariable, nonSerialGroupVariable);
               resultObject = formControlCheck.variables;
               serialChecks = formControlCheck.serialChecks;
               endpoints = formControlCheck.endpoints;
               dependencies = formControlCheck.dependencies;
               testGroups = formControlCheck.testGroups;
               serialGroupResults = formControlCheck.serialGroupResults;
               nonSerialGroupResults = formControlCheck.nonSerialGroupResults;

               if (formControlCheck.errorString !== '') {
                  this.setState({
                     loading: false,
                     innerState: {
                        ...this.state.innerState,
                        bannerMessage: {
                           show: true,
                           warn: true,
                           message: formControlCheck.errorString,
                           error: true,
                        },
                     },
                  });
                  return;
               }

               if (recordId !== 0 && !edit) {
                  readonly = true;
               }

               let endpointPromises: Promise<any>[] = [];
               let validEndpoints: string[] = [];
               for(const k in endpoints) {
                  if(endpoints[k].isValid){
                     validEndpoints.push(endpoints[k].name);
                     endpointPromises.push(this.get(`${config.apiGateway.META_API}${endpoints[k].parsedUrl}`));
                  }
               }

               Promise.all([
                  formControlCheck['suppliers'] ? this.get(suppliersUrl) : null,
                  formControlCheck['producttypes'] ? getProductTypes(url => this.get(url)) : null,
                  formControlCheck['products'] ? this.get(productsUrl) : null,
                  formControlCheck['recordtypes'] ? this.get(recordtypesUrl) : null,
                  formControlCheck['faultrepairitems'] ? this.get(faultRepairItemsUrl) : null,
                  formControlCheck['faultdescriptives'] ? this.get(faultDescriptivesUrl) : null,
                  formControlCheck['repairdescriptives'] ? this.get(repairDescriptivesUrl) : null,
                  this.get(producttypeUrl),
                  recordId !== 0 ? this.get(recordUrl) : null,
                  formControlCheck['locations'] && deviceOwnerId !== 0 ? this.get(locationsUrl) : null,
                  formControlCheck['calibrators'] ? this.get(calibratorsUrl) : null,
                  ...endpointPromises
               ])
                  .then(([suppliersApiData, productTypesApiData, productsApiData, recordTypesApiData, 
                          faultRepairApiData, faultDescApiData, repairDescApiData, productTypeResponse, 
                          recordResponse, locationsResponse, calibratorsResponse, ...endpointApiData]) => {
                     lists['suppliers'] =
                        suppliersApiData === null
                           ? []
                           : suppliersApiData.map((supplierJson: any) => ({
                                name: supplierJson.name,
                                id: supplierJson.id,
                                products: [],
                             }));

                     lists['producttypes'] = productTypesApiData;
                     lists['products'] =
                        productsApiData === null
                           ? []
                           : productsApiData.map((productJson: any) => ({
                                serialNumber: productJson.serialNumber,
                                name: productJson.serialNumber,
                                id: productJson.id,
                                productType: { id: productJson.assetModelId, name: productJson.assetModel },
                                supplier: { id: productJson.assetManufacturerId, name: productJson.assetManufacturerName },
                                lastRecordDate:
                                   productJson.attributes === null
                                      ? undefined
                                      : decodeURIComponent(productJson.attributes.find((a: any) => a.key === 'DateOfLastRecord').value), // TODO: Depending on how the data is stored, this may need updating
                                currentStatus:
                                   productJson.attributes === null
                                      ? undefined
                                      : JSON.parse(productJson.attributes.find((a: any) => a.key === 'CurrentStatus').value).Conclusion.toLowerCase(),
                                parent: -1,
                             }));

                     lists['recordtypes'] =
                        recordTypesApiData === null
                           ? []
                           : recordTypesApiData.map((recordTypeJson: any) => ({
                                name: recordTypeJson.name,
                                id: recordTypeJson.id,
                             }));
                     
                     lists['faultrepairitems'] = 
                        !faultRepairApiData
                        ? []
                        : JSON.parse(faultRepairApiData.value);
                     lists['faultdescriptives'] = 
                        !faultDescApiData
                        ? []
                        : faultDescApiData.map((faultDescJson: any) => ({
                              id: faultDescJson.id,
                              name: faultDescJson.value,
                           }));      

                     lists['repairdescriptives'] = 
                     !repairDescApiData
                     ? []
                     : repairDescApiData.map((repairDescJson: any) => ({
                           id: repairDescJson.id,
                           name: repairDescJson.value,
                        }));


                     productType = {
                        name: productTypeResponse.name,
                        id: productTypeResponse.id,
                        recordTypes: [],
                     };
                     if (recordResponse) {
                        const flatControls = this.flattenFormControls(formLayout);
                        resultObject = this.rebuildFormData(recordResponse, flatControls);
                     }

                     lists['locations'] = !locationsResponse
                       ? []
                       : locationsResponse.map((r: any) => new OwnerLocation(r.id, r.name));

                     lists['calibrators'] = !calibratorsResponse
                       ? []
                       : calibratorsResponse.map((c: any) => new Calibrator(c.id, c.name));

                     lists = this.handleEndpointResponses(endpointApiData, validEndpoints, lists, endpoints);
                  })
                  .then(async () => {
                     await Promise.all(
                        lists['products'].map((p: IProduct, index: any, array: IProduct[]) => {
                           return this.get(productMappingsUrl + p.id).then((mappings) => {
                              if(mappings !== undefined)
                              {
                                 let mapping = mappings.find((x: any) => x !== undefined);
                                 array[index] = mapping === undefined ? { ...p, parent: -1 } : { ...p, parent: mapping.parentId };
                              }
                           });
                        })
                     );
                     this.setState({
                        loading: false,
                        innerState: { ...this.state.innerState, lists },
                     });
                  })
                  .then(() => {
                     this.setState({
                        loading: false,
                        innerState: {
                           ...this.state.innerState,
                           productType,
                           recordType,
                           formLayout,
                           resultObject,
                           lists,
                           readonly,
                           supplierId,
                           deviceOwnerId,
                           recordId,
                           serialChecks,
                           endpoints: endpoints,
                           dependencies: dependencies,
                           testGroups: testGroups,
                           testResultVariable: testResultVariable,
                           serialGroupVariable: serialGroupVariable,
                           nonSerialGroupVariable: nonSerialGroupVariable,
                           serialGroupResults: serialGroupResults,
                           nonSerialGroupResults: nonSerialGroupResults,
                        },
                     });
                  })
                  .catch((error) => {
                     this.setState({
                        loading: false,
                        innerState: {
                           ...this.state.innerState,
                           productType,
                           recordType,
                           formLayout,
                           resultObject,
                           lists,
                           readonly,
                           supplierId,
                           recordId,
                           serialChecks,
                           endpoints: endpoints,
                           dependencies: dependencies,
                           testGroups: testGroups,
                           testResultVariable: testResultVariable,
                           serialGroupVariable: serialGroupVariable,
                           nonSerialGroupVariable: nonSerialGroupVariable,
                           serialGroupResults: serialGroupResults,
                           nonSerialGroupResults: nonSerialGroupResults,
                        },
                     });
                     this.handleApiError(error);
                  });
            }
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }



   private handleEndpointResponses(endpointApiData: any[], endpointNames: string[], lists: any, endpoints: {[x:string]:IEndpoint}){
      endpointNames.forEach((name:string, idx: number) => {
         if(!endpointApiData[idx])
            lists[name] = undefined;
         else if(Array.isArray(endpointApiData[idx])){
            lists[name] = endpointApiData[idx].map((e: any) => {
               let out: any = {};
               endpoints[name].outFields.forEach((f: string) => {
                  out[f] = e[f];
               });
               return out;
            });
         } else {
            const field = endpointApiData[idx][endpoints[name].outFields[0]];
            if (Array.isArray(field))
               lists[name] = field;
            else {
               try {
                  lists[name] = JSON.parse(field);
               } catch (error) {
                  lists[name] = undefined;
               }
            }
         }
      });
      return lists;
   }



   private updateEndpointLists(endpointNames: string[]) {
      const endpoints = this.state.innerState.endpoints;
      let endpointPromises: Promise<any>[] = [];
      endpointNames.forEach((e:string) => {
         endpointPromises.push(this.get(`${config.apiGateway.META_API}${endpoints[e].parsedUrl}`));
      });
      Promise.all(endpointPromises)
         .then(endpointApiData => {
            this.setState({
               loading: false,
               innerState: {
                  ...this.state.innerState,
                  lists: this.handleEndpointResponses(endpointApiData, endpointNames, this.state.innerState.lists, endpoints)
               }
            });
         }).catch((error) => this.handleApiError(error));
   }

   private iterateControls(controls: IFormControl[], serialisedItemsField?: string, nonSerialisedItemsField?: string, currentLists?: DataEntryFormControlCheck): DataEntryFormControlCheck {
      let requireLists = currentLists ?? createDefaultEntryFormCheck();

      controls.forEach((c) => {
         if (c.type === 'group' || c.type === 'row') requireLists = this.iterateControls(c.controls, serialisedItemsField, nonSerialisedItemsField, requireLists);

         if (c.type === 'dropdown' && c.source !== undefined) requireLists[c.source] = true;

         if(c.type === 'multiselect' && c.source !== undefined) requireLists[c.source] = true;

         if (c.variable !== undefined) {
            if (requireLists.variables[c.variable] !== undefined) requireLists.errorString += `Variable ${c.variable} is already defined.\n`;
            else {
               if (c.type === 'checkbox') requireLists.variables[c.variable] = false;
               else if(c.type === 'group') requireLists.variables[c.variable] = 'Fail';
               else requireLists.variables[c.variable] = null;
            }

            if (c.type === 'serial') requireLists.serialChecks[c.variable] = null;
         }

         if(c.tests)
            requireLists.testGroups = this.parseTests(c.tests, c.variable, requireLists.testGroups);

         if (c.type === 'group' && c.variable === serialisedItemsField)
            requireLists.serialGroupResults = this.getItemVariables(c.controls);
         if (c.type === 'group' && c.variable === nonSerialisedItemsField)
            requireLists.nonSerialGroupResults = this.getItemVariables(c.controls);
         if(c.endpoint && !requireLists.endpoints[c.endpoint.name]) {
               requireLists.endpoints[c.endpoint.name] = this.parseEndpoint(c.endpoint);
               requireLists.dependencies.push(this.parseDependencies(c.endpoint, c.variable, c.type));
         }
      });

      return requireLists;
   }


   private parseTests(tests:string[], variable:string, testGroups:{[x:string]:TestGroup}) {
      let newTestGroups = Object.assign({}, testGroups);
      tests.forEach((test:string) => {
         if(!newTestGroups[test]) {
            newTestGroups[test] = {groupVar: variable, componentTests:tests};
         }
      });
      return newTestGroups;
   }

   private parseDependencies(endpoint: IFormEndpoint, variable: string, type: string) {
      let deps: string[] = [];
      let paramMap:{[x:string]:string} = {};
         if(endpoint.urlParams){
         endpoint.urlParams.forEach((param: IUrlParam) => {
            if(param.type === 'variable')
               deps.push(param.value);
               paramMap[param.value] = param.name;
         });
      }
      return {"name":endpoint.name, "deps":deps, "paramMap":paramMap, "variable": variable, "varType":type};
   }

   private parseEndpoint(endpoint: IFormEndpoint) {
      let parsedEndpoint = new Endpoint(endpoint.name, endpoint.api);
         if(endpoint.urlParams) {
         endpoint.urlParams.forEach((param: IUrlParam) => {
            if(param.type === 'param') {
               const urlQueryParams = queryString.parse(this.props.location.search);
               const urlQueryParamValue = urlQueryParams[param.value]?.toString();
               parsedEndpoint.paramValues[param.name] = urlQueryParamValue ? urlQueryParamValue : '';
            }
            else if(param.type !== 'variable')
               parsedEndpoint.paramValues[param.name] = String(param.value);
            if(param.field)
               parsedEndpoint.inFields[param.value] = param.field;
         });
      }
      parsedEndpoint.outFields = endpoint.outFields;
      return parsedEndpoint;
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   private onChange(variable: string, value: any) {
      const dependencies = this.state.innerState.dependencies;
      const deps = dependencies.filter((e:Dependency) => e.deps.includes(variable));
      const testGroups = this.state.innerState.testGroups;
      let results = this.state.innerState.resultObject;
      let endpoints = this.state.innerState.endpoints;
      let validEndpoints: string[] = [];


      results[variable] = value;
     
      let serialChecks = this.state.innerState.serialChecks;
      if (serialChecks[variable] === null || serialChecks[variable] === true) serialChecks[variable] = false;

      if (serialChecks[variable] !== null && serialChecks[variable] === false && value === '') serialChecks[variable] = null;
     
      deps.forEach((dep:Dependency) =>{
         if(endpoints[dep.name].inFields[variable])
            endpoints[dep.name].paramValues[dep.paramMap[variable]] = value[endpoints[dep.name].inFields[variable]];
         else
            endpoints[dep.name].paramValues[dep.paramMap[variable]] = value;
         if(endpoints[dep.name].isValid)
            validEndpoints.push(endpoints[dep.name].name);
         else if (dep.varType === "checkbox")
            results[dep.variable] = false;
         else
            results[dep.variable] = null;
      });

      if(testGroups[variable]) {
         const {groupVar: groupName, componentTests: tests} = testGroups[variable];

         const passOrFail = tests.every((test: string) => {
            let currentTest = results[test];
            if (currentTest === null)
               return false;
            // Explicit check for true to ensure the result type is an actual boolean i.e. from a checkbox
            else if (currentTest === true || currentTest === 'Pass' || currentTest === config.naTestResultText)
               return true;
            if(currentTest && currentTest.passOrFail)
               return currentTest.passOrFail === 'Pass' || currentTest.passOrFail === config.naTestResultText;
            
            return false;
         });
         const passOrFailString = passOrFail ? 'Pass' : 'Fail';
         if(passOrFailString !== results[groupName])
            this.onChange(groupName, passOrFailString);
      }

      this.setState({
         innerState: {
            ...this.state.innerState,
            resultObject: results,
            serialChecks,
         },
      }, () => this.updateEndpointLists(validEndpoints));
   }

   private onSerialResult(variable: string, result: string) {
      let serialChecks = this.state.innerState.serialChecks;
      if (serialChecks[variable] !== undefined) {
         if (result === 'pass') serialChecks[variable] = true;

         this.setState({
            innerState: {
               ...this.state.innerState,
               serialChecks,
            },
         });
      }
   }

   private renderControls(controls: IFormControl[], groupTitle?: string, key?: any, row?: boolean, groupVar?: string) {
      return (<>
         {!row && <div key={key} className={groupTitle === undefined ? '' : 'mt-3 m-2 p-3 border rounded'}>
            {groupTitle === undefined ? '' : <h6 className="float-right">{groupTitle}</h6>}
            {this.renderIndividualControl(controls, groupTitle, key, row, groupVar)}
         </div>}
         {row && <Row>
            <>{this.renderIndividualControl(controls, groupTitle, key, true, groupVar)}</>
         </Row>}
      </>);
   }

   private renderIndividualControl(controls: IFormControl[], _groupTitle?: string, _key?: any, row?: boolean, groupVar?: string)
   {
      let data = [] as any[];
      return (controls.map((c, i) => {
         switch (c.type) {
            case 'note':
               var noteContent = <p key={i} className="mt-3">
                                 {c.label}
                              </p>;

               return (!row ? <>{noteContent}</> : this.renderContentInCol(noteContent, defaultColSize));
            case 'group':
               return this.renderControls(c.controls, c.label, i, row, c.variable);
            case 'row':
               return this.renderControls(c.controls, c.label, i, true, groupVar);
            case 'dropdown':
               if (c.values) {
                  data = c.values;
               } else if(c.source) {
                  data = this.getListData(c.source, c.filterfield, c.filtervalue);
               } else if(c.endpoint) {
                  data = this.getListData(c.endpoint.name);
               }
               break;
            case 'multiselect':
               if(c.values) {
                  data = c.values;
               } else if(c.source){
                  data = this.getListData(c.source);
               } else if(c.endpoint) {
                  data = this.getListData(c.endpoint.name);
               }
               break;
            default:
               break;
         }

         const isSerialisedItem = groupVar === this.state.innerState.serialGroupVariable;
         const locked = this.state.innerState.nonSerialLocked;
         const readOnly = this.state.innerState.readonly;
         var inputFormControl = <InputFormControl
                                 key={i}
                                 {...c}
                                 data={data}
                                 onChange={this.onChange.bind(this)}
                                 onSerialResult={this.onSerialResult.bind(this)}
                                 value={this.state.innerState.resultObject[c.variable]}
                                 readonly={readOnly || (locked && !isSerialisedItem)}
                              />;
         return (!row ? <>{inputFormControl}</> : this.renderContentInCol(inputFormControl, c.col? c.col : defaultColSize));
      }));
   }

   renderContentInCol(content: any, colSize = defaultColSize)
   {
      return <Col className={this.getColClass(colSize)}>{content}</Col>
   }

   getColClass(colSize: number)
   {
      return colSize > maxColSize ? `col-md-${maxColSize}` : `col-md-${colSize}`;

   }

   getListData(source?: string, field?: string, value?: string) {
      if (source === undefined) return [];

      let fullData = this.state.innerState.lists[source];

      return field === undefined || value === undefined
         ? fullData
         : fullData.filter((d: any) => d[field] === this.state.innerState.resultObject[value]);
   }

   private validateForm(flatControls: IFormControl[]) {
      let validated = true;

      // This is temporarily disabled until the backlog of test results have been uploaded
      // to substation360 as all validation will fail until the test results can be checked
      // let serialChecks = this.state.innerState.serialChecks;
      // for (const result of Object.values(serialChecks)) {
      //    if (result === false) validated = false;
      // }

      if (!validated) {
         this.setState({
            innerState: {
               ...this.state.innerState,
               validationtext: `All serial numbers must be verified before continuing.`,
            },
         });
      }

      const [testEquipmentValid, testEquipmentValidationError] = this.validateTestEquipment(flatControls);
      if (!testEquipmentValid) {
         validated = false;
         this.setState({
            innerState: {
               ...this.state.innerState,
               validationtext: testEquipmentValidationError,
            },
         });
      }

      return validated;
   }

   private validateTestEquipment = (flatControls: IFormControl[]): [boolean, string] => {
      const { resultObject } = this.state.innerState;
      const testEquipmentControls = flatControls.filter(c => c.type === 'testEquipment');
      if (testEquipmentControls.length === 0)
         return [true, ''];

      const results = Object.assign({}, resultObject);
      for (const control of testEquipmentControls) {
         const min = control.min ?? 0;
         const testEquipmentIds = results[control.variable] ?? [];
         if (testEquipmentIds.length < min)
            return [false, `At least ${min} test equipment required for ${control.label}`];
      }

      return [true, ''];
   };

   private handleButtonClick(e: any) {
      if (e.target.value === 'save') this.saveData();
      else this.props.history.goBack();
   }

   private flattenFormControls(form: IFormLayout) {
      let flattenedControls: IFormControl[] = [];
      const extractControls = (control:any) => {
         if(control.controls) control.controls.forEach(extractControls);
         flattenedControls.push(control);
      };
      form.controls.forEach(extractControls);
      return flattenedControls;
   }


   private rebuildFormData(responseData: any, formControls: IFormControl[]) {
      let formData: any = {};
      formControls.forEach((control) => {
         const variableMetadataField = `${control.variable}_METADATA`;

         switch (control.type.toLowerCase()) {
            case 'dropdown':
               if (control.outField && responseData[variableMetadataField])
                  formData[control.variable] = JSON.parse(responseData[variableMetadataField]);
               else
                  formData[control.variable] = responseData[control.variable];
               break;
            case 'testfield':
               let testObject: any = {};
               if (responseData[control.variable]) {
                  testObject.value = responseData[`${control.variable}_value`];
                  testObject.passOrFail = responseData[control.variable];
                  formData[control.variable] = testObject;
               } else
                  formData[control.variable] = null;
               break;
            case 'multiselect':
               if (control.outField && responseData[variableMetadataField])
                  formData[control.variable] = JSON.parse(responseData[variableMetadataField]);
               else {
                  if(responseData[`${control.variable}_values`])
                     formData[control.variable] = JSON.parse(responseData[`${control.variable}_values`]);
                  else
                     formData[control.variable] = null;
               }
               break;
            default:
               if(control.variable)
                  formData[control.variable] = responseData[control.variable];
         }
      });
      return formData;
   }


   private async saveData() {
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: false,
               warn: false,
               message: '',
               error: false,
            },
            validationtext: '',
         },
      });

      const flatControls = this.flattenFormControls(this.state.innerState.formLayout);
      if (!this.validateForm(flatControls)) return;

      const isCalibrationRecord = this.state.innerState.recordType.category === "Calibration";
      const submissionUrl = `${config.apiGateway.SUBMISSION_API}/api/InstrumentTest/upload-single/${isCalibrationRecord ? 'calibration' : ''}`;
      const despatchUrl = `${config.apiGateway.SUBMISSION_API}/api/InstrumentTest/upload-single/despatch`;
      const editUrl = `${config.apiGateway.SUBMISSION_API}/api/InstrumentTest/Record/`;

      let resultData = Object.assign({}, this.state.innerState.resultObject);

      resultData = await extractOutFields(resultData, flatControls);

      if (this.state.innerState.recordType.category === 'Problem Report') {
         let [faultItems, faultDescriptives, repairItems, repairDescriptives] = this.getFaultRepairItemsList(resultData, flatControls);

         resultData.faultItemsField = faultItems;
         resultData.repairItemsField = (repairItems.length === 0) ? null : repairItems;
         resultData.faultDescriptivesField = faultDescriptives;
         resultData.repairDescriptivesField = (repairDescriptives.length === 0) ? null : repairDescriptives;
      }

      if (this.state.innerState.recordType.category === 'Despatch') {
         let serialisedItemResults: string[] = [];
         let nonSerialisedItemResults: string[] = [];
         for (const item of this.state.innerState.serialGroupResults) {
            serialisedItemResults.push(resultData[item]);
         }
         resultData[this.state.innerState.serialGroupVariable] = serialisedItemResults;
         for (const item of this.state.innerState.nonSerialGroupResults) {
            nonSerialisedItemResults.push(resultData[item]);
         }
         resultData[this.state.innerState.nonSerialGroupVariable] = nonSerialisedItemResults;
      }

      let uploadPromise: Promise<any>;
      const body = JSON.stringify({
         supplierId: this.state.innerState.supplierId,
         deviceOwnerId: this.state.innerState.deviceOwnerId,
         productTypeId: this.state.innerState.productType.id,
         recordTypeId: this.state.innerState.recordType.id,
         fileName: '',
         data: resultData,
      });

      if (this.state.innerState.recordId !== 0 && this.state.innerState.recordType.category === 'Problem Report') {
         uploadPromise = this.put(`${editUrl}${this.state.innerState.recordId}`, body);
      } else {
         if (this.state.innerState.recordType.category === 'Despatch')
            uploadPromise = this.post(despatchUrl, body);
         else
            uploadPromise = this.post(submissionUrl, body);
      }

      await uploadPromise
        .then((response) => {
           let resultData = Object.assign({}, this.state.innerState.resultObject);
           for(const item of this.state.innerState.serialGroupResults) {
               resultData[item] = null;
           }
           this.setState({
              loading: false,
              innerState: {
                 ...this.state.innerState,
                 apiResult: response,
                 resultObject: resultData
              },
           });
        })
        .catch((error) => {
           this.handleApiError(error);
        });
   }

   getFaultRepairItemsList(data: any, flatControls: IFormControl[]) {
      let faultItems: string[]  = [];
      let faultDescriptives: string[]  = [];
      let repairItems: string[]  = [];
      let repairDescriptives: string[]  = [];

      Object.keys(data).forEach((key) => {
         let item;
         if(key.includes(FaultItemsFieldPrefix) && !key.includes('METADATA') && data[key] != null)
         {
            item = flatControls.find(i => i.variable == key);
            if(item !== undefined)
            {
               faultItems.push(item?.label);
               faultDescriptives.push(data[key])
            }

         }
         if(key.includes(RepairItemsFieldPrefix) && !key.includes('METADATA') && data[key] != null)
         {
            item = flatControls.find(i => i.variable == key);
            if(item !== undefined)
            {
               repairItems.push(item?.label);
               repairDescriptives.push(data[key])
            }

         }
      });

      return [faultItems,faultDescriptives, repairItems, repairDescriptives];
   }

   getItemVariables(controls: IFormControl[]) {
      let items: string[] = [];
      for (const control of controls) {
         if (control.type !== 'group' && control.type !== 'row') {
            items = [...items, control.variable];
         } else {
            items = [...items, ...this.getItemVariables(control.controls)];
         }
      }
      return items;
   }

   handleSummaryButtonClick(e: any) {
      let value = e.target.value;

      if (value === 'duplicate') {
         const formControlCheck = this.iterateControls(this.state.innerState.formLayout.controls);

         this.setState({
            innerState: {
               ...this.state.innerState,
               apiResult: undefined,
               resultObject: formControlCheck.variables,
            },
         });
      } else if (value === 'new') {
         this.props.history.push(`/AddRecord`);
      } else if (value === 'finish') {
         this.props.history.push(`/Home`);
      }
   }

   private renderPageHeader() {
      const isTestForm: boolean = this.state.innerState.recordType.category === 'Test';
      const results = this.state.innerState.resultObject;
      const testResultVariable = this.state.innerState.testResultVariable;
      let containerClassName = 'title-container';
      if (isTestForm && testResultVariable) containerClassName += ' problem-report-container';

      return(
         <div className={containerClassName}>
            {isTestForm && testResultVariable ? <div className="problem-report-outer"/> : null}
            <h1 className="page-header">{this.state.innerState.formLayout.title}</h1>
            {isTestForm && testResultVariable
            ? <div className="problem-report-outer">
                  <ProductStatusIcon
                     value={results[testResultVariable] ? results[testResultVariable] : 'Fail'}
                     size={'5x'}
                  />
               </div> 
            : null } 
         </div>
      );
   }

   private handleEditRecord() {
      const params = new URLSearchParams({
         producttype: this.state.innerState.productType.id.toString(),
         recordtype: this.state.innerState.recordType.id.toString(),
         record: this.state.innerState.recordId.toString(),
         supplier: this.state.innerState.supplierId.toString(),
         owner: this.state.innerState.deviceOwnerId.toString(),
         edit: 'true'
      });
      this.props.history.push(`/DataEntry?${params}`);
      this.setState({
         innerState:{
            ...this.state.innerState,
            readonly: false,
         }
      });
   }

   render() {
      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            {this.state.innerState.apiResult !== undefined && this.state.innerState.recordType.category !== 'Despatch' ? (
               <ResultSummary
                  result={this.state.innerState.apiResult}
                  productType={this.state.innerState.productType}
                  recordType={this.state.innerState.recordType}
                  handleButtonClick={this.handleSummaryButtonClick.bind(this)}
               />
            ) : (
               <div>
                  {this.renderPageHeader()}
                  <Button className="mr-2 float-right" type="button" onClick={() =>{
                     this.setState({
                        innerState:{
                           ...this.state.innerState,
                           nonSerialLocked: true
                        }
                     });
                  }}>Lock Items</Button>
                  <br/>
                  {this.renderControls(this.state.innerState.formLayout.controls)}
                  <div className="mt-3">
                     {this.state.innerState.readonly ? (
                        <React.Fragment>
                        <Button className="ml-2 float-right" variant="secondary" type="button" onClick={() => this.props.history.goBack()}>
                           Close
                        </Button>
                        <Button className="ml-2 float-right" variant="primary" type="button" onClick={this.handleEditRecord.bind(this)}>
                           Edit
                        </Button>
                        </React.Fragment>
                     ) : (
                        <FormButtons handleButtonClick={this.handleButtonClick.bind(this)} />
                     )}
                     <p className="text-danger">{this.state.innerState.validationtext}</p>
                  </div>
               </div>
            )}
         </React.Fragment>
      );
   }
}

export default withRouter(DataEntryForm);
