import config from '../../config';
import { AttributeSerialRange } from './API/APISerialRange';

export interface SerialRange {
  id: number;
  prefix: string;
  min: number;
  max: number;
}

export abstract class SerialRangeUtils {
  public static isEmpty = (range: SerialRange): boolean =>
    range.prefix.trim() === ''
    && range.min === 0
    && range.max === 0;

  public static isValidPrefix = (range: SerialRange): boolean =>
    range.prefix.endsWith(config.serialNumberPrefixSeparator);

  public static parseFromJSON = (json?: string): SerialRange[] => {
    if (!json || json.trim() === '')
      return [];

    const parsed = JSON.parse(json);
    if (!Array.isArray(parsed))
      throw new Error('Unexpected serial range type');

    return parsed.map((item: AttributeSerialRange) => ({
      id: item.Id,
      prefix: item.Prefix,
      min: item.Min,
      max: item.Max
    }));
  }

  public static default: SerialRange = {
    id: 0,
    prefix: "",
    min: 0,
    max: 0
  };
}
