import config from '../../../../config';
import { Owner } from '../../../Model/Owner';
import { APIQuery } from '../Types';
import APICreateOwner from '../../../Model/API/APICreateOwner';
import APIPatchOwner from '../../../Model/API/APIPatchOwner';

export const addUpdateOwner = (owner: Owner, query: APIQuery): Promise<Owner> =>
  owner.id === 0
    ? addOwner(owner, query)
    : updateOwner(owner, query);

const addOwner = (owner: Owner, query: APIQuery): Promise<Owner> => {
  const ownerUrl = `${config.apiGateway.ITR_API}/api/deviceOwners`;
  const body = JSON.stringify(toCreateOwnerRequest(owner));
  return query(ownerUrl, 'POST', body);
};

const updateOwner = async (owner: Owner, query: APIQuery): Promise<Owner> => {
  const ownerUrl = `${config.apiGateway.ITR_API}/api/deviceOwners/${owner.id}`;
  const body = JSON.stringify(toPatchOwnerRequest(owner));
  const response: Owner | null | string = await query(ownerUrl, 'PATCH', body);

  // Use owner in response if API determines a changes, otherwise use the existing owner
  return response && typeof response === "object"
    ? { ...owner, ...response }
    : owner;
};

const toCreateOwnerRequest = (owner: Owner): APICreateOwner => ({
  name: owner.name,
});

const toPatchOwnerRequest = (owner: Owner): APIPatchOwner => ({
  name: owner.name
});