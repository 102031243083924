import Attribute, { AttributeKeys, attributeValueByKey } from './Attribute';
import { SerialRange, SerialRangeUtils } from './SerialRange';
import { APIOwner } from './API/APIOwner';

export interface Owner {
  id: number;
  name: string;
  createdInItr: boolean;
  attributes: Attribute[];
  testEquipmentSerialRanges: SerialRange[];
  calibrationReminderContacts: string[];
}

export abstract class OwnerUtils {
  public static fromResponse = (response: APIOwner): Owner => {
    const serialRangeJSON = attributeValueByKey(response.attributes, AttributeKeys.TestEquipmentSerialRange);
    const serialRanges = SerialRangeUtils.parseFromJSON(serialRangeJSON);

    const contactsJSON = attributeValueByKey(response.attributes, AttributeKeys.CalibrationReminderContacts);
    const calibrationReminderContacts: string[] = contactsJSON ? JSON.parse(contactsJSON) : [];

    return ({
      ...response,
      testEquipmentSerialRanges: serialRanges,
      calibrationReminderContacts,
    });
  };

  public static default: Owner = {
    id: 0,
    name: "",
    createdInItr: true,
    attributes: [],
    testEquipmentSerialRanges: [],
    calibrationReminderContacts: [],
  };
}
