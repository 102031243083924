import React, { useCallback, useState } from 'react';
import TestEquipment from '../../Model/TestEquipment';
import QRCode from 'qrcode.react';
import { useTestEquipmentQRPayload } from './UseTestEquipmentQRPayload';
import { Button } from 'react-bootstrap';
import { PrintTestEquipmentLabelModal } from './PrintTestEquipmentLabelModal';

interface TestEquipmentQRCodeProps {
  testEquipment: TestEquipment;
}

export const TestEquipmentQRCode: React.FC<TestEquipmentQRCodeProps> = ({ testEquipment }) => {
  const qrCodePayload = useTestEquipmentQRPayload(testEquipment);

  const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
  const handlePrintLabelClick = useCallback(() => setShowPrintLabelModal(true), []);
  const handleTestEquipmentModalClose = useCallback(() => setShowPrintLabelModal(false), []);

  return (
    <>
      <div className="d-inline-flex flex-column">
        <QRCode value={qrCodePayload} renderAs="svg" />
        <Button onClick={handlePrintLabelClick} className="mt-3">Print Label</Button>
      </div>

      <PrintTestEquipmentLabelModal
        show={showPrintLabelModal}
        testEquipment={testEquipment}
        onClose={handleTestEquipmentModalClose}
        qrCodePayload={qrCodePayload}
      />
    </>
  );
};
