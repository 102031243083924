import { APIQuery } from './Types';
import { Calibrator } from '../../Model/Calibrator';
import config from '../../../config';

const BASE_ADDRESS = `${config.apiGateway.ITR_API}/api/calibrators`;

export class CalibratorAPI {
  private readonly _query: APIQuery;

  constructor(query: APIQuery) {
    this._query = query;
  }

  public fetchAll = async (): Promise<Calibrator[]> => {
    const response: Calibrator[] | undefined = await this._query(BASE_ADDRESS, 'GET');
    if (!response || response.length === 0)
      return [];

    return response.map(r => new Calibrator(r.id, r.name));
  };

  public save = (item: Calibrator): Promise<number> =>
    item.isNew()
      ? this.saveNew(item)
      : this.updateExisting(item).then(() => item.id);

  public delete = (item: Calibrator): Promise<void> => {
    if (item.isNew())
      return Promise.reject("Item has not been saved");

    const address = `${BASE_ADDRESS}/${item.id}`;
    return this._query(address, 'DELETE');
  };

  private saveNew = async (item: Calibrator): Promise<number> => {
    const body = this.createRequestBody(item);
    const response = await this._query(BASE_ADDRESS, 'POST', body);
    return response.id;
  };

  private updateExisting = (item: Calibrator) => {
    const address = `${BASE_ADDRESS}/${item.id}`;
    const body = this.createRequestBody(item);
    return this._query(address, 'PUT', body);
  };

  private createRequestBody = (item: Calibrator) => JSON.stringify(this.toAPIRequest(item));

  private toAPIRequest = (item: Calibrator) => ({ name: item.name });
}