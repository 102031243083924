import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import { Button } from 'react-bootstrap';
import { GridActionsCell } from '../Shared/GridActionsCell';
import { RouteComponentProps } from 'react-router-dom';
import ISurveyRepairType from '../Model/ISurveyRepairType';
import { InfoMessage, InfoBanner, BLANK_BANNER_MESSAGE } from '../Shared/Infobanner';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import BusyOverlay from '../Shared/BusyOverlay';
import { process } from '@progress/kendo-data-query';
import config, { keys, newId } from '../../config';
import queryString from 'query-string';
import { getFaultRepairTypes } from '../Shared/Data/SurveyRepair/GetFaultRepairTypes';
import { SurveyRepairDescriptiveAPI } from '../Shared/Data/SurveyRepair/SurveyRepairDescriptiveAPI';

export interface FaultRepairTypesProps extends RouteComponentProps<any> {
}

export interface FaultRepairTypesState {
  FaultRepairTypes: ISurveyRepairType[];
  dataState: any;
  bannerMessage: InfoMessage;
  displayItemDescriptive: string;
}

const dataState = {
  take: 10,
  skip: 0,
};

class SurveyRepairTypes extends ApiResponseHandler<FaultRepairTypesProps, FaultRepairTypesState> {
  private readonly _actionCell: any;
  private readonly _descriptiveAPI: SurveyRepairDescriptiveAPI;

  constructor(props: FaultRepairTypesProps) {
    super(props);
    this.state = {
      // Set loading to true once API or static data loading is done
      loading: true,
      redirect: false,
      innerState: {
        // This is temporary until static data layout is finalised
        FaultRepairTypes: [],
        dataState: dataState,
        bannerMessage: BLANK_BANNER_MESSAGE,
        displayItemDescriptive: 'Item'
      }
    };

    this._actionCell = GridActionsCell({
      onEdit: this.onEdit.bind(this),
      onDelete: this.onDelete.bind(this)
    });

    this._descriptiveAPI = new SurveyRepairDescriptiveAPI(this.query.bind(this));
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    let message = this.state.innerState.bannerMessage;

    let success = values.success || false;
    if (success) message = { message: 'Successfully added new survey/repair type', show: true, warn: false, error: false, successMessage: true };
    this.setState({
      loading: true,
      innerState: {
        ...this.state.innerState,
        bannerMessage: message,
      },
    }, () => this.getFaultRepairTypes());
  }

  getFaultRepairTypes() {
    getFaultRepairTypes(url => this.get(url))
      .then(async faultRepairTypes => {
        this.setState({
          loading: false,
          innerState: {
            ...this.state.innerState,
            FaultRepairTypes: faultRepairTypes
          }
        });

      }).catch((error) => this.handleApiError(error));
  }

  handleApiError(error: string) {
    console.error(error);
    this.setState({
      loading: false,
      innerState: {
        ...this.state.innerState,
        bannerMessage: {
          show: true,
          warn: true,
          message: `API Error: ${error}`,
          error: true,
        },
      },
    });
  }

  onEdit(dataItem: any) {
    if (dataItem.itemDescriptive === 'Descriptive') {
      const id = encodeURIComponent(dataItem.id);
      const type = dataItem.faultRepair === 'Fault' ? 'fdesc' : 'rdesc';
      this.props.history.push(`/SurveyRepairTypes/${id}?type=${type}`);
    } else {
      const id = encodeURIComponent(dataItem.name);
      this.props.history.push(`/SurveyRepairTypes/${id}?type=item&prod=${dataItem.productType.id}`);
    }
  }

  onDelete(dataItem: any) {
    const deletePromise = dataItem.itemDescriptive === 'Descriptive'
      ? this._descriptiveAPI.deleteDescriptive(dataItem)
      : this.deleteItem(dataItem);

    this.handleDeletePromise(deletePromise);
  }

  deleteItem(dataItem: any): Promise<void> {
    const productId = dataItem.productType.id;
    const prodUrl = `${config.apiGateway.META_API}/api/AssetModels/attributes/${productId}/${keys.ProductTypeItemsKey}`;
    return this.get(prodUrl).then((attr: any) => {
      const existingAttr = attr !== undefined;
      if (existingAttr) {
        let attributeArray = JSON.parse(attr.value);
        let idx = attributeArray.findIndex((e: any) => e === dataItem.name);
        if (idx !== -1) {
          attributeArray.splice(idx, 1);
          const putUrl = `${config.apiGateway.META_API}/api/AssetModels/attributes/${productId}`;
          const body = {
            key: keys.ProductTypeItemsKey,
            name: keys.ProductTypeItemsKey,
            description: 'string',
            value: JSON.stringify(attributeArray)
          };
          return this.put(putUrl, JSON.stringify(body));
        }
      }
    });
  }

  private handleDeletePromise = (deletePromise: Promise<void>) => {
    deletePromise
      .then(() => {
        const message = { message: 'Successfully deleted survey/repair type', show: true, warn: false, error: false, successMessage: true };
        this.setState({
          loading: false,
          innerState: {
            ...this.state.innerState,
            bannerMessage: message,
          },
        }, () => this.getFaultRepairTypes());
      })
      .catch((error) => this.handleApiError(error));
  };

  handleAddNewSurveyRepairType() {
    this.props.history.push(`/SurveyRepairTypes/${newId}`);
  }

  handleChangeGridDisplay() {
    let current: string = this.state.innerState.displayItemDescriptive;
    current = current === 'Item' ? 'Descriptive' : 'Item';
    this.setState({
      innerState: {
        ...this.state.innerState,
        displayItemDescriptive: current,
        dataState: {
          ...this.state.innerState.dataState,
          filter: undefined
        }
      }
    });
  }

  renderControlButtons() {
    return (
      <React.Fragment>
        <Button className="mt-3 mr-1" variant="primary" onClick={() => this.handleAddNewSurveyRepairType()}>
          Add new Survey/Repair type
        </Button>
        <Button className="mt-3" variant="primary" onClick={() => this.handleChangeGridDisplay()}>
          {this.state.innerState.displayItemDescriptive === 'Item' ? 'Show Descriptives' : 'Show Items'}
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const faultRepairTypes = this.state.innerState.FaultRepairTypes;
    const items = faultRepairTypes.filter(e => e.itemDescriptive === 'Item');
    const descriptives = faultRepairTypes.filter(e => e.itemDescriptive === 'Descriptive');
    return (
      <React.Fragment>
        <InfoBanner message={this.state.innerState.bannerMessage} />
        <BusyOverlay show={this.state.loading} />
        <div className="title-container">
          <h1 className="page-header">Survey/Repair Types</h1>
        </div>
        {this.state.innerState.displayItemDescriptive === 'Item' ? (
          <React.Fragment>
            <h5 className="form-label">Survey/Repair Items</h5>
            {this.renderControlButtons()}
            <Grid
              className="mt-1"
              pageable
              sortable
              data={process(items, this.state.innerState.dataState)}
              {...this.state.innerState.dataState}
              onDataStateChange={(e) => {
                if (e.dataState.filter) {
                  e.dataState.filter.logic = 'and';
                }
                this.setState({ innerState: { ...this.state.innerState, dataState: e.dataState } });
              }}>
              <Column field="name" title="Name" width="auto" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={items} expanded={true} />} />
              <Column field="productTypeName" title="Product Type" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={items} expanded={true} />} />
              <Column title="Actions" width="150em" cell={this._actionCell} filterable={false} />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h5 className="form-label">Survey/Repair Descriptives</h5>
            {this.renderControlButtons()}
            <Grid
              className="mt-1"
              pageable
              sortable
              data={process(descriptives, this.state.innerState.dataState)}
              {...this.state.innerState.dataState}
              onDataStateChange={(e) => {
                if (e.dataState.filter) {
                  e.dataState.filter.logic = 'and';
                }
                this.setState({ innerState: { ...this.state.innerState, dataState: e.dataState } });
              }}>
              <Column field="id" title="ID" width="80em" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={descriptives} expanded={true} />} />
              <Column field="name" title="Name" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={descriptives} expanded={true} />} />
              <Column field="faultRepair" title="Survey/Repair" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={descriptives} expanded={true} />} />
              <Column title="Actions" width="150em" cell={this._actionCell} filterable={false} />
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

}

export default SurveyRepairTypes;
