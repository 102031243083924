import { HasId } from '../Types';

type MapCallback<T> = (item: T) => T;

/**
 * Create a function which maps an item with expected ID.
 * @param updatingId ID of item to update
 * @param map Callback to map the updated item
 */
export const updateById = <T extends HasId>(updatingId: number | undefined | null, map: MapCallback<T>): MapCallback<T> =>
  (item: T) => item.id === updatingId
    ? map(item)
    : item;