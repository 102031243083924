import * as React from 'react';
import ProductStatusIcon from '../ProductStatusIcon';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default function CustomStatusFilter(props: any) {
   const onChange = (event: any) => {
      const value = event.target.value;
      const { firstFilterProps } = props;

      firstFilterProps.onChange({
         value,
         operator: 'eq',
         syntheticEvent: event.syntheticEvent,
      });
   };

   const radios = [
      { value: 'pass', variant: 'success' as const },
      { value: 'fail', variant: 'danger' as const },
      { value: 'n/a', variant: 'warning' as const },
   ];

   return (
      <ButtonGroup toggle>
         {radios.map((radio, idx) => (
            <ToggleButton
               key={idx}
               type="radio"
               variant={radio.variant}
               value={radio.value}
               checked={props.firstFilterProps.value === radio.value}
               onChange={onChange}>
               <ProductStatusIcon value={radio.value} invert />
            </ToggleButton>
         ))}
      </ButtonGroup>
   );
}
