import { IFormControl } from "../../Model/IFormLayout";
import { UploadedFile } from "../FileUploader/DynamicUpload";
import { DocumentUploadRequest } from "../../Model/API/DocumentUploadRequest";
import { stripBase64Header } from "./StripBase64Header";

export function mapDocumentUploadRequest(control: IFormControl, fileUpload: UploadedFile): DocumentUploadRequest | null {
  if (control.documentType == null || fileUpload.base64 == null)
    return null;

  return {
    name: fileUpload.name,
    contentType: fileUpload.type,
    content:  stripBase64Header(fileUpload.base64),
    documentType: control.documentType,
  };
}

