import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { process, State as DataState } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridDataStateChangeEvent, GridColumn as Column, GridProps } from '@progress/kendo-react-grid';
import { Button } from 'react-bootstrap';
import pluralize from "pluralize";

import { BLANK_BANNER_MESSAGE, createErrorInfoMessage, InfoBanner, InfoMessage } from '../Infobanner';
import ApiResponseHandler from '../ApiResponseHandler';
import { DEFAULT_DATA_STATE } from '../KendoConstants';
import { getErrorMessageOrDefault } from '../Utils/GetErrorMessageOrDefault';
import { createActionCell } from './ActionCell';
import { createGridSortFilterColumn } from './CreateGridSortFilterColumn';
import { PageTitle } from '../PageTitle';
import BusyOverlay from '../BusyOverlay';
import { EDIT_FIELD, setInEdit } from '../Utils/SetInEdit';
import { HasId } from '../Utils/Types';

interface GridPageState<T> {
  bannerMessage: InfoMessage;
  dataState: DataState;
  data: T[];
  inEditId: number | undefined;
}

export default abstract class GridPage<T extends HasId> extends ApiResponseHandler<RouteComponentProps, GridPageState<T>> {
  private readonly _description: string;

  protected ActionCell: (props: GridCellProps) => JSX.Element;
  protected gridOverrides: Partial<GridProps> | undefined;

  protected constructor(props: RouteComponentProps, description: string) {
    super(props);

    this._description = description;

    this.state = {
      loading: true,
      redirect: false,
      innerState: {
        bannerMessage: BLANK_BANNER_MESSAGE,
        dataState: DEFAULT_DATA_STATE,
        data: [],
        inEditId: undefined,
      }
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleAddNewItem = this.handleAddNewItem.bind(this);

    this.ActionCell = createActionCell({ onEdit: this.handleEdit });
  }

  public componentDidMount() {
    this.fetchData()
      .then(data => {
        this.setState(prevState => ({
          loading: false,
          innerState: {
            ...prevState.innerState,
            data,
          }
        }));
      })
      .catch(this.handleLoadingError);
  }

  /**
   * Fetch data to display in grid.
   * @protected
   */
  protected abstract fetchData(): Promise<T[]>;

  /**
   * Handle event when user edits an item in a grid.
   * @param item Item users wishes to edit.
   */
  protected abstract handleEdit(item: T): void;

  /**
   * Handle event when user adds a new item to the grid.
   */
  protected abstract handleAddNewItem(): void;

  private handleLoadingError = (error: unknown) => {
    const message = getErrorMessageOrDefault(error, 'Unable to load data at this time');

    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        bannerMessage: createErrorInfoMessage(message)
      }
    }));
  };

  private handleDataStateChange = (e: GridDataStateChangeEvent) =>
    this.setState(prevState => ({
      innerState: {
        ...prevState.innerState,
        dataState: e.data
      }
    }));

  render() {
    const {
      loading,
      innerState: { bannerMessage, dataState, data, inEditId }
    } = this.state;

    const filterColumn = createGridSortFilterColumn(data, {fuzzy:true});

    const processedData = process(
      data.map(setInEdit(inEditId)),
      dataState
    );

    return (
      <React.Fragment>
        <InfoBanner message={bannerMessage}/>
        <PageTitle title={pluralize(this._description)}/>
        <BusyOverlay show={loading}/>
        <Grid
          className="mt-5"
          pageable
          sortable
          data={processedData}
          editField={EDIT_FIELD}
          {...dataState}
          onDataStateChange={this.handleDataStateChange}
          {...this.gridOverrides}
        >
          <Column
            field="id"
            title="ID"
            width="80em"
            columnMenu={filterColumn}
            editable={false}
          />
          <Column
            field="name"
            title="Name"
            columnMenu={filterColumn}
          />
          <Column
            title="Actions"
            width="150em"
            cell={this.ActionCell}
            filterable={false}
          />
        </Grid>
        <Button className="mt-2" variant="primary" onClick={this.handleAddNewItem} disabled={inEditId != null}>
          Add new {this._description}
        </Button>
      </React.Fragment>
    );
  }
}
