import * as React from 'react';
import {Modal,Button,Form} from 'react-bootstrap';
import { InfoBanner, InfoMessage } from '../Shared/Infobanner';
import config from '../../config';

import styles from './loginModal.module.css';
import { JwtManager } from '../Shared/JwtManager';

interface LoginModalProps {
    showLoginModal: boolean;
    projectTitle: string;
}

interface LoginModalState {
    errorMessage?: '';
    username: string;
    password: string;
    submitted: boolean;
    loading: boolean;
    message?: InfoMessage;
    error?: boolean;
}

export class LoginModal extends React.Component<LoginModalProps, LoginModalState>{
    tokenManager = new JwtManager({});
    
    constructor(props: LoginModalProps)
    {
        super(props);
        this.state = {
            username: "",
            password: "",
            submitted: false,
            loading: false        
        }
    }

    handleSubmit(e: any) {
        e.preventDefault();
        var state = this.state;
        var username = state.username;
        var password = state.password;
        this.setState({ ...state,
            loading: true,
            submitted: true
        });

        // stop here if form is invalid
        if (username === "" && password === "") {
            return;
        }

        let postBody = 'grant_type=password&username='+ username + '&password='+password;
        var authEndpoint = config.apiGateway.AUTH_API + "/api/token";
        fetch(authEndpoint,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: postBody
            })
            .then(resp => this.handleResponse(resp))
            .catch(error => {
                this.setState({
                    ...this.state,
                    message: {
                        message:`An error has occurred: ${error}`,
                        warn: false,
                        show: true,
                        error: true
                    },
                    loading: false,
                });
            });
    }

    handleResponse(response: Response) {
        if (response.status === 401) {
            this.setState({
                message: {
                    show: true,
                    warn: true,
                    message: "User is Unauthorized.",
                    error: true
                },
                submitted: false,
                loading: false
            });
        } else if (!response.ok) {

            this.setState({
                message: {
                    show: true,
                    warn: true,
                    message: `Response ${response.status}: ${response.statusText}`,
                    error: true
                },
                submitted: false,
                loading: false
            })
        } else {
            this.handleSuccess(response)
        }
    }

    handleSuccess(response: Response)
    {
        response.json().then(resp => {
            this.tokenManager.storeAuthResponse(
                resp.token,
                resp.expires,
                resp.refresh_token
            );
            window.location.reload();
        });
    }

    handleChange(e: any) {
        const { name, value } = e.target;
        if(name === "username")
           this.setState({
            ...this.state,
            username: value,
            message: undefined
           });
        else if(name === "password")
            this.setState({
                ...this.state,
                password: value,
                message: undefined
            });
    }

    render() {
        const showModal = this.props.showLoginModal;

        return (
          <Modal show={showModal} backdrop="static" className={styles.loginModal}>
              <Modal.Header className={styles.customModalHeader}>
                  <Modal.Title>Login - {this.props.projectTitle}</Modal.Title>
              </Modal.Header>
              <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Modal.Body>
                      <Form.Group controlId="formBasicEmail">
                          <Form.Label>Username</Form.Label>
                          <Form.Control name="username" required={true} value={this.state.username} onChange={this.handleChange.bind(this)} type="text" placeholder="Enter username"/>
                          <Form.Text className="text-muted">
                              Substation 360 login credentials
                          </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control name="password" required={true} type="password" placeholder="Password" value={this.state.password}
                                        onChange={this.handleChange.bind(this)}/>
                      </Form.Group>
                      {(!this.state.message) ? '' :
                        <InfoBanner message={this.state.message}/>
                      }
                  </Modal.Body>
                  <Modal.Footer>
                      <Button
                        disabled={this.state.loading}
                        className="blue-fill"
                        variant="primary"
                        type="submit"
                      >
                          {this.state.loading ? 'Please wait..' : 'Login'}
                      </Button>
                  </Modal.Footer>
              </Form>
          </Modal>
        ) 
    }

}