import * as React from "react";
import { GridColumnMenuProps } from "@progress/kendo-react-grid";
import GridSortFilterColumn, { GridSortFilterColumnOptions } from "../GridFilters/GridSortFilterColumn";

/**
 * Create a specialised {@see GridSortFilterColumn} component for the given data set.
 * @param data Data to allow sorting and filtering on
 */
export function createGridSortFilterColumn<T extends string | object>(data: T[], extraProps?: GridSortFilterColumnOptions) {
  return (props: GridColumnMenuProps) => <GridSortFilterColumn {...props} {...extraProps} data={data} expanded={true}/>;
}