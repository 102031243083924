import { useCallback } from 'react';
import { ContactGridData } from '../Types';

// Change handlers for contact email addresses
export default (setEditingIndex: (index: number) => void, contacts: string[], onChange: (updated: string[]) => void) => {
  const handleEdit = useCallback((contact: ContactGridData) => setEditingIndex(contact.index), [contacts]);

  const handleDelete = useCallback(
    (toDelete: ContactGridData) => {
      const updated = contacts.filter((_, index) => index !== toDelete.index);
      onChange(updated);
    }, [contacts, onChange]);

  const handleAdd = useCallback(() => {
    const updated = contacts.concat('');
    onChange(updated);
    setEditingIndex(updated.length - 1);
  }, [contacts, onChange]);

  return { handleEdit, handleDelete, handleAdd };
}