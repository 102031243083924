import React from 'react';
import { TestEquipmentCard, TestEquipmentCardBody, warningCardText } from '../TestEquipmentCard';
import { CommonTestEquipmentCardProps } from './Types';
import TestEquipmentDescription from './TestEquipmentDescription';

interface UncalibratedTestEquipmentCardProps extends CommonTestEquipmentCardProps {
  assetTag: string;
  description: string;
  deviceOwner: string;
  location: string;
}

export function UncalibratedTestEquipmentCard({ deviceOwner, location, description, ...cardProps }: UncalibratedTestEquipmentCardProps) {
  return (
    <TestEquipmentCard {...cardProps} variant="warning">
      <TestEquipmentCardBody>
        <TestEquipmentDescription description={description}/>
        <p className={warningCardText}>This hasn't been calibrated!</p>
      </TestEquipmentCardBody>
    </TestEquipmentCard>
  );
}
