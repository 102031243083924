import * as React from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

interface ModeSwitchProps<TMode extends string> {
  label: string;
  currentMode: TMode;
  modes: readonly TMode[];
  onModeChange: (value: TMode) => void;
}

export function ModeSwitch<TMode extends string>({ label, currentMode, modes, onModeChange }: ModeSwitchProps<TMode>) {
  return (
    <div className="d-inline-flex flex-column mb-2">
      <Form.Label>{label}</Form.Label>
      <ToggleButtonGroup type="radio" name="modes" defaultValue={[currentMode]} onChange={onModeChange}>
        {
          modes.map(mode => (
            <ToggleButton
              key={mode}
              value={mode}
            >
              {mode}
            </ToggleButton>
          ))
        }
      </ToggleButtonGroup>
    </div>
  );
}