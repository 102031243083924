import { IFormControl } from '../Model/IFormLayout';
import { mapDocumentUploadRequest } from '../Shared/Utils/MapDocumentUploadRequest';
import { UploadedFile } from '../Shared/FileUploader/DynamicUpload';
import { parseUploadedDocumentAsRequest } from '../Shared/Utils/ParseUploadedDocument';
import { DocumentUploadRequest } from '../Model/API/DocumentUploadRequest';

export async function extractOutFields(resultData: Record<string, unknown>, formControls: IFormControl[]): Promise<Record<string, unknown>> {
  let extractedResultData = Object.assign({}, resultData);
  for (const [key, val] of Object.entries(resultData)) {
    if (val) {
      const valObject = val as any;
      const control = formControls.find((control) => control.variable === key);
      if (!control)
        continue;
      switch (control.type.toLowerCase()) {
        case 'dropdown':
          if (control.outField) {
            extractedResultData[key] = valObject[control.outField];
            extractedResultData[`${key}_METADATA`] = valObject;
          }
          break;
        case 'testfield':
          extractedResultData[key] = valObject.passOrFail;
          extractedResultData[`${key}_value`] = valObject.value;
          break;
        case 'multiselect':
          if (control.outField) {
            extractedResultData[key] = [].concat(...valObject.map((entry: any) => {
              if (control.outField)
                return [entry[control.outField]];
              else
                return [];
            }));
            extractedResultData[`${key}_METADATA`] = valObject;
          }
          extractedResultData[`${key}_values`] = extractedResultData[key];
          extractedResultData[key] = extractedResultData[key];
          break;
        case 'upload':
          const uploadData = extractedResultData[key];
          let uploadRequest: DocumentUploadRequest | null = null;

          // If a new file was uploaded we can read the content from the file
          if (typeof uploadData === 'object') {
            uploadRequest = mapDocumentUploadRequest(control, uploadData as UploadedFile);
          }
          // If existing file was reused the JSON metadata needs to be converted into a document upload request
          else if (typeof uploadData === 'string') {
            uploadRequest = await parseUploadedDocumentAsRequest(uploadData);
          }

          extractedResultData[key] = uploadRequest;
          break;
        case 'testequipment':
          extractedResultData[key] = valObject;
          break;
      }
    }
  }
  return extractedResultData;
}
