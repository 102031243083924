import React, { ReactNode } from 'react';

interface TestEquipmentCardBodyProps {
  children: ReactNode;
}

// Textual body for `TestEquipmentCard` which reduces the line height for improved readability.
export default ({ children }: TestEquipmentCardBodyProps) => (
  <div style={{ lineHeight: '1rem', marginTop: '1rem' }}>
    {children}
  </div>
)
