import React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';

import TestEquipment from '../../Model/TestEquipment';
import ApiResponseHandler from '../../Shared/ApiResponseHandler';
import TestEquipmentAPI from '../../Shared/Data/TestEquipment/TestEquipmentAPI';
import { BLANK_BANNER_MESSAGE, createErrorInfoMessage, InfoBanner, InfoMessage } from '../../Shared/Infobanner';
import { getErrorMessageOrDefault } from '../../Shared/Utils/GetErrorMessageOrDefault';
import BusyOverlay from '../../Shared/BusyOverlay';
import { PageTitle } from '../../Shared/PageTitle';
import { CalibrationSummary } from './CalibrationSummary';
import { TestEquipmentQRCode } from '../QRCode';

interface ViewTestEquipmentRouteMatchProps {
  id: string;
}

type ViewTestEquipmentRouteLocationState = { testEquipment: TestEquipment } | null;

type ViewTestEquipmentProps = RouteComponentProps<ViewTestEquipmentRouteMatchProps, StaticContext, ViewTestEquipmentRouteLocationState>;

interface ViewTestEquipmentState {
  testEquipment: TestEquipment | undefined;
  bannerMessage: InfoMessage;
}

export default class ViewTestEquipment extends ApiResponseHandler<ViewTestEquipmentProps, ViewTestEquipmentState> {
  private _testEquipmentAPI = new TestEquipmentAPI(this.query.bind(this));

  constructor(props: ViewTestEquipmentProps) {
    super(props);

    // Use test equipment from location state if possible
    const testEquipment = this.props.location.state?.testEquipment;

    this.state = {
      redirect: false,
      loading: testEquipment == null,
      innerState: {
        testEquipment,
        bannerMessage: BLANK_BANNER_MESSAGE,
      }
    };
  }

  get id(): Number {
    return Number(this.props.match.params.id);
  }

  public componentDidMount() {
    this._testEquipmentAPI.getAll()
      .then(this.handleTestEquipmentLoaded)
      .catch(this.handleLoadingError);
  }

  private handleTestEquipmentLoaded = (loaded: TestEquipment[]) => {
    const matchingTestEquipment = loaded.find(te => te.id === this.id);
    const bannerMessage = matchingTestEquipment
      ? BLANK_BANNER_MESSAGE
      : createErrorInfoMessage(`Could not find test equipment with ID ${this.id}`);

    this.setState({
      loading: false,
      innerState: {
        testEquipment: matchingTestEquipment,
        bannerMessage,
      }
    });
  };

  private handleLoadingError = (e: unknown) => {
    const message = getErrorMessageOrDefault(e, 'An error occurred while loading test equipment');
    this.setState({
      loading: false,
      innerState: {
        testEquipment: undefined,
        bannerMessage: createErrorInfoMessage(message)
      }
    });
  };

  render(): JSX.Element {
    const { loading, innerState } = this.state;
    const { testEquipment, bannerMessage } = innerState;

    return (
      <>
        <BusyOverlay show={loading} />
        <PageTitle title={`Test Equipment ${testEquipment?.serialNumber.full}`} />

        {
          testEquipment
          && (
            <>
              <div>
                <h5>Calibration</h5>
                <CalibrationSummary testEquipment={testEquipment} />
              </div>

              <div className="mt-5">
                <h5>QR Code</h5>
                <TestEquipmentQRCode testEquipment={testEquipment} />
              </div>
            </>
          )
        }

        <InfoBanner message={bannerMessage} />
      </>
    );
  }
}

