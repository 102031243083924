import { useMemo } from 'react';

import { process, State as DataState } from '@progress/kendo-data-query';

import { setInEdit } from '../Utils/SetInEdit';
import { HasId } from '../Utils/Types';

/**
 * Process items with sorting and filtering for use in Kendo grids.
 * @param items Items to process
 * @param editingId ID of the item under edit
 * @param dataState Data state containing sorting and filtering criteria
 */
export const useProcessedGridData = <T extends HasId>(items: T[], dataState: DataState, editingId?: number) => {
  return useMemo(() => {
    const editingLocations = items.map(setInEdit(editingId));
    return process(editingLocations, dataState);
  }, [items, editingId, dataState]);
}