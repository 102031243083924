import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LoadingOverlayProps {
   show: boolean;
   message?: string;
}

const BusyOverlay: React.SFC<LoadingOverlayProps> = (props) => {
   if (!props.show) {
      return null;
   }

   return (
      <div className="loading-overlay">
         <span className="loading-span">{props.message ? props.message : ""}</span>
         <FontAwesomeIcon icon="sync-alt" className="refresh" />
      </div>
   );
};

export default BusyOverlay;
