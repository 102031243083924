import * as React from 'react';
import { FC } from 'react';

interface ListMessageProps {
  description: string;
  list: string[];
}

export const ListMessage: FC<ListMessageProps> = ({ description, list }) =>
  <>
    <span>{description}</span>
    <ul className="mt-3">
      {list.map(item => <li key={item}>{item}</li>)}
    </ul>
  </>;