import IEndpoint from "../Model/IEndpoint";

class Endpoint implements IEndpoint {
    name: string;
    url: string;
    params: string[];
    paramValues: { [x: string]: string; };
    inFields: { [x:string]: string };
    outFields: string[];

    constructor(name: string, url: string) {
        this.name = name;
        this.url = url;
        this.params = [];
        this.paramValues = {};
        this.inFields = {};
        this.outFields = [];
        const matches = this.url.match(/{.+?}/g);
        if(matches)
            this.params = matches.map((m:string) => m.replace(/[{}]/g,''));
        this.params.forEach((p:string)=>{
            this.paramValues[p] = '';
        });
    }

    get parsedUrl() {
        let parsedUrl = this.url;
        this.params.forEach((p:string) => {
            const pValue = this.inFields[p] ? this.inFields[p] : p;
            parsedUrl = parsedUrl.replace(`{${p}}`, this.paramValues[pValue]);
        });
        return parsedUrl;
    }

    get isValid() {
        let isValid: boolean = true;
        this.params.forEach((p:string) => {
            if(!this.paramValues[p])
                isValid = false;
        });
        return isValid;
    }
}

export default Endpoint;