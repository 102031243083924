import { useCallback } from 'react';
import { GridItemChangeEvent } from '@progress/kendo-react-grid';

// Grid item change handler for contact email addresses
export default (editingIndex: number | undefined, contacts: string[], onChange: (updated: string[]) => void) => useCallback(
  (event: GridItemChangeEvent) => {
    if (editingIndex == null)
      return;

    const updated = contacts.map((contact, index) =>
      index === editingIndex
        ? event.value as string
        : contact
    );

    onChange(updated);
  }, [editingIndex, contacts, onChange]);
