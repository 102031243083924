import { useMemo } from 'react';
import moment from 'moment';

// Figure out if the calibration has expired, and how long to/since expiry
export const useCalibrationExpiry = (expires: Date) =>
  useMemo(() => {
    const currentMoment = moment();
    const validityDifference = moment(expires).diff(currentMoment);

    const expired = validityDifference < 0;
    const validityDuration = moment.duration(validityDifference);

    return { expired, validityDuration };
  }, [expires]);