import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import ISurveyRepairType from '../Model/ISurveyRepairType';
import { InfoMessage, InfoBanner, BLANK_BANNER_MESSAGE } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import { Form, Container, Row, Col } from 'react-bootstrap';
import config, { newId, keys } from '../../config';
import FormButtons from '../Shared/FormButtons';
import { CustomDropdownList } from '../Shared/DropdownList';
import IProductType from '../Model/IProductType';
import queryString from 'query-string';
import { getProductTypes } from '../Shared/Data/GetProductTypes';
import { getFaultRepairDescriptive } from '../Shared/Data/SurveyRepair/GetFaultRepairDescriptive';
import { SurveyRepairDescriptiveAPI } from '../Shared/Data/SurveyRepair/SurveyRepairDescriptiveAPI';

export interface SurveyRepairTypeProps extends RouteComponentProps<any> {
  id: string;
}

export interface SurveyRepairTypeState {
  faultRepairType: ISurveyRepairType,
  bannerMessage: InfoMessage,
  faultRepairTypeCache: ISurveyRepairType[],
  productTypes: IProductType[],
  originalProductID: number,
  originalName: string
}

class SurveyRepairType extends ApiResponseHandler<SurveyRepairTypeProps, SurveyRepairTypeState> {
  private readonly _surveyRepairAPI: SurveyRepairDescriptiveAPI;

  constructor(props: SurveyRepairTypeProps) {
    super(props);
    this.state = {
      // Set loading to True when data loading set up
      loading: true,
      redirect: false,
      innerState: {
        faultRepairType: { id: 0, name: '', faultRepair: '', itemDescriptive: '' },
        bannerMessage: BLANK_BANNER_MESSAGE,
        faultRepairTypeCache: [],
        productTypes: [],
        originalProductID: 0,
        originalName: ''
      }
    };

    this._surveyRepairAPI = new SurveyRepairDescriptiveAPI(this.query.bind(this));
  }

  componentDidMount() {
    const id = decodeURIComponent(this.props.match.params.id);
    const values = queryString.parse(this.props.location.search);
    const validTypes = ['item', 'rdesc', 'fdesc'];
    let type: string = '';

    if (id !== newId && (!values.type || typeof values.type !== 'string' ||
      !validTypes.includes(values.type as string))) {
      this.setState({
        loading: false,
        innerState: {
          ...this.state.innerState,
          bannerMessage: {
            show: true,
            warn: true,
            message: `Invalid type in URL must be either item, rdesc, fdesc`,
            error: true,
          },
        }
      });
      return;
    }
    if (id !== newId) {
      // Fine to assert string as we would have returned already if it were not
      type = values.type as string;
    }

    const parsedId = Number(id);

    Promise.all([
      getProductTypes(url => this.get(url)),
      isNaN(parsedId) ? null : getFaultRepairDescriptive(url => this.get(url), Number(id))
    ]).then(([productTypes, typeApiData]) => {
      let faultRepairType: ISurveyRepairType = { id: 0, name: '', itemDescriptive: '' };
      let originalProductID = 0;
      if (id !== newId) {
        if (type !== 'item') {
          faultRepairType = typeApiData ?? faultRepairType;
        } else {
          const curr = productTypes?.find((e: any) => e.id === values.prod);
          const prodInfo = curr ? { productType: curr, productTypeName: curr.name } : {};
          if (curr !== undefined)
            originalProductID = curr.id;
          faultRepairType = {
            ...faultRepairType,
            id: 0,
            name: id,
            itemDescriptive: 'Item',
            ...prodInfo
          };
        }
      }
      this.setState({
        loading: false,
        innerState: {
          ...this.state.innerState,
          faultRepairType: faultRepairType,
          productTypes: productTypes ?? [],
          originalProductID: originalProductID,
          originalName: id
        }
      });
    }).catch((error) => {
      this.handleApiError(error);
    });

  }

  handleApiError(error: string) {
    console.error(error);
    this.setState({
      loading: false,
      innerState: {
        ...this.state.innerState,
        bannerMessage: {
          show: true,
          warn: true,
          message: `API Error: ${error}`,
          error: true,
        },
      },
    });
  }

  validateForm() {
    if (!this.state.innerState.faultRepairType.name) return this.setValidationError('You must provide a name for the Survey/Repair type.');
    if (!this.state.innerState.faultRepairType.itemDescriptive) return this.setValidationError('You must select either item or descriptive from the dropdown.');
    if (this.state.innerState.faultRepairType.itemDescriptive === 'Descriptive'
      && !this.state.innerState.faultRepairType.faultRepair) return this.setValidationError('You must select a Survey/Repair type from the dropdown.');
    if (this.state.innerState.faultRepairType.itemDescriptive === 'Item'
      && !this.state.innerState.faultRepairType.productType) return this.setValidationError('You must select an associated product for a Survey/Repair item');
    return true;
  }

  setValidationError(errorMessage: string) {
    this.setState({
      loading: false,
      innerState: {
        ...this.state.innerState,
        bannerMessage: {
          show: true,
          warn: true,
          message: errorMessage,
          error: true,
        },
      },
    });

    return false;
  }

  saveFaultRepairType() {
    if (!this.validateForm()) return;

    const id = decodeURIComponent(this.props.match.params.id);
    const faultRepairType = this.state.innerState.faultRepairType;

    if (faultRepairType.itemDescriptive === 'Descriptive') {
      const isNew = id === newId;
      this._surveyRepairAPI.saveDescriptive(isNew, faultRepairType)
        .then(() => this.props.history.push(`/SurveyRepairTypes?success=true`))
        .catch((error) => this.handleApiError(error));
    } else {
      if (!faultRepairType.productType) {
        this.setValidationError('Associated Product must have a valid ID.');
        return;
      }
      let savePromise: Promise<any>;
      const pid = faultRepairType.productType.id;
      const attributeGetUrl = config.apiGateway.META_API + `/api/AssetModels/attributes/${pid}/${keys.ProductTypeItemsKey}`;
      const attributePostPutURL = `${config.apiGateway.META_API}/api/AssetModels/attributes/${pid}`;
      this.get(attributeGetUrl).then((attr: any) => {
        const existingAttr = attr !== undefined;
        let attributeArray = !existingAttr ? [] : JSON.parse(attr.value);
        if (attributeArray.length === 0) {
          attributeArray.push(faultRepairType.name);
        } else {
          const idx = attributeArray.findIndex((e: any) => e === faultRepairType.name);
          if (idx === -1)
            attributeArray.push(faultRepairType.name);
          else
            attributeArray[idx] = faultRepairType.name;
        }

        if (existingAttr)
          savePromise = this.saveExistingItem(attributePostPutURL, attributeArray);
        else
          savePromise = this.saveNewItem(attributePostPutURL, attributeArray);

        savePromise.then(() => {
          if (id !== newId) {
            const originalAttributeUrl = `${config.apiGateway.META_API}/api/AssetModels/attributes/${this.state.innerState.originalProductID}`;
            return this.removeOriginalAttribute(originalAttributeUrl);
          }
        }).then(() => {
          this.props.history.push(`/SurveyRepairTypes?success=true`);
        }).catch((error) => this.handleApiError(error));
      }).catch((error) => this.handleApiError(error));
    }
  }

  removeOriginalAttribute(prodUrl: string) {
    const originalAttributeURl = `${prodUrl}/${keys.ProductTypeItemsKey}`;
    return this.get(originalAttributeURl).then((attr: any) => {
      const existingAttr = attr !== undefined;
      if (existingAttr) {
        let attributeArray = JSON.parse(attr.value);
        let idx = attributeArray.findIndex((e: any) => e === this.state.innerState.originalName);
        if (idx !== -1) {
          attributeArray.splice(idx, 1);
          const body = {
            key: keys.ProductTypeItemsKey,
            name: keys.ProductTypeItemsKey,
            description: 'string',
            value: JSON.stringify(attributeArray)
          };
          return this.put(prodUrl, JSON.stringify(body));
        }
      }
      return true;
    });
  }

  saveNewItem(itemUrl: string, body: string[]) {
    const payload = {
      key: keys.ProductTypeItemsKey,
      name: keys.ProductTypeItemsKey,
      description: 'string',
      value: JSON.stringify(body)
    };
    return this.post(itemUrl, JSON.stringify(payload));
  }

  saveExistingItem(itemUrl: string, body: string[]) {
    const payload = {
      key: keys.ProductTypeItemsKey,
      name: keys.ProductTypeItemsKey,
      description: 'string',
      value: JSON.stringify(body)
    };
    return this.put(itemUrl, JSON.stringify(payload));
  }


  handleButtonClick(e: any) {
    if (e.target.value === 'save') this.saveFaultRepairType();
    else this.props.history.push(`/SurveyRepairTypes`);
  }

  handleChangeName(e: any) {
    this.setState({
      innerState: {
        ...this.state.innerState,
        faultRepairType: { ...this.state.innerState.faultRepairType, name: e.target.value }
      }
    });
  }

  handleChangeFaultRepair(e: any) {
    this.setState({
      innerState: {
        ...this.state.innerState,
        faultRepairType: { ...this.state.innerState.faultRepairType, faultRepair: e }
      }
    });
  }

  handleChangeItemDescriptive(e: any) {
    let faultRepairType: ISurveyRepairType = { ...this.state.innerState.faultRepairType, itemDescriptive: e };
    if (e === 'Descriptive') {
      delete faultRepairType.productType;
      delete faultRepairType.productTypeName;
    } else
      delete faultRepairType.faultRepair;
    this.setState({
      innerState: {
        ...this.state.innerState,
        faultRepairType: faultRepairType
      }
    });
  }

  handleChangeProductType(e: any) {
    this.setState({
      innerState: {
        ...this.state.innerState,
        faultRepairType: { ...this.state.innerState.faultRepairType, productType: e, productTypeName: e.name }
      }
    });
  }

  getNamePlaceholderText() {
    let placeholderText = 'Enter survey/repair type name';
    if (this.state.innerState.faultRepairType.itemDescriptive === 'Descriptive')
      placeholderText = 'Enter descriptive name';
    if (this.state.innerState.faultRepairType.itemDescriptive === 'Item')
      placeholderText = 'Enter survey/repair item name';

    return placeholderText;
  }

  render() {
    return (
      <React.Fragment>
        <InfoBanner message={this.state.innerState.bannerMessage} />
        <BusyOverlay show={this.state.loading} />
        <div className="title-container">
          <h1 className="page-header">{this.props.match.params.id === newId ? 'Create New' : 'Edit'} Survey/Repair Type</h1>
        </div>
        <Form>
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="recordTypeName"
            required={true}
            value={this.state.innerState.faultRepairType.name}
            type="text"
            placeholder={this.getNamePlaceholderText()}
            onChange={this.handleChangeName.bind(this)}
          />
          <Form.Text className="sm text-muted m-1">ID:{this.state.innerState.faultRepairType.id}</Form.Text>
          <Form.Group>
            <Container>
              <Row>
                <Col>
                  <Form.Label className="mt-3">Item or Descriptive</Form.Label>
                  <CustomDropdownList
                    data={['Item', 'Descriptive']}
                    defaultValue={this.state.innerState.faultRepairType.itemDescriptive}
                    onChange={this.handleChangeItemDescriptive.bind(this)}
                  />
                </Col>
                {this.state.innerState.faultRepairType.itemDescriptive === 'Descriptive' ? (
                  <Col>
                    <Form.Label className="mt-3">Survey/Repair Category</Form.Label>
                    <CustomDropdownList
                      data={['Fault', 'Repair', 'Quotation']}
                      defaultValue={this.state.innerState.faultRepairType.faultRepair}
                      onChange={this.handleChangeFaultRepair.bind(this)}
                    />
                  </Col>
                ) : ('')
                }
              </Row>
            </Container>
          </Form.Group>
          {this.state.innerState.faultRepairType.itemDescriptive === 'Item' ? (
            <Form.Group className="m-3">
              <Form.Label>Select Product Type</Form.Label>
              <CustomDropdownList
                data={this.state.innerState.productTypes}
                defaultValue={this.state.innerState.faultRepairType.productType}
                onChange={this.handleChangeProductType.bind(this)}
              />
            </Form.Group>
          ) : ('')
          }
        </Form>
        <FormButtons handleButtonClick={this.handleButtonClick.bind(this)} />
      </React.Fragment>

    );
  }

}

export default withRouter(SurveyRepairType);