import * as React from 'react';
import { FormEventHandler, useState } from 'react';

import { Form } from 'react-bootstrap';

import { TestEquipmentFormControl } from '../index';
import { parseUnknownSerialNumber } from '../../../Model/AssetQRCode';

interface TestEquipmentFormProps {
  label: string;
  serialNumbers: string[];
  onChange: (serialNumbers: string[]) => void;
  disabled: boolean;
}

export function TestEquipmentForm({ label, serialNumbers, onChange, disabled }: TestEquipmentFormProps) {
  const [serialInput, setSerialInput] = useState('');

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    const submittedSerialNumber = parseUnknownSerialNumber(serialInput);
    const withSubmission = [...serialNumbers, submittedSerialNumber];
    setSerialInput('');
    onChange(withSubmission);
  };

  const handleAdd = (added: string) => {
    const withNewSerialNumber = [...serialNumbers, added];
    onChange(withNewSerialNumber);
    setSerialInput('');
  };

  const handleDelete = (deleted: string) => {
    const withoutDeletedSerialNumber = serialNumbers.filter(serial => serial !== deleted);
    onChange(withoutDeletedSerialNumber);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Label>{label}</Form.Label>
      <TestEquipmentFormControl
        serialNumbers={serialNumbers}
        value={serialInput}
        onChange={setSerialInput}
        onAdd={handleAdd}
        onDelete={handleDelete}
        disabled={disabled}
      />
    </Form>
  );
}