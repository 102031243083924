import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import { Button } from 'react-bootstrap';
import { process } from '@progress/kendo-data-query';
import { RouteComponentProps } from 'react-router-dom';
import { GridActionsCell } from '../Shared/GridActionsCell';
import config, { newId } from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import IRecordType from '../Model/IRecordType';
import queryString from 'query-string';

export interface RecordTypesProps extends RouteComponentProps<any> {}

export interface RecordTypesState {
   recordTypes: IRecordType[];
   dataState: any;
   bannerMessage: InfoMessage;
}

const dataState = {
   take: 10,
   skip: 0,
};

class RecordTypes extends ApiResponseHandler<RecordTypesProps, RecordTypesState> {
   actionCell: any;
   blankBannerMessage = { message: '', show: false, warn: false, error: false };

   constructor(props: RecordTypesProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: { recordTypes: [], dataState, bannerMessage: this.blankBannerMessage },
      };
      this.actionCell = GridActionsCell({
         onEdit: this.onEdit.bind(this),
         onDelete: this.onDelete.bind(this),
         onReport: this.onShowReports.bind(this),
      });
   }

   componentDidMount() {
      const values = queryString.parse(this.props.location.search);
      let message = this.state.innerState.bannerMessage;

      let success = values.success || false;
      if (success) message = { message: 'Successfully added new record type', show: true, warn: false, error: false, successMessage: true };

      this.setState({
         loading: true,
         innerState: {
            ...this.state.innerState,
            bannerMessage: message,
         },
      });

      this.getRecordTypes();
   }

   getRecordTypes() {
      const recordTypesUrl = config.apiGateway.META_API + '/api/datapointtypes/';

      this.get(recordTypesUrl)
         .then((response) => {
            let recordTypes = response
               .map((recordTypeJson: any) => ({
                  name: recordTypeJson.name,
                  id: recordTypeJson.id,
                  captureType: recordTypeJson.valueType,
                  category: recordTypeJson.units,
               }))
               .filter((rt: IRecordType) => rt.captureType === 'form' || rt.captureType === 'file');

            this.setState({ loading: false, innerState: { ...this.state.innerState, recordTypes } });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   handleAddNewRecordType() {
      this.props.history.push(`/RecordTypes/${newId}`);
   }

   onEdit(dataItem: any) {
      this.props.history.push(`/RecordTypes/${dataItem.id}`);
   }

   onDelete(dataItem: any) {
      const recordTypesUrl = config.apiGateway.META_API + '/api/datapointtypes/' + dataItem.id;

      this.delete(recordTypesUrl)
         .then(() => {
            this.getRecordTypes();
            this.setState({
               ...this.state,
               innerState: {
                  ...this.state.innerState,
                  bannerMessage: this.blankBannerMessage,
               },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   onShowReports(dataItem: any) {
      this.props.history.push(`/Records?filter=recordTypeid&value=${dataItem.id}`);
   }

   render() {
      const recordTypes = this.state.innerState.recordTypes;

      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <div className="title-container">
               <h1 className="page-header">Record Types</h1>
            </div>
            <Grid
               className="mt-5"
               pageable
               sortable
               data={process(recordTypes, this.state.innerState.dataState)}
               {...this.state.innerState.dataState}
               onDataStateChange={(e) => {
                  this.setState({ innerState: { ...this.state.innerState, dataState: e.data } });
               }}>
               <Column field="id" title="ID" width="80em" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={recordTypes} expanded={true} />} />
               <Column field="name" title="Name" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={recordTypes} expanded={true} />} />
               <Column field="captureType" title="Capture" columnMenu={(p) => <GridSortFilterColumn fuzzy {...p} data={recordTypes} expanded={true} />} />
               <Column field="category" title="Category" columnMenu={(p) => <GridSortFilterColumn fuzzy {...p} data={recordTypes} expanded={true} />} />
               <Column title="Actions" width="150em" cell={this.actionCell} filterable={false} />
            </Grid>
            <Button className="mt-2" variant="primary" onClick={() => this.handleAddNewRecordType()}>
               Add new record type
            </Button>
         </React.Fragment>
      );
   }
}

export default RecordTypes;
