import NavigationItem from '../Model/NavigationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { JwtManager } from './JwtManager';
import config from '../../config';

var tokenManager = new JwtManager({});

export function UpdateNavItems() {
   var nav = returnNavItems();
   var claims = tokenManager.getClaims();
   if (claims === config.externalSupplierView) {
      nav = nav.filter((navItem: NavigationItem) => {
         return config.externalSupplierComponents.includes(navItem.component);
      });
      return nav;
   } else if (claims === config.ITRInternalUserView) {
      nav = nav.filter((navItem: NavigationItem) => {
         return config.internalUserComponents.includes(navItem.component);
      });
      return nav;
   } else if (claims === config.viewAllClaim || claims === config.ITRConfiguratorView) {
      return nav;
   }

   return [];
}

function returnNavItems() {
   const navItems: NavigationItem[] = [
      {
         route: '/Home',
         text: 'Home',
         icon: <FontAwesomeIcon icon="home" size="2x" />,
         component: 'Home',
      },
      {
         route: '/Suppliers',
         text: 'Suppliers',
         icon: <FontAwesomeIcon icon="boxes" size="2x" />,
         component: 'SuppliersList',
      },
      {
         route: '/Owners',
         text: 'Device Owners',
         icon: <FontAwesomeIcon icon="warehouse" size="2x" />,
         component: 'OwnerList',
      },
      {
         route: '/Calibrators',
         text: 'Calibrators',
         icon: <FontAwesomeIcon icon="laptop-house" size="2x" />,
         component: 'CalibratorList',
      },
      {
         route: '/ProductTypes',
         text: 'Product Types',
         icon: <FontAwesomeIcon icon="project-diagram" size="2x" />,
         component: 'ProductTypes',
      },
      {
         route: '/Products',
         text: 'Products',
         icon: <FontAwesomeIcon icon="cube" size="2x" />,
         component: 'Products',
      },
      {
         route: '/TestEquipment',
         text: 'Test Equipment',
         icon: <FontAwesomeIcon icon="vial" size="2x" />,
         component: 'TestEquipmentPage',
      },
      {
         route: '/AddRecord',
         text: 'Add New Record',
         icon: <FontAwesomeIcon icon="cloud-upload-alt" size="2x" />,
         component: 'AddNewTesRecord',
      },
      {
         route: '/RecordTypes',
         text: 'Record Types',
         icon: <FontAwesomeIcon icon="file-alt" size="2x" />,
         component: 'RecordTypes',
      },
      {
         route: '/Records',
         text: 'Records',
         icon: <FontAwesomeIcon icon="clipboard-list" size="2x" />,
         component: 'Records',
      },
      {
         route: '/SurveyRepairTypes',
         text: 'Survey/Repair Types',
         icon: <FontAwesomeIcon icon="tools" size="2x" />,
         component: 'SurveyRepairTypes'
      }
   ];

   return navItems;
}
