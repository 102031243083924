import { APIQuery } from '../../Types';
import config from '../../../../../config';

export const deleteOwnerLocations = (ownerId: number, deletedLocationIds: number[], query: APIQuery): Promise<void> => {
  if (deletedLocationIds.length === 0)
    return Promise.resolve();

  const baseAddress = `${config.apiGateway.ITR_API}/api/DeviceOwners/${ownerId}/locations`;
  const promises = deletedLocationIds.map(id => {
    const address = `${baseAddress}/${id}`;
    return query(address, 'DELETE');
  })

  return Promise.all(promises).then();
};