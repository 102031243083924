import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import ProductStatusIcon from './ProductStatusIcon';
import config from '../../config';

interface TestResultIconProps {
    invert?: boolean;
    size?: SizeProp;
    value: string;
}


const TestResultIcon = (props: TestResultIconProps) => {

    const result = props.value.toLowerCase();
    

    return result === config.naTestResultText ? (
        <span className='fa-stack'>
            <FontAwesomeIcon className='fa-stack-1x' transform={{rotate:135}} icon={'circle'} color={props.invert ? 'white' : 'grey'} size={props.size} />
            <FontAwesomeIcon className='fa-stack-1x' icon={'slash'} color={props.invert ? 'grey' : 'white'} size={props.size} />
        </span>
    ) : (
        <ProductStatusIcon {...props} />
    )
};

export default TestResultIcon;
