import config from "../../../../config";
import { Owner, OwnerUtils } from '../../../Model/Owner';
import { APIOwner } from '../../../Model/API/APIOwner';

export async function getOwner(id: number, get: (address: string) => Promise<any>): Promise<Owner | null> {
  const ownerUrl = `${config.apiGateway.ITR_API}/api/deviceOwners/${id}`;
  const response: APIOwner | null | string = await get(ownerUrl);
  if (!response || typeof response != "object")
    return null;

  return OwnerUtils.fromResponse(response);
}
