import BarcodeScannerComponent from "react-qr-barcode-scanner";
import * as React from "react";
import { useCallback } from "react";
import { Result } from "@zxing/library";
import { Button, Modal } from "react-bootstrap";

interface BarcodeScannerModalProps {
  onScanned: (data: string) => void;
  onClose: () => void;
  show: boolean;
  title: string;
}

export function BarcodeScannerModal(props: BarcodeScannerModalProps) {
  const { onScanned, onClose } = props;

  const handleBarcodeScanned = useCallback((_err: unknown, result?: Result) => {
      if (!result)
        return;

      const data = result.getText();
      onScanned(data);
    }
    , [onScanned]);

  const { show, title } = props;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <BarcodeScannerComponent
          onUpdate={handleBarcodeScanned}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}