import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { JwtManager } from "./components/Shared/JwtManager";
import Layout from "./components/Layout";
import { Home } from "./components/Home/Home";
import { SplashScreen } from "./components/SplashScreen/SplashScreen";
import { LoginModal } from "./components/Login/LoginModal";
import Supplier from "./components/Suppliers/Supplier";
import SupplierList from "./components/Suppliers/SupplierList";
import ProductTypes from "./components/ProductTypes/ProductTypes";
import ProductType from "./components/ProductTypes/ProductType";
import Products from "./components/Products/Products";
import DataEntryForm from "./components/DataEntry/DataEntryForm";
import AddNewTestRecord from "./components/DataEntry/AddNewTestRecord";
import RecordTypes from "./components/RecordTypes/RecordTypes";
import RecordType from "./components/RecordTypes/RecordType";
import Records from "./components/RecordView/Records";
import Record from "./components/RecordView/Record";
import SurveyRepairTypes from "./components/SurveyRepairs/SurveyRepairTypes"
import FaultRepairType from "./components/SurveyRepairs/SurveyRepairType";
import OwnerList from "./components/Owners/OwnerList";
import { ViewOwner } from "./components/Owners/ViewOwner";
import CalibratorList from './components/Calibrators/CalibratorList';
import { TestEquipmentPage } from './components/TestEquipment/TestEquipmentPage';
import ViewTestEquipment from './components/TestEquipment/ViewTestEquipment';

export interface RouteProps {
   children?: React.ReactNode;
   location?: any;
}

export interface RouteState {
   redirect: boolean;
   userIsAuthenticated: boolean;
   mainRoute?: string;
}

export class Routes extends React.Component<RouteProps, RouteState> {
   tokenManager = new JwtManager({});
   token: string | null;

   constructor(props: RouteProps) {
      super(props);

      let route = window.location.pathname;
      route = route.toLowerCase();

      let mainRoute = route.split("/")[1];

      this.token = this.tokenManager.getToken();

      let redirect = false;
      let userIsAuthenticated: boolean;

      if (!this.tokenManager.checkTokenIsValid()) {
         redirect = true;
         userIsAuthenticated = false;
      } else {
         userIsAuthenticated = true;
      }
      this.state = {
         userIsAuthenticated: userIsAuthenticated,
         redirect: redirect,
         mainRoute: mainRoute,
      };
   }

   componentWillReceiveProps() {
      this.token = this.tokenManager.getToken();
      this.setState({
         userIsAuthenticated: false,
         redirect: !this.token || this.token.length === 0,
      });
   }
   redirectToLogin() {
      window.location.reload();
   }

   checkTokenIsValid = () => {
      if (!this.tokenManager.checkTokenIsValid()) {
         this.redirectToLogin();
      }
   };

   setUserIsAuthenticated = (userIsAuthenticated: boolean) => {
      this.setState({ userIsAuthenticated: userIsAuthenticated });
   };

   PrivateRoute = ({ component: Component, ...rest }: any) => {
      return (
         <Route
            {...rest}
            render={(props) =>
               this.state.userIsAuthenticated ? (
                  <Component {...props} checkTokenIsValid={this.checkTokenIsValid.bind(this)} />
               ) : (
                  <SplashScreen>
                     <LoginModal showLoginModal={true} projectTitle="Ea Technology" />
                  </SplashScreen>
               )
            }
         />
      );
   };

   public render() {
      return (
         <BrowserRouter>
            <Layout userIsAuthenticated={this.state.userIsAuthenticated}>
               <this.PrivateRoute exact path="/" component={Home as any} />
               <this.PrivateRoute exact path="/Home/" component={Home as any} />
               <this.PrivateRoute exact path="/Suppliers/" component={SupplierList as any} />
               <this.PrivateRoute exact path="/Suppliers/:id" component={Supplier as any} />
               <this.PrivateRoute exact path="/Owners/" component={OwnerList as any} />
               <this.PrivateRoute exact path="/Owners/:id" component={ViewOwner as any} />
               <this.PrivateRoute exact path="/Calibrators" component={CalibratorList as any} />
               <this.PrivateRoute exact path="/ProductTypes/" component={ProductTypes as any} />
               <this.PrivateRoute exact path="/ProductTypes/:id" component={ProductType as any} />
               <this.PrivateRoute exact path="/TestEquipment" component={TestEquipmentPage as any} />
               <this.PrivateRoute exact path="/TestEquipment/:id" component={ViewTestEquipment as any} />
               <this.PrivateRoute exact path="/Products/" component={Products as any} />
               <this.PrivateRoute exact path="/DataEntry/" component={DataEntryForm as any} />
               <this.PrivateRoute exact path="/AddRecord/" component={AddNewTestRecord as any} />
               <this.PrivateRoute exact path="/RecordTypes/" component={RecordTypes as any} />
               <this.PrivateRoute exact path="/RecordTypes/:id" component={RecordType as any} />
               <this.PrivateRoute exact path="/Records/" component={Records as any} />
               <this.PrivateRoute exact path="/Records/:id" component={Record as any} />
               {/* FaultRepairTypes routes are kept for backwards compatibility - they can be removed when all rerouting code has been updated */}
               <this.PrivateRoute exact path="/FaultRepairTypes" component={SurveyRepairTypes as any} />
               <this.PrivateRoute exact path="/FaultRepairTypes/:id" component={FaultRepairType as any} />
               <this.PrivateRoute exact path="/SurveyRepairTypes" component={SurveyRepairTypes as any} />
               <this.PrivateRoute exact path="/SurveyRepairTypes/:id" component={FaultRepairType as any} />
            </Layout>
         </BrowserRouter>
      );
   }
}

export default Routes;
