import { TransitionGroup } from "react-transition-group";
import { Collapse } from "@material-ui/core";
import { Alert } from "react-bootstrap";
import DynamicUpload, { UploadedFile } from "../Shared/FileUploader/DynamicUpload";
import * as React from "react";
import { useCallback } from "react";
import { parseUploadedDocument } from '../Shared/Utils/ParseUploadedDocument';

export interface UploadFormControlProps {
  value: File | string | null;
  label: string;
  onDocumentReset: () => void;
  onDocumentUpload: (files: UploadedFile[]) => void;
}

export function UploadFormControl(props: UploadFormControlProps) {
  const { value, label, onDocumentReset, onDocumentUpload } = props;
  const fileSelected = value != null;

  const filenameLabel = typeof value === 'string'
    ? `Reuse existing file (${parseUploadedDocument(value).originalFilename})`
    : `Selected ${value?.name}`;

  const renderUploadControl = useCallback(() =>
    fileSelected ? (
      <Collapse key='info'>
        <div>
          <Alert variant='info'>
            {filenameLabel}.&nbsp;
            <Alert.Link onClick={onDocumentReset}>
              Change file
            </Alert.Link>
          </Alert>
        </div>
      </Collapse>
    ) : (
      <Collapse key='upload'>
        <div>
          <DynamicUpload
            multiple={false}
            label={`Upload ${label}`}
            accept='.pdf'
            callBack={onDocumentUpload}
          />
        </div>
      </Collapse>
    ), [fileSelected, value, label]);

  return (
    <TransitionGroup>
      {renderUploadControl()}
    </TransitionGroup>
  )
}