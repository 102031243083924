import { useEffect, useState } from "react";
import config from "../../../config";
import { BlobApiResponseHandler } from "../BlobApiResponseHandler";

export function useFormDocumentContent(savedFilename: string) {
  const [content, setContent] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLoading(true)
    fetchFormDocumentContent(savedFilename)
      .then(content => {
        setContent(content);
        setLoading(false);
      })
      .catch(e => {
        setError(e?.message ?? "Could not load document at this time");
        setLoading(false);
      });
  }, [savedFilename]);

  return {
    loading,
    error,
    content
  };
}

export function fetchFormDocumentContent(savedFilename: string, apiHandler?: BlobApiResponseHandler) {
  apiHandler = apiHandler ?? new BlobApiResponseHandler();

  const encodedFilename = encodeURI(savedFilename);
  const url = `${config.apiGateway.ITR_API}/api/formDocuments?filename=${encodedFilename}`;
  return apiHandler.get(url);
}
