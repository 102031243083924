import * as React from 'react';
import { ReactElement } from 'react';

import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { process, State as DataState } from '@progress/kendo-data-query';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import TestEquipment from '../Model/TestEquipment';
import TestEquipmentAPI from '../Shared/Data/TestEquipment/TestEquipmentAPI';
import { createGridSortFilterColumn } from '../Shared/Grids/CreateGridSortFilterColumn';
import { BLANK_BANNER_MESSAGE, createErrorInfoMessage, InfoBanner, InfoMessage } from '../Shared/Infobanner';
import { PageTitle } from '../Shared/PageTitle';
import BusyOverlay from '../Shared/BusyOverlay';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { getErrorMessageOrDefault } from '../Shared/Utils/GetErrorMessageOrDefault';
import { DEFAULT_DATA_STATE } from '../Shared/KendoConstants';
import { CalibratedCell } from './CalibratedCell';
import { ExpiryCell } from './ExpiryCell';
import { createActionCell } from '../Shared/Grids/ActionCell';
import { TestEquipmentSortUtils } from './TestEquipmentSortUtils';

type TestEquipmentPageProps = RouteComponentProps<{ product: string }>

interface TestEquipmentPageState {
  testEquipment: TestEquipment[];
  bannerMessage: InfoMessage;
  dataState: DataState;
}

export class TestEquipmentPage extends ApiResponseHandler<TestEquipmentPageProps, TestEquipmentPageState> {
  private readonly _testEquipmentAPI: TestEquipmentAPI;
  private readonly _actionCell: (props: GridCellProps) => ReactElement;

  constructor(props: TestEquipmentPageProps) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
      innerState: {
        testEquipment: [],
        bannerMessage: BLANK_BANNER_MESSAGE,
        dataState: DEFAULT_DATA_STATE,
      }
    };

    this._testEquipmentAPI = new TestEquipmentAPI(this.query.bind(this));

    this._actionCell = createActionCell({ onEdit: this.handleEdit,
                                          onFilter: this.handleFilter.bind(this) });
  }

  public componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const productId = Number(query.product);
    this.fetchTestEquipment(productId)
      .then(testEquipment => this.setState({
        loading: false,
        innerState: {
          testEquipment,
          bannerMessage: BLANK_BANNER_MESSAGE,
          dataState: DEFAULT_DATA_STATE,
        }
      }))
      .catch(this.handleLoadingError);
  }

  private fetchTestEquipment = (productId: number): Promise<TestEquipment[]> => {
    if (!isNaN(productId))
      return this._testEquipmentAPI.getByProduct(productId);

    return this._testEquipmentAPI.getAll();
  };

  private handleLoadingError = (error: unknown) => {
    const message = getErrorMessageOrDefault(error, 'Unable to load test equipment for product at this time');
    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        bannerMessage: createErrorInfoMessage(message)
      }
    }));
  };

  private handleDataStateChange = (e: GridDataStateChangeEvent) =>
    this.setState(prevState => ({
      innerState: {
        ...prevState.innerState,
        dataState:e.data
      }
    }));

  private handleEdit = (item: TestEquipment) =>
    this.props.history.push(`/TestEquipment/${item.id}`, { testEquipment: item });

  private handleFilter = (item: TestEquipment) =>
    this.props.history.push(`/Records?filter=testEquipment&value=${item.id}`);
    

  private sortAndFilterTestEquipment = () => {
    const { testEquipment, dataState } = this.state.innerState;
    let processed = process(testEquipment, dataState);

    const sortByCalibration = dataState.sort?.find(s => s.field === 'calibration');
    if (sortByCalibration) {
      processed.data = TestEquipmentSortUtils.sortByCalibration(processed.data, sortByCalibration.dir);
    }

    return processed;
  };

  public render() {
    const {
      loading,
      innerState: { bannerMessage, dataState, testEquipment }
    } = this.state;

    const filterColumnMenu = createGridSortFilterColumn(testEquipment, {fuzzy: true});
    const processedData = this.sortAndFilterTestEquipment();

    return (
      <React.Fragment>
        <InfoBanner message={bannerMessage} />
        <PageTitle title="Test Equipment" />
        <BusyOverlay show={loading} />
        <Grid
          className="mt-5"
          pageable
          sortable
          data={processedData}
          {...dataState}
          onDataStateChange={this.handleDataStateChange}
        >
          <Column
            field="serialNumber.full"
            title="Serial Number"
            columnMenu={filterColumnMenu}
          />
          <Column
            field="description"
            title="Description"
            columnMenu={filterColumnMenu}
          />
          <Column
            field="assetTag"
            title="Physical Serial Number"
            columnMenu={filterColumnMenu}
          />
          <Column
            field="location.name"
            title="Location"
            columnMenu={filterColumnMenu}
          />
          <Column
            field="calibration"
            title="Calibrated"
            cell={CalibratedCell}
          />
          <Column
            field="calibration"
            title="Expires"
            cell={ExpiryCell}
          />
          <Column
            title="Actions"
            cell={this._actionCell}
          />
        </Grid>
      </React.Fragment>
    );
  }
}