import React, { Component } from 'react';
import './DynamicUpload.css';
import Progress from './Progress';
import Dropzone from './Dropzone';
import { OverlayTrigger, Tooltip, Container } from 'react-bootstrap';

var imageTypes = ['.jpeg', '.png', '.svg', '.jpg', '.jfif'];

const intialState = {
   files: [],
   uploading: false,
   uploadProgress: {},
   successfullUploaded: false,
   fileTypeSupported: true,
   images: [], //for image upload only
};

interface DynamicUploadProps {
   label: string;
   name: string;
   fileUploadtext: string;
   disabled: boolean;
   multiple: boolean;
   showActions: boolean;
   url: string;
   accept: string;
   reset: boolean;
   callBack?: (files: UploadedFile[]) => void;
   deleteCallBack?: any;
}

interface DynamicUploadState {
   files: any;
   uploading: boolean;
   uploadProgress: any;
   successfullUploaded: boolean;
   fileTypeSupported: boolean;
   images: any;
}

export interface UploadedFile extends File {
   valid?: boolean;
   base64?: string;
}

class DynamicUpload extends Component<DynamicUploadProps, DynamicUploadState> {
   static defaultProps: DynamicUploadProps;
   constructor(props: DynamicUploadProps) {
      super(props);
      this.state = intialState;
      this.onFilesAdded = this.onFilesAdded.bind(this);
      this.uploadFiles = this.uploadFiles.bind(this);
      this.sendRequest = this.sendRequest.bind(this);
      this.renderActions = this.renderActions.bind(this);
      this.deleteFile = this.deleteFile.bind(this);
   }

   componentWillReceiveProps(NextProps: DynamicUploadProps) {
      if (NextProps.reset) {
         this.setState(intialState);
      }
   }

   onFilesAdded(file: UploadedFile[]) {
      var acceptedTypes = this.props.accept.split(',');
      file.map((key: any, index: number) => {
         var fileType = '.' + file[index]['name'].split('.').pop();
         var foundType = acceptedTypes.indexOf(fileType.toLowerCase());
         if (foundType < 0) {
            file[index]['valid'] = false;
         } else {
            file[index]['valid'] = true;
            this.toBase64(file[index], (result: any) => {
               file[index]['base64'] = result;
               if (imageTypes.includes(fileType.toLowerCase())) {
                  let imageObject = {
                     src: result,
                     caption: file[index]['name'],
                     thumbnail: result,
                     thumbnailWidth: 320,
                     thumbnailHeight: 174,
                     id: index,
                     name: file[index]['name'],
                     tags: [{ title: 'Name', value: file[index]['name'] }],
                  };
                  this.setState((prevState) => ({
                     images: prevState.images.concat(imageObject),
                  }));
               }
               if (this.props.multiple !== undefined && this.props.multiple) {
                  this.setState(
                     (prevState) => ({
                        files: prevState.files.concat(file[index]),
                     }),
                     () => {
                        if (this.props.callBack) this.props.callBack(this.state.files);
                     }
                  );
               } else {
                  this.state.files.length = 0;
                  this.setState(
                     (prevState) => ({
                        files: [file[0]],
                     }),
                     () => {
                        if (this.props.callBack) this.props.callBack([file[0]]);
                     }
                  );
               }
            });
         }
      });
   }

   renderProgress(file: any) {
      const uploadProgress = this.state.uploadProgress[file.name];
      if (this.state.uploading || this.state.successfullUploaded) {
         return (
            <div className="ProgressWrapper">
               <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
               <img
                  className="CheckIcon"
                  alt="done"
                  src="baseline-check_circle_outline-24px.svg"
                  style={{
                     opacity: uploadProgress && uploadProgress.state === 'done' ? 0.5 : 0,
                  }}
               />
            </div>
         );
      }
   }

   renderActions() {
      if (this.state.successfullUploaded) {
         return <button onClick={() => this.setState({ files: [], successfullUploaded: false })}>Clear</button>;
      } else {
         return (
            <button disabled={this.state.files.length < 0 || this.state.uploading || this.props.disabled} onClick={this.uploadFiles}>
               Upload
            </button>
         );
      }
   }
   //for auto upload
   sendRequest(file: any) {
      return new Promise((resolve, reject) => {
         const req = new XMLHttpRequest();
         const url = this.props.url;
         req.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
               const copy = { ...this.state.uploadProgress };
               copy[file.name] = {
                  state: 'pending',
                  percentage: (event.loaded / event.total) * 100,
               };
               this.setState({ uploadProgress: copy });
            }
         });

         req.upload.addEventListener('load', (event) => {
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = { state: 'done', percentage: 100 };
            this.setState({ uploadProgress: copy });
            resolve(req.response);
         });

         req.upload.addEventListener('error', (event) => {
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = { state: 'error', percentage: 0 };
            this.setState({ uploadProgress: copy });
            reject(req.response);
         });

         const formData = new FormData();
         formData.append('file', file, file.name);

         req.open('POST', url);
         req.send(formData);
      });
   }
   deleteFile(file: any) {
      let imageObject = {};
      let images: any = this.state.images;
      var acceptedTypes: string[] = this.props.accept.split(',');
      var updatedFiles = this.state.files.filter((item: any) => {
         return item.name !== file.name;
      });

      if (imageTypes.some((r) => acceptedTypes.indexOf(r) >= 0)) {
         images = [];
         updatedFiles.map((key: any, index: number) => {
            imageObject = {
               src: updatedFiles[index]['base64'],
               caption: updatedFiles[index]['name'],
               thumbnail: updatedFiles[index]['base64'],
               thumbnailWidth: 320,
               thumbnailHeight: 174,
               id: index,
               name: updatedFiles[index]['name'],
               tags: [{ title: 'Name', value: updatedFiles[index]['name'] }],
            };
            images.push(imageObject);
         });
      }

      this.setState(
         {
            files: updatedFiles,
            images: images,
         },
         () => {
            if (this.props.deleteCallBack) {
               this.props.deleteCallBack(updatedFiles);
            }
         }
      );
   }
   async uploadFiles() {
      this.setState({ uploadProgress: {}, uploading: true });
      const promises: any = [];
      this.state.files.forEach((file: any) => {
         promises.push(this.sendRequest(file));
      });
      try {
         await Promise.all(promises);

         this.setState({ successfullUploaded: true, uploading: false });
      } catch (e) {
         this.setState({ successfullUploaded: false, uploading: false });
      }
   }

   bytesToSize(bytes: number) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i: number = Math.floor(Math.log(bytes) / Math.log(1024));
      return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
   }
   toBase64(file: any, callBack: any) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
         callBack(reader.result);
      };
      reader.onerror = function (error) {
         console.log('Error: ', error);
      };
   }

   render() {
      return (
         <React.Fragment>
            <Container fluid>
               <OverlayTrigger
                  placement="auto"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="upload-tip">Click or drag to upload {this.props.accept} file</Tooltip>}>
                  <div>
                     <Dropzone
                        onFilesAdded={this.onFilesAdded}
                        disabled={this.state.uploading || this.state.successfullUploaded || this.props.disabled}
                        accept={this.props.accept}
                        name={this.props.name}
                     />
                  </div>
               </OverlayTrigger>
            </Container>
         </React.Fragment>
      );
   }
}
DynamicUpload.defaultProps = {
   label: 'Upload File',
   fileUploadtext: 'Click here to upload files',
   disabled: false,
   multiple: false,
   showActions: false,
   url: '',
   accept: '.docx, .png, .svg, .xls, .zip, .pdf, jpg, .jpeg',
   name: 'fileUpload',
   reset: false,
};

export default DynamicUpload;
