import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { CalibrationDetails } from '../Model/TestEquipment';

export const ExpiryCell: React.FC<GridCellProps> = props => {
  if (!props.field)
    return <td />;

  const calibration: CalibrationDetails | null = props.dataItem[props.field];
  if (!calibration)
    return <td />;

  return (
    <td className="k-command-cell text-center">
      {
        calibration.skipped
          ? 'Calibration not required'
          : `Expires ${calibration.expires.toLocaleDateString()}`
      }
    </td>
  );
};