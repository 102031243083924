import React, { useEffect, useState } from "react";
import IRecordType from '../../Model/IRecordType';
import { Dialog, DialogTitle, DialogActions, DialogContent, Divider } from "@material-ui/core";
import { ListSubheader, List, ListItem, ListItemText } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CustomMultiSelect from "../MultiSelect";
import NestedListDrawer from "./NestedListDrawer";
import { Form } from "react-bootstrap";
import config from "../../../config";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import ExcelDateListItem from "./ExcelDateListItem";
import ExcelStatusSelect, { ResultValue } from "./ExcelStatusSelect";
import separateSerialFromPrefix from "../Utils/SeparateSerialAndPrefix";


interface Filter {
    field: string;
    value: string;
}

export interface ExcelExportDialogProps {
    onExport: (filters: Filter[]) => void;
    recordTypes: IRecordType[];
    filteredRecordTypes: IRecordType[];
    filters: CompositeFilterDescriptor[];
    open: boolean;
    handleClose: () => void;
    APIGet: (address: string, body?: string) => Promise<any>;
};



const ExcelExportDialog = (props: ExcelExportDialogProps) => {

    const [serialNumber, setSerialNumber] = useState<string>('');
    const [recordTypes, setRecordTypes] = useState<IRecordType[]| []>([]);
    const [productTypeNames, setProductTypeNames] = useState<string[]>([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([]);
    const [startDateEnabled, setStartDateEnabled] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>();
    const [endDateEnabled, setEndDateEnabled] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<Date>();
    const [testRecEnabled, setTestRecEnabled] = useState<boolean>(false);
    const [despatchEnabled, setDespatchEnabled] = useState<boolean>(false);
    const [problemEnabled, setProblemEnabled] = useState<boolean>(false);
    const [calEnabled, setCalEnabled] = useState<boolean>(false);
    const [resultFilters, setResultFilters] = useState<ResultValue[]>([]);
    const [supplierNames, setSupplierNames] = useState<string[]>([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
    const [problemItems, setProblemItems] = useState<string[]>([]);
    const [selectedFaultItems, setSelectedFaultItems] = useState<string[]>([]);
    const [selectedRepairItems, setSelectedRepairItems] = useState<string[]>([]);
    const [faultDescriptives, setFaultDescriptives] = useState<string[]>([]);
    const [selectedFaultDescriptives, setSelectedFaultDescriptives] = useState<string[]>([]);
    const [repairDescriptives, setRepairDescriptives] = useState<string[]>([]);
    const [selectedRepairDescriptives, setSelectedRepairDescriptives] = useState<string[]>([]);
    const [customers, setCustomers] = useState<string[]>([]);
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
    const [jobIdentifier, setJobIdentifer] = useState<string>('');
    const [batchNumber, setBatchNumber] = useState<string>('');

    useEffect(() => {
        updateFilters();
    }, [props.filters, productTypeNames, supplierNames, problemItems, faultDescriptives, repairDescriptives, customers]);


    const onClose = () => {
        setSerialNumber('');
        setRecordTypes([]);
        setSelectedProductTypes([]);
        setStartDateEnabled(false);
        setEndDateEnabled(false);
        setTestRecEnabled(false);
        setDespatchEnabled(false);
        setProblemEnabled(false);
        setCalEnabled(false);
        setResultFilters([]);
        setSelectedSuppliers([]);
        setSelectedFaultItems([]);
        setSelectedFaultDescriptives([]);
        setSelectedRepairDescriptives([]);
        setSelectedCustomers([]);
        setJobIdentifer('');
        setBatchNumber('');
        updateFilters();
        props.handleClose();
    }


    const onExport = () => {
        let filters = [];
        if (serialNumber)
            filters.push({field: 'productSerialNumber', value: separateSerialFromPrefix(serialNumber)});
        if (recordTypes.length > 0) {
            for (const recordType of recordTypes)
                filters.push({field: 'recordTypeName', value: recordType.name});
        }
        if (selectedProductTypes.length > 0){
            for (const prodType of selectedProductTypes)
                filters.push({field: 'productTypeName', value: prodType});
        }
        if (startDateEnabled)
            filters.push({field: 'recordDateStart', value: JSON.stringify(startDate)});
        if (endDateEnabled)
            filters.push({field: 'recordDateEnd', value: JSON.stringify(startDate)});
        if (testRecEnabled) {
            filters.push({field: 'recordCategory', value: 'test'});
            if (resultFilters.length > 0) {
                for (const result of resultFilters)
                    filters.push({field: 'testResult', value: result});
            }
            if (supplierNames.length > 0) {
                for (const supplier of supplierNames)
                    filters.push({field: 'supplierName', value: supplier});
            }
        }
        if (despatchEnabled) {
            filters.push({field: 'recordCategory', value: 'despatch'});
            if (selectedCustomers.length > 0) {
                for (const customer of selectedCustomers)
                    filters.push({field: 'customerName', value: customer});
            }
            if (jobIdentifier)
                filters.push({field: 'jobIdentifier', value: jobIdentifier});
            if (batchNumber)
                filters.push({field: 'batchNumber', value: batchNumber});
        }
        if (problemEnabled) {
            filters.push({field: 'recordCategory', value: 'problemReport'});
            if (selectedFaultItems.length > 0) {
                for (const item of selectedFaultItems)
                    filters.push({field: 'faultItems', value: item});
            }
            if (selectedRepairItems.length > 0) {
                for (const item of selectedRepairItems)
                    filters.push({field: 'repairItems', value: item});
            }
            if (selectedFaultDescriptives.length > 0) {
                for (const descriptive of selectedFaultDescriptives)
                    filters.push({field: 'faultDescriptives', value: descriptive});
            }
            if (selectedRepairDescriptives.length > 0) {
                for (const descriptive of selectedRepairDescriptives)
                    filters.push({field: 'repairDescriptives', value: descriptive});
            }
        }
        if (calEnabled)
            filters.push({field: 'recordCategory', value: 'calibration'});
        
        props.onExport(filters);
    }


    const updateFilters = () => {
        let enableStartDate = false;
        let enableEndDate = false;
        for (const filterGroup of props.filters) {
            let filterIndex = 0;
            for (const filter of filterGroup.filters) {
                if ('field' in filter) {
                    switch (filter.field) {
                        case 'productSerialNumber':
                            setSerialNumber(filter.value);
                            break;
                        case 'productTypeName':
                            setSelectedProductTypes(productTypeNames.filter((name) => (name.includes(filter.value))));
                            break;
                        case 'recordDate':
                            if (filterIndex === 0) {
                                enableStartDate = true;
                                setStartDate(filter.value);
                            } else {
                                enableEndDate = true;
                                setEndDate(filter.value);
                            }
                            break;
                        case 'testResult':
                            setResultFilters([filter.value]);
                            break;
                        case 'customerName':
                            setSelectedCustomers(customers.filter((name) => name.includes(filter.value)));
                            break;
                        case 'jobNumber':
                            setJobIdentifer(filter.value);
                            break;
                        case 'faultItems':
                            setSelectedFaultItems(problemItems.filter((name) => (name.includes(filter.value))))
                            break;
                        case 'repairItems':
                            setSelectedRepairItems(problemItems.filter((name) => (name.includes(filter.value))));
                            break;
                        case 'faultDescriptives':
                            setSelectedFaultDescriptives(faultDescriptives.filter((name) => (name.includes(filter.value))));
                            break;
                        case 'repairDescriptives':
                            setSelectedRepairDescriptives(repairDescriptives.filter((name) => (name.includes(filter.value))));
                            break;
                    }
                }
                filterIndex++;
            }
        }
        setStartDateEnabled(enableStartDate);
        setEndDateEnabled(enableEndDate);
    }

    const getProductTypes = () => {
        const prodTypeUrl = config.apiGateway.META_API + '/api/AssetModels/';
        props.APIGet(prodTypeUrl).then((response) => {
            const prodTypes = response.map((prodType: any) => prodType.name);
            setProductTypeNames(prodTypes);
        });
    }

    const getSuppliers = () => {
        const supplierUrl = config.apiGateway.META_API + '/api/AssetManufacturers/'
        props.APIGet(supplierUrl).then((response) => {
            const suppliers = response.map((supplier: any) => supplier.name);
            setSupplierNames(suppliers);
            setSelectedSuppliers(suppliers);
        });
    }

    const getCustomers = () => {
        const customerUrl = config.apiGateway.META_API + '/api/Customers'
        props.APIGet(customerUrl).then((response) => {
            const cust = response.map((customer: any) => customer.name);
            setCustomers(cust);
            setSelectedCustomers(cust);
        });
    }

    const getFaultRepairTypes = () => {
        const faultRepairUrl = config.apiGateway.ITR_API + '/api/faultRepairTypes';
        props.APIGet(faultRepairUrl).then((response) => {
            let items = [];
            let faultDesc = [];
            let repairDesc = [];
            for (const faultRepair of response) {
                if (faultRepair.faultRepairType === 'Item')
                    items.push(faultRepair.name);
                else if (faultRepair.faultRepairType === 'Descriptive' && faultRepair.descriptiveType === 'Fault')
                    faultDesc.push(faultRepair.name);
                else if (faultRepair.faultRepairType === 'Descriptive' && faultRepair.descriptiveType === 'Repair')
                    repairDesc.push(faultRepair.name);
                    
            }
            setProblemItems(items);
            setSelectedFaultItems([...items]);
            setSelectedRepairItems([...items]);
            setFaultDescriptives(faultDesc);
            setSelectedFaultDescriptives(faultDesc);
            setRepairDescriptives(repairDesc);
            setSelectedRepairDescriptives(repairDesc);
        });
    }

    return (
    <div>
        <Dialog open={props.open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Export Data to Excel</DialogTitle>
            <DialogContent>
                <List subheader={
                    <ListSubheader>
                        Export Filters
                    </ListSubheader>
                }>
                    <NestedListDrawer text="Serial Number">
                        <ListItem style={{paddingLeft: '4em'}}>
                            <Form.Control
                                name="serialField"
                                type="text"
                                placeholder="Enter the serial number to filter"
                                value={serialNumber}
                                onChange={(e) => setSerialNumber(e.target.value)}
                            />
                        </ListItem>
                    </NestedListDrawer>
                    <NestedListDrawer text="Record Type">
                        <ListItem style={{paddingLeft: '2em'}}>
                            <CustomMultiSelect data={props.recordTypes} value={recordTypes} maxDisplayedValues={5} textField={'name'} filterable autoClose={false} onChange={(e:any)=>{setRecordTypes(e.value)}} />
                        </ListItem>
                    </NestedListDrawer>
                    <NestedListDrawer onOpen={getProductTypes} text="Product Type">
                        <ListItem style={{paddingLeft: '2em'}}>
                            <CustomMultiSelect data={productTypeNames} value={selectedProductTypes} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedProductTypes(e.value)}} />
                        </ListItem>
                    </NestedListDrawer>
                    <NestedListDrawer text="Date">
                        <ExcelDateListItem style={{paddingLeft: '2em'}} label="Start Date" dateValue={startDate} 
                        dateChanged={(e) => {
                            if (e.target.value)
                                setStartDate(e.target.value);
                        }} 
                        enabled={startDateEnabled}
                        onToggle={()=>setStartDateEnabled(!startDateEnabled)}
                        />
                        <Divider style={{paddingLeft: '2em'}} variant="middle"/>
                        <ExcelDateListItem style={{paddingLeft: '2em'}} label="End Date" dateValue={endDate} 
                        dateChanged={(e) => {
                            if (e.target.value)
                                setEndDate(e.target.value);
                        }} 
                        enabled={endDateEnabled}
                        onToggle={()=>setEndDateEnabled(!endDateEnabled)}
                        />
                    </NestedListDrawer>
                    <NestedListDrawer text="Category">
                        <NestedListDrawer toggle style={{paddingLeft:'2em'}} text="Test Record" disabled={!testRecEnabled}
                        onToggle={() => setTestRecEnabled(!testRecEnabled)}
                        >
                            <ListItem style={{paddingLeft: '4em'}}>
                                <ExcelStatusSelect value={resultFilters} onChange={(value) => setResultFilters(value)} />
                                <ListItemText style={{marginLeft: '1em'}} primary='Test Result' />
                            </ListItem>
                            <Divider style={{paddingLeft: '4em'}} variant='middle' />
                            <NestedListDrawer style={{paddingLeft:'4em'}} text="Supplier Name" onOpen={getSuppliers}>
                                <ListItem style={{paddingLeft: '6em'}}>
                                    <CustomMultiSelect data={supplierNames} value={selectedSuppliers} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedSuppliers(e.value)}} />
                                </ListItem>
                            </NestedListDrawer>
                        </NestedListDrawer>
                        <NestedListDrawer toggle style={{paddingLeft:'2em'}} text="Despatch Record" disabled={!despatchEnabled}
                        onToggle={() => setDespatchEnabled(!despatchEnabled)}
                        >
                            <NestedListDrawer style={{paddingLeft:'4em'}} text="Customer" onOpen={getCustomers}>
                                <ListItem style={{paddingLeft: '6em'}}>
                                    <CustomMultiSelect data={customers} value={selectedCustomers} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedCustomers(e.value)}} />
                                </ListItem>
                            </NestedListDrawer>
                            <Divider style={{paddingLeft:'4em'}} variant='middle' />
                            <ListItem style={{paddingLeft: '4em'}}>
                            <Form.Control
                                name="jobIdentifier"
                                type="text"
                                placeholder="Enter the packing form Job Identifier"
                                value={jobIdentifier}
                                onChange={(e) => setJobIdentifer(e.target.value)}
                            />
                        </ListItem>
                        <Divider style={{paddingLeft:'4em'}} variant='middle' />
                        <ListItem style={{paddingLeft: '4em'}}>
                            <Form.Control
                                name="serialField"
                                type="text"
                                placeholder="Enter the packing form Batch Number"
                                value={batchNumber}
                                onChange={(e) => setBatchNumber(e.target.value)}
                            />
                        </ListItem>
                        </NestedListDrawer>
                        <NestedListDrawer toggle style={{paddingLeft:'2em'}} text="Problem Report Record" disabled={!problemEnabled}
                        onToggle={() => setProblemEnabled(!problemEnabled)}
                        onOpen={getFaultRepairTypes}
                        >
                            <NestedListDrawer style={{paddingLeft:'4em'}} text="Fault Item">
                                <ListItem style={{paddingLeft: '6em'}}>
                                    <CustomMultiSelect data={problemItems} value={selectedFaultItems} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedFaultItems(e.value)}} />
                                </ListItem>
                            </NestedListDrawer>
                            <NestedListDrawer style={{paddingLeft:'4em'}} text="Repair Item">
                                <ListItem style={{paddingLeft: '6em'}}>
                                    <CustomMultiSelect data={problemItems} value={selectedRepairItems} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedRepairItems(e.value)}} />
                                </ListItem>
                            </NestedListDrawer>
                            <NestedListDrawer style={{paddingLeft:'4em'}} text="Fault Descriptive">
                                <ListItem style={{paddingLeft: '6em'}}>
                                    <CustomMultiSelect data={faultDescriptives} value={selectedFaultDescriptives} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedFaultDescriptives(e.value)}} />
                                </ListItem>
                            </NestedListDrawer>
                            <NestedListDrawer style={{paddingLeft:'4em'}} text="Repair Descriptive">
                                <ListItem style={{paddingLeft: '6em'}}>
                                    <CustomMultiSelect data={repairDescriptives} value={selectedRepairDescriptives} maxDisplayedValues={5} filterable autoClose={false} onChange={(e:any)=>{setSelectedRepairDescriptives(e.value)}} />
                                </ListItem>
                            </NestedListDrawer>
                        </NestedListDrawer>
                        <NestedListDrawer toggle style={{paddingLeft:'2em'}} text="Calibration Record" disabled={!calEnabled}
                        onToggle={() => setCalEnabled(!calEnabled)}
                        >
                    </NestedListDrawer>
                    </NestedListDrawer>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onExport}>Export</Button>
            </DialogActions>
        </Dialog>
    </div>
    );

}

export default ExcelExportDialog;