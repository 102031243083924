import * as React from 'react';
import IProductType from '../Model/IProductType';
import IRecordType from '../Model/IRecordType';
import Button from 'react-bootstrap/Button';
import { Container } from '@material-ui/core';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

export interface ResultSummaryProps {
   result: any;
   productType: IProductType;
   recordType: IRecordType;
   handleButtonClick: any;
}

const RenderLineItem = (item: any) => {
   let returnString = '';

   if (typeof item === 'string') return item;

   if ('serialNumber' in item) returnString += `Serial Number: ${item.serialNumber} `;

   if ('payload' in item) returnString += `Payload: ${item.payload} `;

   if ('reason' in item) returnString += `- Reason: ${item.reason} `;

   return returnString;
};

const ResultSummaryBreakdown = (props: any) => {
   return (
      <Card>
         <Card.Header>
            {props.list.length || 0} {props.title}
            <Accordion.Toggle as={Button} variant="link" eventKey={props.key}>
               view
            </Accordion.Toggle>
         </Card.Header>
         <Accordion.Collapse eventKey={props.key}>
            <React.Fragment>
               {props.list.map((item: any, index: number) => (
                  <Card.Body key={index}>{RenderLineItem(item)}</Card.Body>
               ))}
            </React.Fragment>
         </Accordion.Collapse>
      </Card>
   );
};

const ResultSummary: React.SFC<ResultSummaryProps> = (props: ResultSummaryProps) => {
   return (
      <Container className="text-center">
         <h2>Upload Complete</h2>
         <p>
            <strong>Product Type:</strong> {props.productType.name} <strong>Record Type:</strong> {props.recordType.name}
         </p>
         <p>
            <strong>{props.result} </strong>
         </p>
         <div className="mt-2">
            <Button className="ml-2 float-right" variant="secondary" type="button" value="finish" onClick={props.handleButtonClick}>
               Finish
            </Button>
            <Button className="ml-2 float-right" variant="primary" type="button" value="duplicate" onClick={props.handleButtonClick}>
               Add Duplicate
            </Button>
            <Button className="ml-2 float-right" variant="primary" type="button" value="new" onClick={props.handleButtonClick}>
               Add New
            </Button>
         </div>
      </Container>
   );
};

export default ResultSummary;
