import ISurveyRepairType from "../../../Model/ISurveyRepairType";
import config from "../../../../config";
import { ApiFaultRepairType } from "../../../Model/API/ApiFaultRepairType";
import { getProductTypes } from "../GetProductTypes";

export async function getFaultRepairTypes(get: (address: string) => Promise<any>): Promise<ISurveyRepairType[]> {
  const faultRepairTypesUrl = config.apiGateway.ITR_API + '/api/faultRepairTypes';
  const response = await get(faultRepairTypesUrl);
  if (!response || !Array.isArray(response))
    return [];

  const productTypes = await getProductTypes(get);

  return response.map<ISurveyRepairType>((faultRepairType: ApiFaultRepairType) => {
    if (faultRepairType.faultRepairType === 'Item') {
      return {
        id: 0,
        name: faultRepairType.name,
        itemDescriptive: 'Item',
        productTypeName: faultRepairType.productTypeName,
        productType: productTypes.find(pt => pt.id === faultRepairType.productTypeId)
      }
    } else {
      return {
        id: faultRepairType.id,
        name: faultRepairType.name,
        itemDescriptive: 'Descriptive',
        faultRepair: faultRepairType.descriptiveType,
      }
    }
  });
}