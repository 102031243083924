import ApiResponseHandler from "./ApiResponseHandler";

/**
 * API handler for endpoints which return blob files responses.
 */
export class BlobApiResponseHandler extends ApiResponseHandler<unknown, never> {
  constructor() {
    super({});
  }

  handleResponse(response: Response, address: string, method: string, body?: string | File): Promise<string> {
    if (!response.ok)
      return super.handleResponse(response, address, method, body);

    return response.blob().then(blob =>
      new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve((reader.result as string | null) ?? "");
        reader.readAsDataURL(blob);
      })
    );
  }
}