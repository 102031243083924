import * as React from 'react';
import { Redirect } from 'react-router';
import TokenRefresher from './TokenRefresher';
import { JwtManager } from './JwtManager';

export interface RedirectState<T> {
  redirect: boolean;
  loading: boolean;
  innerState: T;
}

export default class ApiResponseHandler<TProps, TState extends object> extends React.Component<TProps, RedirectState<TState>> {
  tokenRefresher = new TokenRefresher({});
  tokenManager = new JwtManager({});
  token: string | null;

  constructor(props: TProps) {
    super(props);
    this.token = this.tokenManager.getToken();
    // The below is a funny line... it basically means that the redirect should occur if token (string | null) has no value or its value has no length.
    this.state = {
      redirect: !this.token || this.token.length === 0,
      loading: false,
      innerState: {} as TState,
    };
  }

  redirectToLogin(): Promise<any> {
    this.setState({ redirect: true, loading: false });
    window.location.reload();
    return Promise.reject('Unauthorised. Redirecting to login.');
  }

  get(address: string, body?: string): Promise<any> {
    return this.query(address, 'GET', body);
  }

  post(address: string, body?: string, errorNotAsString?: boolean): Promise<any> {
    return this.query(address, 'POST', body, errorNotAsString);
  }

  postFile(address: string, body: File): Promise<any> {
    this.setState({
      loading: true,
    });
    return fetch(address, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: body,
    })
      .then((response) => {
        return this.handleResponse(response, address, 'POST', body);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  put(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
    return this.query(address, 'PUT', body, undefined, removeHeaderContentType);
  }

  delete(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
    return this.query(address, 'DELETE', body, undefined, removeHeaderContentType);
  }

  query(address: string, method: string, body?: string, errorNotAsString?: boolean, removeHeaderContentType?: boolean): Promise<any> {
    var header: {};
    header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    };
    if (removeHeaderContentType) {
      header = {
        Authorization: `Bearer ${this.token}`,
      };
    }
    this.token = this.tokenManager.getToken();
    this.setState({
      redirect: !this.token || this.token.length === 0,
      loading: true,
    });
    return fetch(address, {
      method: method,
      headers: header,
      body: body,
    })
      .then((response) => {
        return this.handleResponse(response, address, method, body);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
        return Promise.reject(this.getErrorString(error, errorNotAsString));
      });
  }

  handleResponse(response: Response, address: string, method: string, body?: string | File): Promise<string> {
    if (response.ok) {
      const _this = this;
      // Handle the NoContent response
      return response.text().then(function (text) {
        return text ? _this.isJsonString(text) ? JSON.parse(text) : text : Promise.resolve(undefined);
      });
    } else {
      if (response.status === 401 || response.status === 403) {
        var expiry = this.tokenManager.getExpires();
        if (expiry && new Date() > expiry) {
          // Token has expired, try a refresh
          return this.tokenRefresher
            .refreshToken()
            .then((tk: any) => {
              // Update the token and recursively try again.
              this.token = tk;
              if (body instanceof File) {
                return this.postFile(address, body);
              } else {
                return this.query(address, method, body);
              }
            })
            .catch(() => {
              if (response.status !== 403) {
                this.tokenManager.removeToken();
                this.redirectToLogin();
              }
              return Promise.reject(response);
            });
        } else {
          if (response.status !== 403) {
            this.tokenManager.removeToken();
            this.redirectToLogin();
          }
          return response.text().then((text) => {
            return Promise.reject(text);
          });
        }
      } else {
        return response.text().then((text) => {
          return Promise.reject(text);
        });
      }
    }
  }

  getErrorString(error: any, errorNotAsString?: boolean): string {
    if (errorNotAsString) return error;

    if (error.constructor === String) {
      return error.toString();
    }
    if (error instanceof Response) {
      var errorResponse = error as Response;
      return errorResponse.statusText;
    }
    return error.toString();
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  protected setInnerState = (update: TState | ((prevInnerState: TState) => TState), callback?: () => void) =>
    this.setState(prevState => ({
      innerState: typeof update === 'object' ? update : update(prevState.innerState)
    }), callback);

  render() {
    return <div>{this.state.redirect && <Redirect to="/login" push/>}</div>;
  }
}
