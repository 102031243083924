import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { GridItemChangeEvent } from '@progress/kendo-react-grid';

import { Calibrator } from '../Model/Calibrator';
import GridPage from '../Shared/Grids/GridPage';
import { createActionCell } from '../Shared/Grids/ActionCell';
import { createErrorInfoMessage, createSuccessInfoMessage } from '../Shared/Infobanner';
import { getErrorMessageOrDefault } from '../Shared/Utils/GetErrorMessageOrDefault';
import { updateById } from '../Shared/Utils/Changes/UpdateById';
import { CalibratorAPI } from '../Shared/Data/CalibratorAPI';

export default class CalibratorList extends GridPage<Calibrator> {
  private _calibratorApi: CalibratorAPI;

  constructor(props: RouteComponentProps) {
    super(props, 'Calibrator');

    this.query = this.query.bind(this);
    this._calibratorApi = new CalibratorAPI(this.query);

    // Allow user to change items on the grid
    this.gridOverrides = {
      onItemChange: this.handleItemChange
    };

    // Ensure user can delete and save calibrators
    this.ActionCell = createActionCell({
      onEdit: this.handleEdit,
      editVisible: (item: Calibrator) => item.id !== this.state.innerState.inEditId,
      onSave: this.handleSave,
      saveVisible: (item: Calibrator) => item.id === this.state.innerState.inEditId,
      onDelete: this.handleDelete,
    });
  }

  protected fetchData(): Promise<Calibrator[]> {
    return this._calibratorApi.fetchAll();
  }

  protected handleAddNewItem(): void {
    const newCalibrator = new Calibrator();
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      data: prevInnerState.data.concat(newCalibrator),
      inEditId: newCalibrator.id
    }));
  }

  protected handleEdit(item: Calibrator): void {
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      inEditId: item.id
    }));
  }

  private handleSave = (item: Calibrator) =>
    this._calibratorApi.save(item)
      .then(newId => {
        const updateId = updateById(item.id, (existing: Calibrator) => new Calibrator(newId, existing.name));
        const updateData = (prevData: Calibrator[]) => prevData.map(updateId);
        this.handleAPIChange(item, `Saved calibrator ${item.name}`, updateData);
      })
      .catch(this.handleAPIError);

  private handleDelete = (item: Calibrator): Promise<void> => {
    if (item.isNew()) {
      this.deleteUnsavedCalibrator(item);
      return Promise.resolve();
    }

    return this.deleteCalibratorFromAPI(item);
  };

  private deleteUnsavedCalibrator = (item: Calibrator) => {
    const filterOutDeleted = (prevData: Calibrator[]) => prevData.filter(calibrator => calibrator.id !== item.id);
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      inEditId: item.id === prevInnerState.inEditId ? undefined : prevInnerState.inEditId,
      data: filterOutDeleted(prevInnerState.data)
    }));
  };

  private deleteCalibratorFromAPI = (item: Calibrator): Promise<void> => {
    const filterOutDeleted = (prevData: Calibrator[]) => prevData.filter(calibrator => calibrator.id !== item.id);

    return this._calibratorApi.delete(item)
      .then(() => this.handleAPIChange(item, `Deleted calibrator ${item.name}`, filterOutDeleted))
      .catch(this.handleAPIError);
  };

  private handleAPIChange = (changed: Calibrator, successMessage: string, updateData: (prevData: Calibrator[]) => Calibrator[]) =>
    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        inEditId: changed.id === prevState.innerState.inEditId ? undefined : prevState.innerState.inEditId,
        bannerMessage: createSuccessInfoMessage(successMessage),
        data: updateData(prevState.innerState.data)
      }
    }));

  private handleAPIError = (e: unknown) => {
    const message = getErrorMessageOrDefault(e, 'Unable to save calibrator at this time');
    this.setState(prevState => ({
      loading: false,
      innerState: {
        ...prevState.innerState,
        bannerMessage: createErrorInfoMessage(message),
      }
    }));
  };

  protected handleItemChange = (e: GridItemChangeEvent): void => {
    const { dataItem, field, value: newName } = e;
    if (dataItem?.id == null || field !== 'name')
      return;

    this.handleNameChange(dataItem.id, newName);
  };

  private handleNameChange = (id: number, newName: string): void => {
    const updateName = updateById(id, (calibrator: Calibrator) => new Calibrator(calibrator.id, newName));

    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      data: prevInnerState.data.map(updateName),
    }));
  };
}