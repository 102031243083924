import * as React from 'react';
import { Alert } from 'react-bootstrap';

export interface InfoMessage {
   show: boolean;
   warn: boolean;
   message: React.ReactNode;
   successMessage?: boolean;
   error: boolean;
}

export const BLANK_BANNER_MESSAGE: InfoMessage = {
   message: '',
   show: false,
   warn: false,
   error: false
};

export function InfoBanner(props: any) {
   if (!props.message.show) {
      return null;
   }

   const anchor = document.querySelector('#back-to-top-anchor');

   if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
   }

   const variant =
     props.message.error
       ? 'danger'
       : props.message.warn
         ? 'warning'
         : props.message.successMessage
           ? 'success'
           : 'info';

   return (
      <Alert variant={variant}>
         {props.message.successMessage ? <span className="glyphicon glyphicon-ok"> </span> : ''}
         {props.message.message}
      </Alert>
   );
}

export const createErrorInfoMessage = (message: React.ReactNode): InfoMessage => ({
   message,
   show: true,
   error: true,
   warn: false,
   successMessage: false,
});

export const createSuccessInfoMessage = (message: string): InfoMessage => ({
   message,
   show: true,
   error: false,
   warn: false,
   successMessage: true,
});
