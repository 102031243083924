import { TestEquipmentCard } from '../TestEquipmentCard';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { CommonTestEquipmentCardProps } from './Types';

export function TestEquipmentLoadingCard(props: CommonTestEquipmentCardProps) {
  return (
    <TestEquipmentCard
      {...props}
      variant="info"
    >
      <div className="my-3">
        <Spinner animation='border'/>
      </div>
    </TestEquipmentCard>
  );
}