import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import { Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import IProduct from '../Model/IProduct';
import config from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import { GridStatusCell } from '../Shared/GridStatusCell';
import queryString from 'query-string';
import moment from 'moment';
import separateSerialFromPrefix from '../Shared/Utils/SeparateSerialAndPrefix';
import Attribute from '../Model/Attribute';
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps';
import { ProductsGridActionsCell } from './ProductsGridActionsCell';

export interface ProductsProps extends RouteComponentProps<any> {}

export interface ProductsState {
   products: IProduct[];
   dataState: any;
   bannerMessage: InfoMessage;
   showOnlyTopLevel: boolean;
}

const initialDataState = {
   take: 10,
   skip: 0,
   filter: { filters: [] },
};

class Products extends ApiResponseHandler<ProductsProps, ProductsState> {
   actionCell: React.FC<GridCellProps>;
   statusCell: any;

   constructor(props: ProductsProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: {
            products: [],
            dataState: initialDataState,
            bannerMessage: { message: '', show: false, warn: false, error: false },
            showOnlyTopLevel: false,
         },
      };

      this.actionCell = (props) =>
        <ProductsGridActionsCell
          onFilterParent={this.onFilterParent.bind(this)}
          onReport={this.onShowReports.bind(this)}
          onShowTestEquipment={this.onShowTestEquipment.bind(this)}
          {...props}
        />;

      this.statusCell = GridStatusCell();
   }

   componentDidMount() {
      this.setState({
         loading: true,
      });

      const values = queryString.parse(this.props.location.search);
      let filterField = (values.filter || '').toString();
      let filterValue = (values.value || '').toString();

      const filter =
         filterField !== ''
            ? {
                 logic: 'and' as const,
                 filters: [{ field: filterField, operator: 'eq', value: filterValue }],
              }
            : null;

      let dataState = this.state.innerState.dataState;

      if (filter) dataState.filter.filters = [filter];

      this.getProducts(1, 10, dataState);
   }

   getProducts(page: number, size: number, dataState: any) {
      const mappingsUrl = config.apiGateway.META_API + '/api/Assets/parentMappings/';
      let totalCount = 0;

      var sortFilter: any = {};

      if (dataState.filter) {
         sortFilter.attribute = { key: 'currentStatus', value: '' };
         dataState.filter.filters.map((filterGroup: any) => {
            if (filterGroup.filters) {
               filterGroup.filters.map((filter: any, i: number) => {
                  switch (filter.field) {
                     case 'lastRecordDate':
                        let date = moment.utc(filter.value);

                        if (i === 0) {
                           date = date.startOf('day');
                           sortFilter.attribute = { key: 'DateOfLastRecord', value: date.format() };
                        } else {
                           date = date.endOf('day');
                           sortFilter.attribute = { ...sortFilter.attribute, value: sortFilter.attribute.value + ';' + date.format() };
                        }
                        break;
                     case 'productSerialNumber':
                        if (filter.value)
                        {
                           const serial = filter.value.serialNumber ?? filter.value;
                           sortFilter.attribute = {key: 'SerialNumberPrefix', value: separateSerialFromPrefix(serial, false)};
                           sortFilter.serialNumbers = [separateSerialFromPrefix(filter.value)];
                        }
                        break;
                     case 'supplierName':
                        sortFilter.assetManufacturerNames = [filter.value];
                        break;
                     case 'productTypeName':
                        sortFilter.assetModels = [filter.value];
                        break;
                     case 'currentStatus':
                        sortFilter.attribute = { key: 'currentStatus', value: filter.value === 'n/a' ? '-' : filter.value };
                        break;
                     case 'parent':
                        sortFilter.ParentAssetId = filterGroup.filters.find((f: any) => f.field === 'id').value;
                        break;
                  }
               });
            }
         });
      }

      if (dataState.sort) {
         let sort = dataState.sort[0];
         if (sort) {
            sortFilter.sortDirection = sort.dir === 'asc';

            switch (sort.field) {
               case 'productSerialNumber':
                  sortFilter.sortField = 'SerialNumber';
                  break;
               case 'supplierName':
                  sortFilter.sortField = 'AssetManufacturerName';
                  break;
               case 'productTypeName':
                  sortFilter.sortField = 'AssetTypeName';
                  break;
               case 'currentStatus':
                  sortFilter.sortField = 'CurrentStatus';
                  break;
               case 'lastRecordDate':
                  sortFilter.sortField = 'DateOfLastRecord';
                  break;
            }
         }
      }

      const productsUrl =
         config.apiGateway.META_API + `/api/assets/page/${page}/size/${size}/filter/${encodeURIComponent(JSON.stringify(sortFilter))}`;

      this.get(productsUrl)
         .then((response) => {
            if (response) {
               totalCount = response.totalCount;

               return response.result.map((productJson: any) => {
                  let lastRecordDate = productJson.attributes ? productJson.attributes.find((a: any) => a.key === 'DateOfLastRecord') : undefined;
                  let currentStatus = productJson.attributes ? productJson.attributes.find((a: any) => a.key === 'CurrentStatus') : undefined;
                  let serialNumberPrefix = productJson.attributes?.find((a: Attribute) => a.key === 'SerialNumberPrefix');
                  let product = {
                     productSerialNumber: serialNumberPrefix ? `${serialNumberPrefix.value}${productJson.serialNumber}` : productJson.serialNumber,
                     id: productJson.id,
                     productTypeId: productJson.assetModelId,
                     productTypeName: productJson.assetModel,
                     supplierid: productJson.assetManufacturerId,
                     supplierName: productJson.assetManufacturerName,
                     lastRecordDate: lastRecordDate !== undefined ? moment.utc(lastRecordDate.value).format('DD/MM/YYYY HH:mm') : undefined,
                     currentStatus: currentStatus ? JSON.parse(currentStatus.value).Conclusion.toLowerCase() : 'n/a',
                     parent: -1,
                  };

                  return product;
               });
            }
         })
         .then(async (products) => {
            if (products) {
               await Promise.all(
                  products.map((p: IProduct, index: any, array: IProduct[]) => {
                     return this.get(mappingsUrl + p.id).then((mappings) => {
                        if(mappings !== undefined)
                        {
                           let mapping = mappings.find((x: any) => x !== undefined);
                           array[index] = mapping === undefined ? { ...p, parent: -1 } : { ...p, parent: mapping.parentId };
                        }   
                     });
                  })
               );
            }
            this.setState({
               loading: false,
               innerState: { ...this.state.innerState, products, dataState: { ...this.state.innerState.dataState, total: totalCount } },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   onFilterParent(dataItem: any) {
      let filter = {
         logic: 'or' as const,
         filters: [
            { field: 'parent', operator: 'eq', value: dataItem.id },
            { field: 'id', operator: 'eq', value: dataItem.id },
         ],
      };

      let dataState = this.state.innerState.dataState;

      dataState.filter.filters.push(filter);

      // TODO: recurse data to find components of components etc...

      dataState.showOnlyTopLevel = false;

      this.getProducts(dataState.skip / dataState.take + 1, dataState.take, dataState);
   }

   onShowReports(dataItem: any) {
      this.props.history.push(`/Records?filter=productSerialNumber&value=${dataItem.productSerialNumber}&secondaryFilter=productTypeId&additionalValue=${dataItem.productTypeId}`);
   }

   onShowTestEquipment(dataItem: IProduct) {
      this.props.history.push(`/TestEquipment?product=${dataItem.id}`);
   }

   onSortChange(e: any) {
      const dataState = { ...this.state.innerState.dataState, sort: e.sort };

      this.setState({
         innerState: {
            ...this.state.innerState,
            dataState,
         },
      });

      this.getProducts(dataState.skip / dataState.take + 1, dataState.take, dataState);
   }

   onFilterChange(e: any) {
      const dataState = { ...this.state.innerState.dataState, take: 10, skip: 0, filter: e.filter || { filters: [] } };

      this.setState({
         innerState: {
            ...this.state.innerState,
            dataState,
         },
      });

      this.getProducts(dataState.skip / dataState.take + 1, dataState.take, dataState);
   }

   render() {
      const products = this.state.innerState.products;
      const dataState = this.state.innerState.dataState;

      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <div className="title-container">
               <h1 className="page-header">Products</h1>
            </div>
            <Button
               variant="primary"
               className="float-right"
               size="sm"
               onClick={() => {
                  let newState = { ...dataState, filter: { filters: [] } };
                  this.setState({
                     innerState: {
                        ...this.state.innerState,
                        showOnlyTopLevel: false,
                        dataState: newState,
                     },
                  });
                  this.getProducts(dataState.skip / dataState.take + 1, dataState.take, newState);
               }}
               disabled={this.state.innerState.dataState.filter.filters.length === 0}>
               Clear Filters
            </Button>
            <Grid
               className="mt-5"
               pageable
               sortable
               data={products}
               {...dataState}
               onDataStateChange={(e) => {
                  this.setState({ innerState: { ...this.state.innerState, dataState: e.data } });
               }}
               onPageChange={(e: any) => {
                  this.getProducts(e.page.skip / e.page.take + 1, e.page.take, dataState);
                  this.setState({ innerState: { ...this.state.innerState, dataState: { ...dataState, skip: e.page.skip, take: e.page.take } } });
               }}
               onFilterChange={this.onFilterChange.bind(this)}
               onSortChange={this.onSortChange.bind(this)}>
               <Column
                  className="grid-overflow-fix text-truncate"
                  field="productSerialNumber"
                  title="Serial Number"
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={products} expanded={true} custom={'serial'} />}
               />
               <Column
                  className="grid-overflow-fix text-truncate"
                  field="supplierName"
                  title="Supplier"
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={products} expanded={true} custom={'text'} />}
               />
               <Column
                  className="grid-overflow-fix text-truncate"
                  field="productTypeName"
                  title="Product Type"
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={products} expanded={true} custom={'text'} />}
               />
               <Column
                  className="grid-overflow-fix text-truncate"
                  field="lastRecordDate"
                  title="Last Record"
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={products} expanded={true} custom={'date'} />}
               />
               <Column
                  field="currentStatus"
                  title="Status"
                  cell={this.statusCell}
                  width="80em"
                  columnMenu={(p) => <GridSortFilterColumn {...p} data={products} expanded={true} custom={'status'} />}
               />
               <Column title="Actions" width="150em" cell={this.actionCell} filterable={false} />
            </Grid>
         </React.Fragment>
      );
   }
}

export default Products;
