import React from 'react';
import { CalibratedTestEquipmentCard } from '../CalibrationCards/CalibratedTestEquipmentCard';
import { UncalibratedTestEquipmentCard } from '../CalibrationCards/UncalibratedTestEquipmentCard';
import { TestEquipmentNotFoundCard } from '../CalibrationCards/TestEquipmentNotFoundCard';
import { TestEquipmentLoadingCard } from '../CalibrationCards/TestEquipmentLoadingCard';
import { TestEquipmentErrorCard } from '../CalibrationCards/TestEquipmentErrorCard';
import ApiResponseHandler from '../../../Shared/ApiResponseHandler';
import TestEquipment from '../../../Model/TestEquipment';
import TestEquipmentAPI from '../../../Shared/Data/TestEquipment/TestEquipmentAPI';
import { getErrorMessageOrDefault } from '../../../Shared/Utils/GetErrorMessageOrDefault';
import { CalibrationSkippedCard } from '../CalibrationCards/CalibrationSkippedCard';

interface AssetCalibrationDetailsProps {
  serialNumber: string;
  onDelete: (serialNumber: string) => void;
  disabled: boolean;
}

interface TestEquipmentCalibrationDetailsState {
  testEquipment: TestEquipment | undefined;
  error: string | undefined;
}

export class TestEquipmentCalibrationDetails extends ApiResponseHandler<AssetCalibrationDetailsProps, TestEquipmentCalibrationDetailsState> {
  private _testEquipmentAPI: TestEquipmentAPI;

  constructor(props: AssetCalibrationDetailsProps) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,
      innerState: {
        testEquipment: undefined,
        error: undefined,
      }
    };

    this.query = this.query.bind(this);
    this._testEquipmentAPI = new TestEquipmentAPI(this.query);
  }

  componentDidMount() {
    const { serialNumber } = this.props;

    this._testEquipmentAPI.getBySerial(serialNumber)
      .then(testEquipment => this.setState({
        loading: false,
        innerState: {
          testEquipment: testEquipment[0],
          error: undefined,
        }
      }))
      .catch(e => this.setState({
        loading: false,
        innerState: {
          testEquipment: undefined,
          error: `API Error: ${getErrorMessageOrDefault(e, `Unable to find test equipment for serial number ${serialNumber} at this time`)}`
        }
      }));
  }

  render() {
    const { loading, innerState: { testEquipment, error } } = this.state;
    if (loading)
      return (
        <TestEquipmentLoadingCard {...this.props} />
      );

    if (error)
      return (
        <TestEquipmentErrorCard {...this.props} error={error} />
      );

    if (!testEquipment)
      return (
        <TestEquipmentNotFoundCard {...this.props} />
      );

    const { calibration, assetTag, description, deviceOwner, location } = testEquipment;
    if (!calibration)
      return (
        <UncalibratedTestEquipmentCard
          {...this.props}
          assetTag={assetTag}
          description={description}
          deviceOwner={deviceOwner.name}
          location={location.name}
        />
      );

    return calibration.skipped
      ? (
        <CalibrationSkippedCard
          {...this.props}
          assetTag={assetTag}
          description={description}
          deviceOwner={deviceOwner.name}
          location={location.name}
          calibration={calibration}
        />
      )
      : (
        <CalibratedTestEquipmentCard
          {...this.props}
          assetTag={assetTag}
          description={description}
          deviceOwner={deviceOwner.name}
          location={location.name}
          calibration={calibration}
        />
      );

  }
}

