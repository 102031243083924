import * as React from 'react';
import {Drawer,withStyles, createStyles, WithStyles, Divider, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { calculateResponsivePanelWidth } from '../Helpers/CSSHelpers';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';

interface SideDrawerProps extends WithStyles<typeof styles> {
    isOpen: boolean;
    anchor: string;
    handleDrawerClose?: any;
    classes: {
        drawerPaper: string,
        drawerOpen: string,
        drawerClose: string,
        toolbar: string,
        drawerHeader: string,
        drawerCloseRightPannel: string
    }
    showBurgerIcon?: boolean;
    showCloseIcon?: boolean;
    width?: string;
    theme: any;
    showDrawerAsToolbar?: boolean
}

const styles = (theme: any) => createStyles({
    toolbar: theme.mixins.toolbar, 
    drawerPaper: {
        width: calculateResponsivePanelWidth("340px"),
        background: '#393939',
        color: '#fff'
    },
    drawerOpen: {
          width: calculateResponsivePanelWidth("340px"),
          transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
          }),
      },
    drawerClose: {
          transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
          }),
          
          overflowX: 'hidden',
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
            '& a': {
                height: '47px',
                paddingLeft: '13px',
            }
        },
      },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawerCloseRightPannel: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
          width: 0,
          '& a': {
              height: '47px',
              paddingLeft: '13px',
          }
      }
    }
  });

class SideDrawer extends React.Component<SideDrawerProps>{

    handleDrawerClose(){

        if(this.props.handleDrawerClose)
        {
            this.props.handleDrawerClose();
        }
    }

    public render() {
        const { classes, theme } = this.props;
        var show = this.props.isOpen;
        return (
            <Drawer 
            variant="permanent" 
            anchor={(this.props.anchor === "right")? "right":"left"} 
            open={show}
            className={classNames({
                [classes.drawerOpen]: show,
                [classes.drawerClose]: !show && (this.props.anchor !== "right" || this.props.showDrawerAsToolbar),
                [classes.drawerCloseRightPannel]: !show && (this.props.anchor === "right"  && !this.props.showDrawerAsToolbar),
            })} classes={{
            paper: classNames(classes.drawerPaper,{
                [classes.drawerOpen]: show,
                [classes.drawerClose]:  !show && (this.props.anchor !== "right" || this.props.showDrawerAsToolbar),
                [classes.drawerCloseRightPannel]: !show && (this.props.anchor === "right"  && !this.props.showDrawerAsToolbar),
                
            })
            }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton className="white-button" onClick={this.handleDrawerClose.bind(this)}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                {this.props.showCloseIcon?<Button style={{width: "10%"}} variant="secondary" onClick={this.handleDrawerClose.bind(this)}><FontAwesomeIcon icon={faTimes} /></Button>: ''}
                {this.props.children}
            </Drawer> 
        );
    }
}

export default withStyles(styles, { withTheme: true })(SideDrawer);
