import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Collapse, Checkbox } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";


export interface NestedListDrawerProps {
    text: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    toggle?: boolean;
    onToggle?: () => void;
    onOpen?: () => void;
}


const NestedListDrawer: React.FC<NestedListDrawerProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (props.disabled)
            setOpen(false);
    }, [props.disabled]);

    const handleClick = () => {
        setOpen(!open);
    }

    return (
        <>
            <ListItem>
            {
                props.toggle && 
                <ListItemIcon style={props.style}>
                    <Checkbox edge='start' color="primary" checked={!props.disabled} onChange={props.onToggle}/>
                </ListItemIcon>
            }
            <ListItem style={props.style} button disabled={props.disabled} onClick={handleClick}>
                <ListItemText primary={props.text} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            </ListItem>
            <Collapse in={open} timeout='auto' onEnter={() => {
                if (!loaded && props.onOpen)
                    props.onOpen();
                setLoaded(true);
            }}>
                <List component={'div'} disablePadding>
                    {props.children}
                </List>
            </Collapse>
        </>
    );
};


export default NestedListDrawer;