import * as React from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { CustomDropdownList } from '../Shared/DropdownList';
import SerialStatusChecker from '../Shared/SerialStatusChecker';
import CustomMultiSelect from '../Shared/MultiSelect';
import FormTestField from './FormTestField'
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BarcodeScannerModal } from "./BarcodeScannerModal";
import { UploadedFile } from "../Shared/FileUploader/DynamicUpload";
import { UploadFormControl } from "./UploadFormControl";
import { TimeSpanField } from "./TimeSpanField";
import { UnitOfTime } from "../Model/UnitOfTime";
import { parseUploadedDocument } from "../Shared/Utils/ParseUploadedDocument";
import { ViewUploadedDocument } from "./ViewUploadedDocument/ViewUploadedDocument";
import { DocumentModal } from "./DocumentModal";
import { triggerDocumentDownload } from "../Shared/Utils/TriggerDocumentDownload";
import { parseSerialNumberFromAssetQRCode } from '../Model/AssetQRCode';
import { FormEvent } from 'react';
import { TestEquipmentForm } from './TestEquipment/FormControl/TestEquipmentForm';

interface InputFormControlProps {
   label: string;
   variable: string;
   type: 'checkbox' | 'datepicker' | 'timespan' | 'multitext' | 'singletext' | 'dropdown' | 'note' | 'group' |
   'serial' | 'multiselect' | 'testField' | 'upload' | 'row' | 'testEquipment';
   onChange: any;
   data?: any[];
   value?: any;
   readonly: boolean;
   onSerialResult?: any;
   textField?: string;
   maxValues?: number;
   na?:boolean;
   testMaxValue?: number;
   testMinValue?: number;
   testUnit?: string;
   testCriteria?: string;
   testNumber?: number;
   testType?: "yesNo" | "numeric" | "selection";
   testChoices?: string[];
   testPassChoice?: string;
   readBarcode?: boolean;
   unit?: UnitOfTime;
   min?: number;
}

export interface InputFormControlState {
  showScanner: boolean;
  documentContentURI: string;
}

class InputFormControl extends React.Component<InputFormControlProps, InputFormControlState> {
   constructor(props: InputFormControlProps) {
      super(props);

      this.state = {
        showScanner: false,
        documentContentURI: ''
      };

      if(this.props.type === "datepicker")
      {
        this.props.onChange(this.props.variable, this.props.value ? new Date(this.props.value): new Date());
      }
   }

   onChange(e: any) {
     let value: any;

     switch (this.props.type) {
       case 'checkbox':
         value = e.target.checked;
         break;
       case 'dropdown':
         value = e;
         break;
       case 'multiselect':
         value = e.value;
         break;
       case 'testField':
       case 'testEquipment':
         value = e;
         break;
       case 'datepicker':
       case 'multitext':
       case 'singletext':
       case 'serial':
       default:
         value = e.target.value;
         break;
     }

     this.props.onChange(this.props.variable, value);
   }

   private onSubmit(e: FormEvent) {
     e.preventDefault();
   }

   private onDocumentUpload(files: UploadedFile[]) {
     const selectedFile = files[0];
     if (selectedFile)
       this.props.onChange(this.props.variable, selectedFile);
   }

   private onDocumentReset() {
     this.props.onChange(this.props.variable, null);
   }

   private onScanBarcode() {
     this.setState({ showScanner: true });
   }

   private onBarcodeScanned(data: string) {
     const serial = parseSerialNumberFromAssetQRCode(data);
     this.props.onChange(this.props.variable, serial);
     this.setState({ showScanner: false });
   }

   private onBarcodeScanClose() {
     this.setState({ showScanner: false })
   }

   private onViewDocument(contentURI: string) {
     this.setState({
       documentContentURI: contentURI
     });
   }

   private onDocumentViewerClosed() {
    this.setState({
      documentContentURI: ''
    });
  }

  renderInputControl() {
      const disabled = this.props.readonly;

      switch (this.props.type) {
         case 'checkbox':
            let checked = this.props.value;
            if(checked === 'true') checked = true;
            if(checked === 'false') checked = false;
            return (
               <Form>
                  {this.props.testNumber
                   ? <Form.Label>Test No. {this.props.testNumber}</Form.Label>
                   : null}
                  <Form.Check
                     type="checkbox"
                     label={this.props.label}
                     checked={checked}
                     onChange={this.onChange.bind(this)}
                     disabled={disabled}
                  />
               </Form>
            );
         case 'datepicker':
            return (
               <Form>
                  <Form.Label>{this.props.label}</Form.Label>
                  <br />
                  <DatePicker
                     onChange={this.onChange.bind(this)}
                     defaultValue={disabled || this.props.value ? new Date(this.props.value) : new Date()}
                     format={'dd-MM-yyyy'}
                     disabled={disabled}
                  />
               </Form>
            );
        case 'timespan':
          if (!this.props.unit) {
            return (
              <Alert variant="danger">
                Unit of time is required for timespan controls, update the record type to enter {this.props.label} data
              </Alert>
            );
          }

          return (
            <Form className="d-flex flex-column">
              <Form.Label>{this.props.label}</Form.Label>
              <TimeSpanField
                value={this.props.value}
                unit={this.props.unit}
                onChange={this.onChange.bind(this)}
                disabled={disabled}
              />
            </Form>
          );
         case 'multitext':
            return (
               <Form>
                  <Form.Label>{this.props.label}</Form.Label>
                  <Form.Control
                     as="textarea"
                     rows={3}
                     onChange={this.onChange.bind(this)}
                     value={this.props.value === null ? '' : this.props.value}
                     disabled={disabled}
                    className={this.props.value !== undefined && this.props.value !== null? 'highlight': ''}
                  />
               </Form>
            );
         case 'singletext':
            return (
              <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Label>{this.props.label}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={this.onChange.bind(this)}
                    value={this.props.value === null ? '' : this.props.value}
                    disabled={disabled}
                    className={this.props.value !== undefined && this.props.value !== null? 'highlight': ''}
                  />
                  {
                    this.props.readBarcode &&
                      <InputGroup.Append>
                          <Button variant="secondary" onClick={this.onScanBarcode.bind(this)}>
                              <FontAwesomeIcon icon={faBarcode}/>
                              Barcode
                          </Button>
                      </InputGroup.Append>
                  }
                  <BarcodeScannerModal
                    show={this.state.showScanner}
                    onScanned={this.onBarcodeScanned.bind(this)}
                    onClose={this.onBarcodeScanClose.bind(this)}
                    title={`Scan ${this.props.label}`}
                  />
                </InputGroup>
              </Form>
            );
         case 'serial':
            return (
               <Form>
                  <Form.Label>{this.props.label}</Form.Label>
                  <SerialStatusChecker
                     onChange={this.onChange.bind(this)}
                     onSerialResult={this.props.onSerialResult.bind(this, this.props.variable)}
                     value={this.props.value === null ? '' : this.props.value}
                     disabled={disabled}
                  />
               </Form>
            );
        case 'upload':
          if (disabled) {
            if (!this.props.value) {
              return (
                <Form>
                  <Form.Label>{this.props.label} was not uploaded</Form.Label>
                </Form>
              );
            }

            const document = parseUploadedDocument(this.props.value);

            return (
              <>
                <Form className="d-inline-flex flex-column">
                  <Form.Label>{this.props.label}</Form.Label>
                  <ViewUploadedDocument
                    document={document}
                    onViewDocument={this.onViewDocument.bind(this)}
                    onDownloadDocument={triggerDocumentDownload}
                  />
                </Form>
                <DocumentModal
                  contentDataURI={this.state.documentContentURI}
                  document={document}
                  onClose={this.onDocumentViewerClosed.bind(this)}
                />
              </>
            );
          }

          return (
            <Form>
              <Form.Label>{this.props.label}</Form.Label>
              <UploadFormControl
                value={this.props.value}
                label={this.props.label}
                onDocumentReset={this.onDocumentReset.bind(this)}
                onDocumentUpload={this.onDocumentUpload.bind(this)}
              />
            </Form>
          );
         case 'dropdown':
            return (
               <Form>
                  <Form.Label>{this.props.label}</Form.Label>
                  <CustomDropdownList
                     data={this.props.data}
                     onChange={this.onChange.bind(this)}
                     defaultValue={this.props.value}
                     disabled={disabled}
                     selectText={this.props.textField}
                  />
               </Form>
            );
         case 'multiselect':
            return (
               <Form>
                  <Form.Label>{this.props.label}</Form.Label>
                  <CustomMultiSelect
                     data={this.props.data}
                     onChange={this.onChange.bind(this)}
                     value={!this.props.value ? [] : this.props.value}
                     disabled={disabled}
                     textField={this.props.textField}
                     maxValues={this.props.maxValues}
                     autoClose={false}
                     filterable={true}
                     className={this.props.value? 'highlight': ''}
                  />
               </Form>
            );
         case 'testField':
            return (
               <Form>
                  <FormTestField
                     testType={this.props.testType}
                     label={this.props.label}
                     value={!this.props.value ? '' : this.props.value}
                     onChange={this.onChange.bind(this)}
                     max={this.props.testMaxValue}
                     min={this.props.testMinValue}
                     unit={this.props.testUnit}
                     criteria={this.props.testCriteria}
                     na={this.props.na}
                     testNumber={this.props.testNumber}
                     disabled={disabled}
                     choices={this.props.testChoices}
                     passChoice={this.props.testPassChoice}
                  />
               </Form>
            );
        case 'testEquipment':
          // Serial numbers will be serialized when data is uploaded
          const serialNumbers: string[] = typeof this.props.value === 'string'
            ? JSON.parse(this.props.value)
            : this.props.value;

          return (
            <TestEquipmentForm
              label={this.props.label}
              serialNumbers={serialNumbers ?? []}
              onChange={this.onChange.bind(this)}
              disabled={disabled}
            />
          );
       default:
          return <div>No valid control found</div>;
      }
   }

   render() {
      return <div className="mt-4">{this.renderInputControl()}</div>;
   }
}

export default InputFormControl;
