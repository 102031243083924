import * as React from 'react';
import TokenRefresher from './TokenRefresher';
import { decodeJWT } from './ParseJWTToken';
import config from '../../config';

const tokenKey = 'jwt';
const tokenExpiryKey = 'jwtExpires';
const refreshTokenKey = 'refreshToken';
const claimsKey = 'claims';
const supplierIDKey = 'supplierID';
const viewRecordViewCategoriesKey = 'recordViewCategories';

export class JwtManager extends React.PureComponent {
   getToken(): string | null {
      return localStorage.getItem(tokenKey);
   }

   setToken(token: string) {
      localStorage.setItem(tokenKey, token);
   }

   removeToken() {
      localStorage.removeItem(tokenKey);
   }

   getRefreshToken(): string | null {
      return localStorage.getItem(refreshTokenKey);
   }

   setRefreshToken(refreshToken: string) {
      localStorage.setItem(refreshTokenKey, refreshToken);
   }

   getExpires(): Date | null {
      var expires = localStorage.getItem(tokenExpiryKey);
      if (!expires) return null;
      return new Date(Number(expires) * 1000);
   }

   checkTokenIsValid(): boolean {
      var token = this.getToken();
      var expiry = this.getExpires();

      let tokenIsValid = true;

      if (!token || token.length === 0) {
         //token is empty - set to invalid
         tokenIsValid = false;
      } else if (expiry && new Date() > expiry) {
         tokenIsValid = false;

         // Token has expired, try a refresh
         var tokenRefresher = new TokenRefresher({});

         tokenRefresher
            .refreshToken()
            .then((token: any) => {
               tokenIsValid = true;
            })
            .catch(() => {
               //can't refresh - set to invalid
               tokenIsValid = false;
            });
      }

      return tokenIsValid;
   }

   getPayload(): any {
      var token = this.getToken();

      if (token != null) {
         var base64Url = token.split('.')[1];
         var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
         var jsonPayload = decodeURIComponent(
            atob(base64)
               .split('')
               .map(function (c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
               })
               .join('')
         );

         return JSON.parse(jsonPayload);
      } else {
         return null;
      }
   }

   checkTokenHasAdminRights = () => {
      let tokenPayload = this.getPayload();
      let hasAdminRights = false;

      if (tokenPayload.IsAdmin === 'IsAdmin') {
         hasAdminRights = true;
      }

      return hasAdminRights;
   };

   setExpires(expires: any) {
      localStorage.setItem(tokenExpiryKey, expires);
   }

   setClaims(token: string) {
      this.removeSupplierId();
      var claims = decodeJWT(token);
      localStorage.setItem(claimsKey, this.createClaimsValue(claims));
   }

   getClaims(): string | null {
      return localStorage.getItem(claimsKey);
   }

   storeAuthResponse(token: string, expires: number, refreshToken: string) {
      this.setToken(token);
      this.setExpires(expires);
      this.setRefreshToken(refreshToken);
      this.setClaims(token);
   }

   createClaimsValue(claims: any) {
      var setClaim = '';
      if (this.matchClaimString(claims, config.adminClaim)) {
         return (setClaim = config.viewAllClaim);
      } else if (this.matchClaimString(claims, config.ITRConfiguratorClaim)) {
         return (setClaim = config.ITRConfiguratorView);
      } else if (this.matchClaimString(claims, config.assetManufacturerClaim)) {
         this.setSupplierID(claims['AssetManufacturerView']);
         if (claims['ITRViewer'] !== undefined) this.setITRViewerCategories(claims['ITRViewer']);
         return (setClaim = config.externalSupplierView);
      } else if (
         this.matchClaimString(claims, config.ITRInternalUser) ||
         this.matchClaimString(claims, config.ITRSubmitter) ||
         this.matchClaimString(claims, config.ITRViewer)
      ) {
         if (claims['ITRViewer'] !== undefined) this.setITRViewerCategories(claims['ITRViewer']);
         return (setClaim = config.ITRInternalUserView);
      }

      return JSON.stringify(claims);
   }

   setSupplierID(supplierID: string) {
      localStorage.setItem(supplierIDKey, supplierID);
   }

   getSupplierID() {
      return localStorage.getItem(supplierIDKey);
   }

   setITRViewerCategories(recordCatergories: string) {
      localStorage.setItem(viewRecordViewCategoriesKey, recordCatergories);
   }

   getITRViewerCategories() {
      return localStorage.getItem(viewRecordViewCategoriesKey);
   }

   matchClaimString(claims: JSON, matchString: string) {
      var keys = Object.keys(claims);

      for (var i = 0; i < keys.length; i++) {
         if (matchString == keys[i]) return true;
      }
   }
   removeSupplierId() {
      if (this.getSupplierID() !== undefined || this.getSupplierID() !== null) localStorage.removeItem(supplierIDKey);
   }
   removeClaims() {
      if (this.getClaims() !== undefined || this.getClaims() !== null) localStorage.removeItem(claimsKey);
   }
}
