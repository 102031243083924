import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import { RouteComponentProps } from 'react-router-dom';
import { GridActionsCell } from '../Shared/GridActionsCell';
import config from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import { IDataRecord } from '../Model/IDataRecord';
import queryString from 'query-string';
import RecordOptionsBar from './RecordOptionsBar';
import IRecordType from '../Model/IRecordType';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { JwtManager } from '../Shared/JwtManager';
import ISortFilter from '../Model/ISortFilter';
import { GridStatusCell } from '../Shared/GridStatusCell';
import { getSerialNumberFilter } from '../Shared/Utils/SeparateSerialAndPrefix';
import ExcelExportDialog from '../Shared/ExcelExportDialog/ExcelExportDialog';

const tokenManager = new JwtManager({});

export interface RecordsProps extends RouteComponentProps<any> {}

export interface RecordsState {
   records: IDataRecord[];
   recordTypes: IRecordType[];
   exportedRecordTypes: IRecordType[];
   exportDialog: boolean;
   dataState: any;
   bannerMessage: InfoMessage;
   recordCategory: string; // 'All' | 'Test' | 'Despatch' | 'Problem Report';
   availableCategories: string[];
}

const initialDataState = {
   take: 10,
   skip: 0,
   filter: { filters: [] },
};

class Records extends ApiResponseHandler<RecordsProps, RecordsState> {
   actionCell: any;
   statusCell: any;
   bannerTimer: ReturnType<typeof setTimeout> | undefined;

   constructor(props: RecordsProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: {
            records: [],
            dataState: initialDataState,
            bannerMessage: { message: '', show: false, warn: false, error: false },
            recordCategory: 'All',
            recordTypes: [],
            exportedRecordTypes: [],
            exportDialog: false,
            availableCategories: [],
         },
      };

      this.actionCell = GridActionsCell({
         onReport: this.onShowReports.bind(this),
      });

      this.statusCell = GridStatusCell();
   }

   _export: any;
   export = () => {
      this.getRecords(1, 100, this.state.innerState.dataState, this.state.innerState.recordCategory, []);
   };

   componentDidMount() {
      this.setState({
         loading: true,
      });

      const values = queryString.parse(this.props.location.search);

      let filterField = (values.filter || '').toString();
      let filterValue = (values.value || '').toString();
      let secondaryFilterField = (values.secondaryFilter || '').toString();
      let secondaryFilterValue = (values.additionalValue || '').toString();

      const filter =
         filterField !== ''
            ? {
               logic: 'and' as const,
               filters: [{ field: filterField, operator: 'eq', value: filterValue }, { field: secondaryFilterField, operator: 'eq', value: secondaryFilterValue }],
            }
            : null;
      const recordTypesUrl = config.apiGateway.META_API + '/api/datapointtypes/';

      this.getCategories();

      this.get(recordTypesUrl)
         .then((response) => {
            let recordTypes = response.map((recordTypeJson: any) => ({
               name: recordTypeJson.name,
               id: recordTypeJson.id,
               captureType: recordTypeJson.valueType,
               recordType: recordTypeJson.units,
            }));

            let dataState = this.state.innerState.dataState;

            if (filter) dataState.filter.filters = [filter];

            this.setState({ 
               innerState: { 
                  ...this.state.innerState, 
                  recordTypes, 
                  dataState,
                  exportedRecordTypes: recordTypes 
               } 
            });

            this.getRecords(1, 10, dataState, this.state.innerState.recordCategory);
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   componentWillUnmount(){
      this.bannerTimer && clearTimeout(this.bannerTimer)
   }

   getRecords(page: number, size: number, dataState: any, recordCategory: string, exportRecords?: IDataRecord[]) {
      let totalPages = 0;
      let totalCount = 0;
      let records: IDataRecord[] = [];

      var sortFilter: ISortFilter = { filters: [] };

      if (dataState.filter) {
         dataState.filter.filters.map((filterGroup: any) => {
            if (filterGroup.filters) {
               filterGroup.filters.map((filter: any, i: number) => {
                  switch (filter.field) {
                     case 'recordDate':
                        i === 0 ? (sortFilter.startDate = filter.value) : (sortFilter.endDate = filter.value);
                        break;
                     case 'testResult':
                        if (filter.value === 'n/a') filter.value = '-';
                        sortFilter.filters.push({ field: filter.field, value: filter.value });
                        break;
                     case 'productSerialNumber':
                        const serial = filter.value.serialNumber ?? filter.value;
                        const serialFilter = getSerialNumberFilter(serial);
                        if (serialFilter.length >0)
                           sortFilter.filters = [...sortFilter.filters, ...serialFilter];
                        break;
                     default:
                        sortFilter.filters.push({ field: filter.field, value: filter.value });
                        break;
                  }
               });
            }
         });
      }

      if (dataState.sort) {
         let sort = dataState.sort[0];
         if (sort) {
            sortFilter.sortField = sort.field;
            sortFilter.sortDirection = sort.dir === 'asc';
         }
      }
      const recordsUrl =
         config.apiGateway.DATA_API +
         `/api/InstrumentTestRecord/Records/page/${page}/size/${size}/filter/${encodeURIComponent(JSON.stringify(sortFilter))}`;

      this.get(recordsUrl)
         .then((response) => {
            if (response) {
               totalPages = response.totalPages;
               totalCount = response.totalCount;
               records = response.result.map((record: any) => ({
                  ...record,
                  productSerialNumber: record.serialNumberPrefix ? `${record.serialNumberPrefix}${record.productSerialNumber}` : record.productSerialNumber,
                  recordDate: record.recordDate ? moment(record.recordDate, [moment.ISO_8601, 'DD/MM/YYYY hh:mm']).format('DD/MM/YYYY hh:mm') : undefined,
               }));
            }

            if (exportRecords) {
               exportRecords = exportRecords.concat(records);
               if (exportRecords.length === totalCount) {
                  this._export.save(exportRecords);

                  this.setState({
                     loading: false,
                  });
               } else this.getRecords(page + 1, 100, dataState, recordCategory, exportRecords);
            } else {
               this.setState({
                  loading: false,
                  innerState: {
                     ...this.state.innerState,
                     records,
                     recordCategory,
                     dataState: { ...this.state.innerState.dataState, total: totalCount },
                  },
               });
            }
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   getCategories() {
      const categoriesUrl = config.apiGateway.META_API + '/api/Configuration/ITRCATEGORY/';

      const claimsIds = tokenManager.getITRViewerCategories()?.split(',');
      const claim = tokenManager.getClaims();
      let claimCategories: string[] = [];
      this.get(categoriesUrl)
         .then((response) => {
            response.map((cat: any) => {
               if (claimsIds?.find((id) => id == cat.id)) claimCategories.push(cat.value);
               else if( claim === config.ITRConfiguratorView || claim === config.viewAllClaim) claimCategories.push(cat.value);
            });

            if (claimCategories.length === 3) claimCategories.push('All');
            this.setState({
               innerState: {
                  ...this.state.innerState,
                  availableCategories: claimCategories,
                  recordCategory: claimCategories[0],
               },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   onShowReports(dataItem: any) {
      let recordType = this.state.innerState.recordTypes.find((r) => r.id === dataItem.recordTypeId);

      if (recordType?.captureType === 'form') {
         const params = new URLSearchParams({
            producttype: dataItem.productTypeId,
            recordtype: dataItem.recordTypeId,
            record: dataItem.recordId,
            supplier: dataItem.supplierId,
            owner: dataItem.customerId
         });

         this.props.history.push(`/DataEntry?${params}`);
      } else {
         this.props.history.push(`/Records/${dataItem.recordId}`);
      }
   }

   changeCategory(e: any) {
      const category = e.target.text;
      let dataState = this.state.innerState.dataState;

      dataState.filter.filters.forEach((filterGroup: any) => {
         if (filterGroup.filters) filterGroup.filters = filterGroup.filters.filter((filter: any) => filter.field !== 'recordCategory');
      });

      dataState.filter.filters = dataState.filter.filters.filter((filterGroup: any) => filterGroup.filters.length > 0);

      const filter =
         category === 'All'
            ? undefined
            : {
               logic: 'and' as const,
               filters: [{ field: 'recordCategory', operator: 'eq', value: category }],
            };

      if (filter) dataState.filter.filters.push(filter);

      this.setState({
         innerState: {
            ...this.state.innerState,
            recordCategory: category,
            dataState,
         },
      });

      this.getRecords(dataState.skip / dataState.take + 1, dataState.take, dataState, category);
   }

   onSortChange(e: any) {
      const dataState = { ...this.state.innerState.dataState, sort: e.sort };

      this.setState({
         innerState: {
            ...this.state.innerState,
            dataState,
         },
      });

      this.getRecords(dataState.skip / dataState.take + 1, dataState.take, dataState, this.state.innerState.recordCategory);
   }

   onFilterChange(e: any) {
      const dataState = { ...this.state.innerState.dataState, take: 10, skip: 0, filter: e.filter || { filters: [] } };
      let filtering: boolean = false;
      let recordTypes: IRecordType[] = [];

      if(dataState.filter) {
         for (const filterGroup of dataState.filter.filters) {
            if (filterGroup.filters) {
               for (const filter of filterGroup.filters) {
                  if (filter.field === 'recordTypeName') {
                     filtering = true;
                     recordTypes.push(...(this.state.innerState.recordTypes.filter((e: IRecordType) => (e.name.includes(filter.value)))));
                  }
               }
            }
         }
      }

      this.setState({
         innerState: {
            ...this.state.innerState,
            dataState,
            exportedRecordTypes: filtering ? recordTypes : this.state.innerState.recordTypes
         },
      });

      this.getRecords(dataState.skip / dataState.take + 1, dataState.take, dataState, this.state.innerState.recordCategory);
   }

   // This is the function that is called when exporting to Excel.
   onExcelExport(filters: {field: string, value: string}[]) {
      const filterJson = { "filters" : filters};
      const exportUrl = `${config.apiGateway.DATA_API}/api/InstrumentTestRecord/ExcelExport/filter/${encodeURIComponent(JSON.stringify(filterJson))}`;
      this.get(exportUrl).then((response) => {
         this.setState({
            loading: false,
            innerState: {
               ...this.state.innerState,
               bannerMessage: {
                  show: true,
                  warn: false,
                  message: `Request logged, you will receive an email with the results.`,
                  error: false                  
               },
               exportDialog: false
            },
         });

         this.bannerTimer = setTimeout(() =>
         {
            this.setState({
               loading: false,
               innerState: {
                  ...this.state.innerState,
                  bannerMessage: {
                     show: false,
                     warn: false,
                     message: undefined,
                     error: false                  
                  },
               },
            });
         }, 5000);
      })
      .catch((error) => {
         this.handleApiError(error);
      });
   }

   render() {
      const records = this.state.innerState.records;
      const showRecords = this.state.innerState.recordCategory;
      const categories = this.state.innerState.availableCategories;
      const dataState = this.state.innerState.dataState;

      let showSerial = true;

      //if (dataState.filter) showSerial = dataState.filter.filters.find((filter: any) => filter.field === 'productSerialNumber');

      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <div className="title-container">
               <h1 className="page-header">Records</h1>
            </div>
            <RecordOptionsBar
               categories={categories}
               options=""
               handleChangeType={this.changeCategory.bind(this)}
               exportToExcel={()=>{
                  this.setState({
                     innerState: {
                        ...this.state.innerState,
                        exportDialog: true
                     },
                  });
               }}
            />
            <ExcelExportDialog APIGet={(url: string) => {
               return this.get(url).then((response) => {
                  this.setState({loading: false});
                  return response;
               });
            }} recordTypes={this.state.innerState.recordTypes} filters={this.state.innerState.dataState.filter.filters} filteredRecordTypes={this.state.innerState.exportedRecordTypes} open={this.state.innerState.exportDialog} onExport={this.onExcelExport.bind(this)} handleClose={()=>{this.setState({
                     innerState: {
                        ...this.state.innerState,
                        exportDialog: false
                     },
                  });}}/>
            <ExcelExport ref={(exporter) => (this._export = exporter)}>
               <Grid
                  className="mt-2"
                  pageable
                  sortable
                  data={records}
                  {...this.state.innerState.dataState}
                  onDataStateChange={(e) => {
                     this.setState({ innerState: { ...this.state.innerState, dataState: e.data } });
                  }}
                  onPageChange={(e: any) => {
                     this.getRecords(e.page.skip / e.page.take + 1, e.page.take, dataState, this.state.innerState.recordCategory);
                     this.setState({ innerState: { ...this.state.innerState, dataState: { ...dataState, skip: e.page.skip, take: e.page.take } } });
                  }}
                  onFilterChange={this.onFilterChange.bind(this)}
                  onSortChange={this.onSortChange.bind(this)}>
                  {showSerial ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="productSerialNumber"
                        title="Serial"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'serial'} />}
                     />
                  ) : null}
                  <Column
                     className="grid-overflow-fix text-truncate"
                     field="productTypeName"
                     title="Product Type"
                     columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                  />
                  {showRecords === 'All' || showRecords === 'Test' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="recordTypeName"
                        title="Record Type"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Test' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="testResult"
                        title="Result"
                        cell={this.statusCell}
                        width="80em"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'status'} />}
                     />
                  ) : null}
                  {showRecords === 'Test' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="supplierName"
                        title="Supplier"
                        width="auto"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Despatch' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="jobNumber"
                        title="Job No."
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Despatch' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="customerName"
                        title="Customer"
                        width="auto"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Problem Report' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="faultItems"
                        title="Fault Item"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Problem Report' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="repairItems"
                        title="Repair Item"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Problem Report' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="faultDescriptives"
                        title="Fault Descriptive"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  {showRecords === 'Problem Report' ? (
                     <Column
                        className="grid-overflow-fix text-truncate"
                        field="repairDescriptives"
                        title="Repair Descriptive"
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'text'} />}
                     />
                  ) : null}
                  <Column
                     className="grid-overflow-fix text-truncate"
                     field="recordDate"
                     title="Date"
                     filter={'date'}
                     //format="{0:dd/MM/yyyy}"
                     width="auto"
                     columnMenu={(p) => <GridSortFilterColumn {...p} data={records} expanded={true} custom={'date'} />}
                  />
                  <Column title="View" width="80em" cell={this.actionCell} filterable={false} />
               </Grid>
            </ExcelExport>
         </React.Fragment>
      );
   }
}

export default Records;
