import { updateById } from './Changes/UpdateById';

export const EDIT_FIELD = "inEdit";

export interface Editable {
  [EDIT_FIELD]: boolean;
}

export const setInEdit = (id: number | undefined | null) =>
  updateById(id, item => ({
    ...item,
    [EDIT_FIELD]: true
  }));