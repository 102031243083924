import * as React from 'react';
import { Component } from 'react';
import ApiResponseHandler from './ApiResponseHandler';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductStatusIcon from './ProductStatusIcon';
import Spinner from 'react-bootstrap/Spinner';
import config, { keys } from '../../config';
import IProduct from '../Model/IProduct';
import { SerialInput } from './SerialInput';

export interface SerialStatusCheckerProps {
   onChange: any;
   value?: any;
   disabled?: boolean;
   onSerialResult: any;
}

export interface SerialStatusCheckerState {
   productResult?: IProduct;
   loading: boolean;
   errorMessage: string;
}

class SerialStatusChecker extends ApiResponseHandler<SerialStatusCheckerProps, SerialStatusCheckerState> {
   constructor(props: SerialStatusCheckerProps) {
      super(props);
      this.state = { loading: false, redirect: false, innerState: { productResult: undefined, loading: false, errorMessage: '' } };
   }

   onSearch() {
      this.setState({ ...this.state, innerState: { ...this.state.innerState, loading: true } });

      let sortFilter = { serialNumbers: [this.props.value] };

      const productsUrl = config.apiGateway.META_API + `/api/assets/page/1/size/1/filter/${encodeURIComponent(JSON.stringify(sortFilter))}`;

      this.get(productsUrl)
         .then((response) => {
            let products = [];
            if (response) {
               products = response.result.map((productJson: any) => {
                  let lastRecordDate = productJson.attributes ? productJson.attributes.find((a: any) => a.key === 'DateOfLastRecord') : undefined;
                  let currentStatus = productJson.attributes ? productJson.attributes.find((a: any) => a.key === 'CurrentStatus') : undefined;

                  let product = {
                     productSerialNumber: productJson.serialNumber,
                     id: productJson.id,
                     productTypeId: productJson.assetModelId,
                     productTypeName: productJson.assetModel,
                     supplierid: productJson.assetManufacturerId,
                     supplierName: productJson.assetManufacturerName,
                     lastRecordDate:
                        lastRecordDate !== undefined
                           ? decodeURIComponent(lastRecordDate.value) // TODO: Depending on how the data is stored, this may need updating
                           : undefined,
                     currentStatus: currentStatus ? JSON.parse(currentStatus.value).Conclusion.toLowerCase() : 'n/a',
                     parent: -1,
                  };

                  return product;
               });
            }

            let result = products[0] ? products[0].currentStatus : 'n/a';
            this.props.onSerialResult(result);

            this.setState({
               ...this.state,
               innerState: {
                  ...this.state.innerState,
                  loading: false,
                  productResult: products[0],
                  errorMessage: products[0] ? '' : 'Could not find serial number.',
               },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         innerState: {
            ...this.state.innerState,
            loading: false,
         },
      });
   }

   onTextChange(e: any) {
      this.setState({
         innerState: {
            ...this.state.innerState,
            loading: false,
            productResult: undefined,
            errorMessage: '',
         },
      });

      this.props.onChange(e);
   }

   onShowReports() {
      const url = `/Records?filter=productSerialNumber&value=${this.state.innerState.productResult?.productSerialNumber}`;

      window.open(url, '_blank');
   }

   render() {
      return (
         <React.Fragment>
            <InputGroup>
               <SerialInput
                  as="input"
                  type="text"
                  onChange={this.onTextChange.bind(this)}
                  value={this.props.value === null ? '' : this.props.value}
                  disabled={this.props.disabled}
               />
               <InputGroup.Append>
                  <Button
                     variant={
                        this.state.innerState.loading
                           ? 'primary'
                           : !this.state.innerState.productResult
                           ? 'primary'
                           : this.state.innerState.productResult.currentStatus === 'pass'
                           ? 'success'
                           : this.state.innerState.productResult.currentStatus === 'fail'
                           ? 'danger'
                           : 'warning'
                     }
                     disabled={this.state.innerState.loading || this.state.innerState.productResult !== undefined || !this.props.value}
                     onClick={this.onSearch.bind(this)}>
                     {this.state.innerState.loading ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                     ) : this.state.innerState.productResult ? (
                        <ProductStatusIcon value={this.state.innerState.productResult.currentStatus} invert />
                     ) : (
                        <FontAwesomeIcon icon={'search'} />
                     )}
                  </Button>
                  <Button variant="primary" disabled={!this.state.innerState.productResult} onClick={this.onShowReports.bind(this)}>
                     <FontAwesomeIcon icon={'file-alt'} />
                  </Button>
               </InputGroup.Append>
            </InputGroup>
            <p className="sm text-muted m-1">{this.state.innerState.errorMessage}</p>
         </React.Fragment>
      );
   }
}

export default SerialStatusChecker;
