import { APIQuery } from '../Types';
import config from '../../../../config';
import TestEquipment, { CalibrationDetails } from '../../../Model/TestEquipment';
import APITestEquipment, { APICalibration } from '../../../Model/API/APITestEquipment';

const BASE_ADDRESS = `${config.apiGateway.ITR_API}/api/testEquipment`;

export default class TestEquipmentAPI {
  private readonly _query: APIQuery;

  constructor(query: APIQuery) {
    this._query = query;
  }

  public getBySerial = async (serialNumber: string): Promise<TestEquipment[]> => {
    const params = new URLSearchParams({ serialNumber });
    const url = `${BASE_ADDRESS}?${params}`;

    const response = await this._query(url, 'GET');
    return TestEquipmentAPI.handleGetResponse(response);
  };

  public getByProduct = async (productId: number) => {
    const url = `${config.apiGateway.ITR_API}/api/products/${productId}/testEquipment`;
    const response = await this._query(url, 'GET');
    return TestEquipmentAPI.handleGetResponse(response);
  };

  public async getAll() {
    const response = await this._query(BASE_ADDRESS, 'GET');
    return TestEquipmentAPI.handleGetResponse(response);
  }

  private static handleGetResponse(response: unknown): TestEquipment[] {
    if (!response || !Array.isArray(response) || response.length === 0)
      return [];

    return TestEquipmentAPI.mapResponse(response);
  }

  private static mapResponse(response: APITestEquipment[]): TestEquipment[] {
    return response.map(value => ({
      id: value.id,
      serialNumber: {
        prefix: value.serialNumberPrefix,
        value: value.serialNumber,
        full: (value.serialNumberPrefix ?? "") + value.serialNumber,
      },
      location: value.location ?? { id: -1, name: 'Unknown Location' },
      deviceOwner: value.owner,
      calibration: TestEquipmentAPI.mapCalibration(value.latestCalibration),
      description: value.description ?? "",
      assetTag: value.assetTag || "Unknown",
    }));
  }

  private static mapCalibration(calibration: APICalibration | undefined): CalibrationDetails | null {
    if (!calibration)
      return null;

    return calibration.skipped
      ? {
        skipped: true,
        date: new Date(calibration.date)
      }
      : {
        skipped: false,
        date: new Date(calibration.date),
        expires: new Date(calibration.expires),
        house: calibration.calibrator
      };
  }
}