import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { CustomDropdownList } from '../Shared/DropdownList';
import TestResultIcon from '../Shared/TestResultIcon';
import config from '../../config';

import './FormTestField.css';

interface TestResult {
    value: string;
    passOrFail: string;
}

export interface SelectionTestFieldProps {
    label: string;
    value: TestResult;
    choices?: string[];
    passChoice?: string;
    na?: boolean;
    onChange?: (e:any) => void;
    disabled?: boolean;
    criteria?: string;
    testNumber?: number;
}

const SelectionTestField = (props: SelectionTestFieldProps) => {

    const [passOrFail, setPassOrFail] = useState<string>('-');
    const [selected, setSelected] = useState<string>('');

    useEffect(() => {
        if(props.value.value) 
            setSelected(props.value.value);
        if (props.value.passOrFail)
            setPassOrFail(props.value.passOrFail);
    }, []);


    const choiceMatchesPass = (choice: string) => {
        return choice === props.passChoice;
    }


    const handleChange = (choice: any) => {
        let testResult = '-';
        if (choice) {
            if (props.na && choice === config.naTestResultText)
                testResult = config.naTestResultText;
            else if(choiceMatchesPass(choice))
                testResult = 'Pass';
            else
                testResult = 'Fail';
        }
        setPassOrFail(testResult);
        const testResultObj: TestResult = {value: choice, passOrFail:testResult};
        props.onChange?.(testResultObj);
        setSelected(choice);
    }

    const renderTestLabel = () => {
        const testNumString = `Test No. ${props.testNumber}: `;
        return props.testNumber != null
               ? <Form.Label>{testNumString}<br />{props.label}</Form.Label>
               : <Form.Label>{props.label}</Form.Label>;
    }
    
    const renderPassFailCriteria = () => {
        return (
            <div className="criteria-layout">
                {props.criteria
                 ? <p className="form-test-field-item">Pass criteria: {props.criteria}</p>
                 : null}
            </div>
        );
    }

    return (
        <React.Fragment>
            {renderTestLabel()}
            <div className="form-test-field">
                <div className="form-test-field-item w-100">
                    <CustomDropdownList
                        data={props.na ? (props.choices ? [...props.choices, config.naTestResultText] : [config.naTestResultText]) : props.choices}
                        onChange={handleChange}
                        defaultValue={props.value.value}
                        disabled={props.disabled}
                    />
                </div>
                <div className="ml-1 form-test-field-item">
                    <TestResultIcon
                        value={passOrFail}
                        size="2x"
                    />
                </div>
            </div>
            {renderPassFailCriteria()}
        </React.Fragment>
    );


}

export default SelectionTestField;