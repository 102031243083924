import { GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';

export default class GridFuzzyFilterColumn extends GridColumnMenuCheckboxFilter {
    handleSearchChange = (e: any) => {
        const filterExpression: CompositeFilterDescriptor = {
            logic: 'and',
            filters: [
                { field: this.props.column.field, operator: 'contains', value: e.target.value, ignoreCase: true }
            ]
        };
        this.setState({
            value: e.target.value,
            data: this.parseData(filterBy(this.props.data || [], filterExpression), this.props.uniqueData)
        });
    };
}