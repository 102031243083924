import { UploadedDocument } from '../../Model/UploadedDocument';
import { UploadedDocumentResponse } from '../../Model/API/UploadedDocumentResponse';
import { UploadDocumentTypes } from '../../Model/UploadDocumentType';
import { stripBase64Header } from './StripBase64Header';
import { fetchFormDocumentContent } from '../Data/UseFormDocumentContent';
import { DocumentUploadRequest } from '../../Model/API/DocumentUploadRequest';

export const parseUploadedDocument = (json: string): UploadedDocument => {
  const parsed: UploadedDocumentResponse = JSON.parse(json);
  return {
    documentType: UploadDocumentTypes[parsed.DocumentType],
    originalFilename: parsed.OriginalFilename,
    savedFilename: parsed.SavedFilename,
    contentType: parsed.ContentType,
  };
};

/**
 * Parse uploaded document which was stored with JSON metadata into another upload request.
 */
export const parseUploadedDocumentAsRequest = async (json: string): Promise<DocumentUploadRequest> => {
  const parsed = parseUploadedDocument(json);
  const content = await fetchFormDocumentContent(parsed.savedFilename);

  return {
    documentType: parsed.documentType,
    contentType: parsed.contentType,
    name: parsed.originalFilename,
    content: stripBase64Header(content)
  };
};
