import * as React from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { SerialInput } from '../Shared/SerialInput';

export default function Search(props: any) {

   const handleSubmit = (event: any) => {
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }
   };

   return (
      <Card className="mb-2">
         <Card.Body>
            <Card.Title className="text-center">{props.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{props.subTitle}</Card.Subtitle>
            <Form onSubmit={handleSubmit} action={props.target}>
               <Form.Control hidden readOnly type="input" name="filter" value="productSerialNumber" />
               <SerialInput required type="input" name="value" />
               <Button className="mt-2" variant="primary" type="submit">
                  Search
               </Button>
            </Form>
         </Card.Body>
      </Card>
   );
}
