import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IProductType from '../Model/IProductType';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import FormButtons from '../Shared/FormButtons';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import { CustomDropdownList } from '../Shared/DropdownList';
import IRecordType from '../Model/IRecordType';
import { GridActionsCell } from '../Shared/GridActionsCell';
import DropDownGridCell from '../DropDownGridCell';
import config, { newId, keys } from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import { getProductTypes } from '../Shared/Data/GetProductTypes';
import Attribute from '../Model/Attribute';

export interface ProductTypeProps extends RouteComponentProps<any> {
  id: string;
}

export interface ProductTypeState {
  productType: IProductType;
  productTypes: IProductType[];
  recordTypes: IRecordType[];
  lastRecordTypeId: number;
  dataState: any;
  bannerMessage: InfoMessage;
  originalMapping: any;
  hasRecordTypes: boolean;
  noSerialNumberPrefix: boolean;
}

const dataState = {
  take: 5,
  skip: 0,
};

class ProductType extends ApiResponseHandler<ProductTypeProps, ProductTypeState> {
  actionCell: any;
  recordTypeCell: any;

  constructor(props: ProductTypeProps) {
    super(props);
    this.state = {
      loading: true,
      redirect: false,
      innerState: {
        productTypes: [],
        dataState,
        bannerMessage: { message: '', show: false, warn: false, error: false },
        productType: { id: 0, name: '', recordTypes: [] },
        recordTypes: [],
        lastRecordTypeId: 0,
        originalMapping: {},
        hasRecordTypes: false,
        noSerialNumberPrefix: false
      },
    };

    this.actionCell = GridActionsCell({ onDelete: this.handleDeleteRecordProductType.bind(this) });
    this.recordTypeCell = (props: any) => (
      <DropDownGridCell {...props} list={this.state.innerState.recordTypes} handleChange={this.handleChangeRecordType.bind(this)} />
    );
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const mappingsUrl = config.apiGateway.META_API + '/api/AssetModels/ParentMappings/' + id;
    const recordTypesUrl = config.apiGateway.META_API + '/api/datapointtypes/';

    Promise.all<IProductType[], any, any>([
      getProductTypes(url => this.get(url)),
      this.get(recordTypesUrl),
      id === newId ? null : this.get(mappingsUrl),
    ])
      .then(([productTypes, recordTypesApiData, mappingsApiData]) => {
        let recordTypes = recordTypesApiData.map((recordTypeJson: any) => ({
          name: recordTypeJson.name,
          id: recordTypeJson.id,
        }));

        let mapping = ((id === newId) || mappingsApiData === undefined) ? undefined : mappingsApiData.find((x: any) => x !== undefined);
        let hasRecordTypes = false;
        let productType: IProductType = { id: 0, name: '', recordTypes: [] };

        if (id !== newId) {
          const productTypeId = Number(id);
          const existingProductType = productTypes.find(pt => pt.id === productTypeId);
          if (existingProductType == null)
            return Promise.reject('Product type does not exist');

          productType = existingProductType;
          productType.NoSerialNumberPrefix = productType.attributes?.find((a: Attribute) => a.key === keys.NoSerialNumberPrefixKey) !== undefined;

          const recordTypeAttribute = productType.attributes?.find((a: Attribute) => a.key === keys.recordTypeAttributeKey);
          productType.recordTypes =
            productType.attributes === undefined ||
            recordTypeAttribute === undefined
              ? []
              : JSON.parse(recordTypeAttribute.value);

          hasRecordTypes = !(
            productType.attributes === undefined ||
            productType.attributes.find((a: any) => a.key === keys.recordTypeAttributeKey) === undefined
          );
        }

        const lastId = this.state.innerState.productType.recordTypes === undefined ? 0 : this.state.innerState.productType.recordTypes.length;

        this.setState({
          loading: false,
          innerState: {
            ...this.state.innerState,
            productTypes,
            productType,
            recordTypes,
            noSerialNumberPrefix: productType.NoSerialNumberPrefix !== undefined && productType.NoSerialNumberPrefix,
            lastRecordTypeId: lastId,
            originalMapping: mapping,
            hasRecordTypes: hasRecordTypes,
          },
        });
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  handleApiError(error: string) {
    console.error(error);
    this.setState({
      loading: false,
      innerState: {
        ...this.state.innerState,
        bannerMessage: {
          show: true,
          warn: true,
          message: `API Error: ${error}`,
          error: true,
        },
      },
    });
  }

  handleButtonClick(e: any) {
    if (e.target.value === 'save') this.validateForm() && this.saveProductType();
    else this.props.history.push(`/ProductTypes`);
  }

  validateForm() {
    if (!this.state.innerState.productType.name) return this.setValidationError('You must give the product type a name.');

    try {
      this.state.innerState.productType.recordTypes.forEach((r) => {
        if (!r.name) throw 'You cannot select an empty record type.';
      });
    } catch (error) {
      return this.setValidationError(error);
    }

    return true;
  }

  setValidationError(errorMessage: string) {
    this.setState({
      loading: false,
      innerState: {
        ...this.state.innerState,
        bannerMessage: {
          show: true,
          warn: true,
          message: errorMessage,
          error: true,
        },
      },
    });

    return false;
  }

  saveProductType() {
    const productType = this.state.innerState.productType;
    const id = this.props.match.params.id;
    const productTypesUrl = config.apiGateway.META_API + '/api/assetmodels/';

    const productTypeBody = JSON.stringify({
      assetManufacturerId: 0,
      assetTypeKey: '',
      name: productType.name,
      description: 'description',
    });

    const mappingsUrl = config.apiGateway.META_API + '/api/assetmodels/' + productType.parent + '/child/assetmodels/';

    if (id === newId) {
      this.saveNewProductType(productTypesUrl, productTypeBody, productType, mappingsUrl);
    } else {
      this.saveExistingProductType(productTypesUrl, productTypeBody, mappingsUrl, productType);
    }
  }

  private saveExistingProductType(
    productTypesUrl: string,
    productTypeBody: string,
    mappingsUrl: string,
    productType: IProductType
  ) {
    let mappingPromise: Promise<any>, recordTypePromise: Promise<any>, noSerialNumberPrefixPromise: Promise<any>;

    this.put(productTypesUrl + productType.id, productTypeBody)
      .then(() => {
        if (this.state.innerState.originalMapping !== undefined) {
          this.delete(mappingsUrl + this.state.innerState.originalMapping.subjectId)
            .then(() => {
              if (productType.parent !== -1) {
                mappingPromise = this.post(mappingsUrl + productType.id);
              }
            })
            .catch((error) => {
              this.handleApiError(error);
            });
        }
      })
      .then(() => {
        noSerialNumberPrefixPromise = this.getNoSerialPrefixAttributePromise(productType.id);
        recordTypePromise = this.updateRecordTypes(productType.id);
        return Promise.all([mappingPromise, recordTypePromise, noSerialNumberPrefixPromise])
          .then(() => this.props.history.push(`/ProductTypes`));
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  private async getNoSerialPrefixAttributePromise(productTypeId: number) {
    let noSerialNumberPrefixPromise: Promise<any>;
    if (this.state.innerState.noSerialNumberPrefix && this.state.innerState.productType.NoSerialNumberPrefix) {
      noSerialNumberPrefixPromise = Promise.resolve(true);
    } else if (this.state.innerState.noSerialNumberPrefix && !this.state.innerState.productType.NoSerialNumberPrefix) {
      noSerialNumberPrefixPromise = this.addNoSerialNumberAttribute(productTypeId);
    } else if (this.state.innerState.productType.NoSerialNumberPrefix !== undefined && this.state.innerState.productType.NoSerialNumberPrefix) {
      noSerialNumberPrefixPromise = this.deleteNoSerialNumberAttribute(productTypeId);
    } else {
      noSerialNumberPrefixPromise = Promise.resolve(true);
    }

    return noSerialNumberPrefixPromise;
  }

  private saveNewProductType(
    productTypesUrl: string,
    productTypeBody: string,
    productType: IProductType,
    mappingsUrl: string
  ) {
    let mappingPromise: Promise<any>, recordTypePromise: Promise<any>, noSerialNumberPrefixPromise: Promise<any>;

    this.post(productTypesUrl, productTypeBody)
      .then((response: number) => {
        if (productType.parent !== -1) {
          mappingPromise = this.post(mappingsUrl + response);
        }
        recordTypePromise = this.updateRecordTypes(response);
        if (this.state.innerState.noSerialNumberPrefix) {
          noSerialNumberPrefixPromise = this.addNoSerialNumberAttribute(response);
        } else
          noSerialNumberPrefixPromise = Promise.resolve(true);
      })
      .then(() => {
        Promise.all([mappingPromise, recordTypePromise, noSerialNumberPrefixPromise]).then(() => {
          this.props.history.push(`/ProductTypes?success=true`);
        });
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  private updateRecordTypes(productTypeId: number): Promise<void> {
    const url = `${config.apiGateway.ITR_API}/api/ProductTypes/${productTypeId}/RecordTypes`;
    const assignedRecordTypeIds = this.state.innerState.productType.recordTypes.map(r => r.id);
    const body = JSON.stringify(assignedRecordTypeIds);
    return this.put(url, body);
  }

  private addNoSerialNumberAttribute(productTypeId: number): Promise<void> {
    const url = `${config.apiGateway.META_API}/api/AssetModels/Attributes/${productTypeId}`;
    const noSerialNumberAttributeBody = JSON.stringify({
      key: keys.NoSerialNumberPrefixKey,
      description: keys.NoSerialNumberPrefixKey,
      value: ''
    });

    return this.post(url, noSerialNumberAttributeBody);
  }

  private deleteNoSerialNumberAttribute(productTypeId: number): Promise<void> {
    const url = `${config.apiGateway.META_API}/api/AssetModels/Attributes/${productTypeId}/${keys.NoSerialNumberPrefixKey}`;
    return this.delete(url);
  }

  handleChangeName(e: any) {
    const { value } = e.target;
    let productType = this.state.innerState.productType;
    productType.name = value;
    this.setState({ innerState: { ...this.state.innerState, productType } });
  }

  handleChangeParentProductType(value: any) {
    let productType = this.state.innerState.productType;
    productType.parent = value.id;
    this.setState({ innerState: { ...this.state.innerState, productType } });
  }

  handleChangeHasParent(e: any) {
    let productType = this.state.innerState.productType;
    if (e.target.checked) {
      productType.parent = this.state.innerState.productTypes.filter((p) => p.id !== this.state.innerState.productType.id)[0].id;
    } else {
      productType.parent = -1;
    }
    this.setState({ innerState: { ...this.state.innerState, productType } });
  }

  handleChangeHasNoSerialPrefix(e: any) {
    let noSerialNumberPrefix = this.state.innerState.noSerialNumberPrefix;
    if (e.target.checked) {
      noSerialNumberPrefix = true;
    } else {
      noSerialNumberPrefix = false;
    }
    this.setState({ innerState: { ...this.state.innerState, noSerialNumberPrefix } });
  }

  private getAvailableParents(): IProductType[] {
    //TODO: make nested and make sure that any children of the current object cannot be used as a parent

    return this.state.innerState.productTypes.filter((p) => p.id !== this.state.innerState.productType.id);
  }

  handleDeleteRecordProductType(value: any) {
    const productType = this.state.innerState.productType;
    productType.recordTypes = productType.recordTypes.filter((r) => r.id !== value.id);
    this.setState({ innerState: { ...this.state.innerState, productType } });
  }

  handleAddRecordType() {
    const productType = this.state.innerState.productType;
    const lastId = this.state.innerState.lastRecordTypeId + 1;
    productType.recordTypes.push({ id: lastId, name: '', captureType: '', category: '' });
    this.setState({ innerState: { ...this.state.innerState, productType, lastRecordTypeId: lastId } });
  }

  handleChangeRecordType(id: number, recordType: IRecordType) {
    let productType = this.state.innerState.productType;

    const newRecordTypes = productType.recordTypes.map((r: { id: number }) =>
      r.id === id ? { ...r, id: recordType.id, name: recordType.name } : r
    ) as IRecordType[];

    productType.recordTypes = newRecordTypes;

    this.setState({ innerState: { ...this.state.innerState, productType } });
  }

  render() {
    return (
      <React.Fragment>
        <InfoBanner message={this.state.innerState.bannerMessage} />
        <BusyOverlay show={this.state.loading} />
        <div className="title-container">
          <h1 className="page-header">{this.props.match.params.id === newId ? 'Create New' : 'Edit'} Product Type</h1>
        </div>
        <Form>
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="productTypeName"
            required={true}
            value={this.state.innerState.productType.name}
            type="text"
            placeholder="Enter product type name"
            onChange={this.handleChangeName.bind(this)}
          />
          <Form.Text className="sm text-muted m-1">ID:{this.state.innerState.productType.id}</Form.Text>
          <Form.Group className="m-3">
            <Form.Check
              className="mb-2"
              id="hasParent"
              type="checkbox"
              label="Is this a sub component?"
              checked={this.state.innerState.productType.parent !== -1}
              onChange={this.handleChangeHasParent.bind(this)}
            />
            {this.state.innerState.productType.parent !== -1 ? (
              <Form.Group>
                <Form.Label>Parent</Form.Label>
                <CustomDropdownList
                  data={this.getAvailableParents()}
                  defaultValue={
                    this.state.innerState.productTypes.find((t: { id: number }) => t.id == this.state.innerState.productType.parent)?.name
                  }
                  onChange={this.handleChangeParentProductType.bind(this)}
                />
              </Form.Group>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group className="m-3">
            <Form.Check
              className="mb-2"
              id="noPrefix"
              type="checkbox"
              label="Product type missing serial number prefix"
              checked={this.state.innerState.noSerialNumberPrefix}
              onChange={this.handleChangeHasNoSerialPrefix.bind(this)}
            />
          </Form.Group>
          <Form.Group className="m-3">
            <Form.Label>Record Types</Form.Label>
            <Grid
              pageable
              sortable
              data={process(this.state.innerState.productType.recordTypes, this.state.innerState.dataState)}
              {...this.state.innerState.dataState}
              onDataStateChange={(e) => {
                this.setState({ innerState: { ...this.state.innerState, dataState: e.data } });
              }}>
              <Column
                field="name"
                title="Name"
                cell={this.recordTypeCell}
                columnMenu={(p) => <GridSortFilterColumn {...p} data={this.state.innerState.productType.recordTypes} expanded={true} />}
              />
              <Column title="Actions" width="100em" cell={this.actionCell} filterable={false} />
            </Grid>
            <Button className="mt-3" variant="primary" onClick={() => this.handleAddRecordType()}>
              Add new record type
            </Button>
          </Form.Group>
          <FormButtons handleButtonClick={this.handleButtonClick.bind(this)} />
        </Form>
      </React.Fragment>
    );
  }
}

export default withRouter(ProductType);
