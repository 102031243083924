import React from 'react';
import TestEquipment from '../../Model/TestEquipment';
import { useCalibrationExpiry } from '../../DataEntry/TestEquipment/CalibrationCards/Hooks/UseCalibrationExpiry';

interface CalibrationSummaryProps {
  testEquipment: TestEquipment;
}

export const CalibrationSummary: React.FC<CalibrationSummaryProps> = ({ testEquipment }) => {
  const { calibration } = testEquipment;

  const expiry = !calibration || calibration.skipped
    ? new Date()
    : calibration.expires;

  const { expired, validityDuration } = useCalibrationExpiry(expiry);

  if (!calibration)
    return <p>This device has not been calibrated</p>;

  if (calibration.skipped)
    return <p>Calibration is not required for this device</p>;

  return (
    <div>
      <p>Last calibrated on {calibration.date.toLocaleDateString()} by {calibration.house.name}.</p>

      {
        expired
          ? <p>This device needs to be recalibrated.</p>
          : <p>Requires recalibration on {calibration.expires.toLocaleDateString()} ({validityDuration.humanize()} from now).</p>
      }
    </div>
  );
};