import * as React from 'react';

export default function CustomTextFilter(props: any) {
   const onChange = (event: any) => {
      const value = event.target.value;
      const { firstFilterProps } = props;

      firstFilterProps.onChange({
         value,
         operator: 'eq',
         syntheticEvent: event.syntheticEvent,
      });
   };

   return (
      <div>
         <input type="text" onChange={onChange} value={props.firstFilterProps.value || ''} />
      </div>
   );
}
