import config from '../../../../config';
import ISurveyRepairType, { SurveyRepairConfigurationDomain, SurveyRepairDomainDescriptions } from '../../../Model/ISurveyRepairType';
import { APIQuery, HTTPMethod } from '../Types';

/**
 * API client for survey/repair descriptive endpoints.
 */
export class SurveyRepairDescriptiveAPI {
  private readonly _query: APIQuery;
  private readonly _descriptivesURL = config.apiGateway.META_API + '/api/Configuration/';

  public constructor(query: APIQuery) {
    this._query = query;
  }

  public saveDescriptive = (isNew: boolean, surveyRepairType: ISurveyRepairType): Promise<void> => {
    if (!surveyRepairType.faultRepair)
      return Promise.reject('Invalid survey/repair category');

    const domain = SurveyRepairConfigurationDomain[surveyRepairType.faultRepair];
    const description = SurveyRepairDomainDescriptions[domain];
    const faultRepairTypeJson = {
      domain: domain,
      key: surveyRepairType.name,
      value: surveyRepairType.name,
      description: description,
      shortDescription: description
    };

    return isNew
      ? this.saveNewDescriptive(faultRepairTypeJson)
      : this.saveExistingDescriptive(faultRepairTypeJson, surveyRepairType.id);
  };

  public deleteDescriptive = (surveyRepairType: ISurveyRepairType): Promise<void> => {
    if (!surveyRepairType.faultRepair)
      return Promise.reject('Invalid survey/repair category');

    const domain: string = SurveyRepairConfigurationDomain[surveyRepairType.faultRepair];
    const body: string = JSON.stringify({
      domain: domain,
      id: surveyRepairType.id,
      key: surveyRepairType.name,
      value: surveyRepairType.name
    });

    return this.queryDescriptives('DELETE', body);
  };

  private saveNewDescriptive = (descJson: any) => {
    const body: string = JSON.stringify(descJson);
    return this.queryDescriptives('POST', body);
  };

  private saveExistingDescriptive = (descJson: any, id: number) => {
    descJson.id = id;
    const body: string = JSON.stringify(descJson);
    return this.queryDescriptives('PUT', body);
  };

  private queryDescriptives = (method: HTTPMethod, body?: string) =>
    this._query(this._descriptivesURL, method, body);
}
