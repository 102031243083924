import { Form } from 'react-bootstrap';
import { CustomDropdownList } from '../../Shared/DropdownList';
import * as React from 'react';
import { RecordTypeMode } from './Types';
import ISupplier from '../../Model/ISupplier';
import { Owner } from '../../Model/Owner';

interface SupplierOwnerDropdown {
  currentMode: RecordTypeMode;
  suppliers: ISupplier[];
  supplier: ISupplier;
  onSupplierChange: (value: ISupplier) => void;
  owners: Owner[];
  owner: Owner | undefined;
  onOwnerChange: (value: Owner) => void;
}

export function SupplierOwnerDropdown({ currentMode, suppliers, supplier, onSupplierChange, owners, owner, onOwnerChange }: SupplierOwnerDropdown) {
  switch (currentMode) {
    case 'Standard':
      return (
        <div>
          <Form.Label>Supplier</Form.Label>
          <CustomDropdownList
            data={suppliers}
            onChange={onSupplierChange}
            defaultValue={
              supplier.id === -1
                ? ''
                : suppliers.find((t: { id: number }) => t.id === supplier.id)?.name
            }
          />
        </div>
      );
    case 'Calibration':
      return (
        <div>
          <Form.Label>Owner</Form.Label>
          <CustomDropdownList
            data={owners}
            onChange={onOwnerChange}
            defaultValue={
              owner
                ? owners.find(o => o.id === owner.id)?.name
                : ''
            }
          />
        </div>
      );
  }
}