import * as React from 'react';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { createErrorInfoMessage, InfoBanner, InfoMessage } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { CustomDropdownList } from '../Shared/DropdownList';
import IProductType from '../Model/IProductType';
import IRecordType from '../Model/IRecordType';
import config, { productTypeId, keys } from '../../config';
import { RouteComponentProps } from 'react-router-dom';
import ResultSummary from './ResultSummary';
import ISupplier from '../Model/ISupplier';
import DynamicUpload from '../Shared/FileUploader/DynamicUpload';
import { JwtManager } from '../Shared/JwtManager';
import { getOwners } from '../Shared/Data/Owners/GetOwners';
import { Owner } from '../Model/Owner';
import { ModeSwitch } from '../Shared/ModeSwitch/ModeSwitch';
import { nameSorter } from '../Shared/Utils/FieldSorter';
import { RECORD_TYPE_MODES, RecordTypeMode } from './AddTestRecord/Types';
import { SupplierOwnerDropdown } from './AddTestRecord/SupplierOwnerDropdown';
import { getProductTypes } from '../Shared/Data/GetProductTypes';

export interface AddNewTestRecordProps extends RouteComponentProps<any> {
}

const tokenManager = new JwtManager({});

export interface AddNewTestRecordState {
  recordTypeMode: RecordTypeMode;
  bannerMessage: InfoMessage;
  productType: IProductType;
  recordType: IRecordType;
  productTypes: IProductType[];
  recordTypes: IRecordType[];
  suppliers: ISupplier[];
  supplier: ISupplier;
  owners: Owner[];
  owner: Owner | undefined;
  dataCapture: 'form' | 'file' | 'both' | undefined;
  hasFormLayout: boolean;
  apiResult: string | undefined;
  supplierView: boolean;
}

const productTypesUrl = config.apiGateway.META_API + '/api/assetmodels/';
const recordTypesUrl = config.apiGateway.META_API + '/api/datapointtypes/';
const suppliersDataUrl = config.apiGateway.META_API + '/api/assetmanufacturers/';
const supplierProductAttributeKey = 'SupplierProducts';

class AddNewTestRecord extends ApiResponseHandler<AddNewTestRecordProps, AddNewTestRecordState> {
  initialRecordType = { id: -1, name: '', captureType: '', category: '' };
  initialProductType = { id: -1, name: '', recordTypes: [] };

  constructor(props: AddNewTestRecordProps) {
    super(props);

    const supplierId = parseInt(tokenManager.getSupplierID() || '-1');

    this.state = {
      loading: true,
      redirect: false,
      innerState: {
        recordTypeMode: 'Standard',
        bannerMessage: { message: '', show: false, warn: false, error: false },
        productType: this.initialProductType,
        recordType: this.initialRecordType,
        supplier: { id: supplierId, name: '', products: [] },
        productTypes: [],
        recordTypes: [],
        suppliers: [],
        owners: [],
        owner: undefined,
        dataCapture: undefined,
        hasFormLayout: false,
        apiResult: undefined,
        supplierView: supplierId !== -1,
      },
    };

    this.get = this.get.bind(this);
  }

  componentDidMount() {
    if (this.state.innerState.supplierView) {
      this.getProductTypes(this.state.innerState.supplier.id);
    } else {
      this.getSupplierOwnerData();
    }
  }

  private getSupplierOwnerData = (): void => {
    const { recordTypeMode, suppliers, owners } = this.state.innerState;

    let getDataPromise = Promise.resolve();

    if (recordTypeMode === 'Standard' && suppliers.length === 0) {
      getDataPromise = this.get(suppliersDataUrl)
        .then(suppliersResponse => {
          if (!suppliersResponse)
            return;

          const suppliers: ISupplier[] = suppliersResponse.map((supplierJson: any) => ({
            name: supplierJson.name,
            id: supplierJson.id,
            products: [],
          }));
          this.setState({ loading: false, innerState: { ...this.state.innerState, suppliers } });
        });
    } else if (recordTypeMode === 'Calibration' && owners.length === 0) {
      getDataPromise = getOwners(this.get)
        .then(owners => {
          const sortedOwners = owners.sort(nameSorter);
          this.setState({ loading: false, innerState: { ...this.state.innerState, owners: sortedOwners } });
        })
        .then(() => this.loadOwnerProductTypes());
    }

    getDataPromise.catch(this.handleApiError);
  };

  private loadOwnerProductTypes = (): Promise<void> =>
    getProductTypes(this.get)
      .then(productTypes => {
        // Set product type to Test Equipment when submitting calibration records
        const testEquipment = productTypes.find(pt => pt.id === productTypeId.testEquipment);
        if (!testEquipment) {
          this.setState(prevState => ({
            loading: false,
            innerState: {
              ...prevState.innerState,
              bannerMessage: createErrorInfoMessage('Unable to load test equipment product type'),
            }
          }));

          return;
        }

        this.setState(prevState => ({
          loading: false,
          innerState: {
            ...prevState.innerState,
            productTypes: productTypes,
            productType: testEquipment
          }
        }));

        this.handleChangeProductType(testEquipment);
      });

  handleApiError(error: string) {
    console.error(error);
    this.setState({
      loading: false,
      innerState: {
        ...this.state.innerState,
        bannerMessage: {
          show: true,
          warn: true,
          message: `API Error: ${error}`,
          error: true,
        },
      },
    });
  }

  handleChangeSupplier(value: any) {
    this.setState({
      loading: true,
      innerState: {
        ...this.state.innerState,
        supplier: value,
        recordTypes: [],
        recordType: this.initialRecordType,
        productType: this.initialProductType,
      },
    });

    this.getProductTypes(value.id);
  }

  private handleChangeOwner = (value: Owner) => {
    this.setInnerState(prevInnerState => ({
      ...prevInnerState,
      owner: value,
      dataCapture: undefined,
    }));
  };

  private handleChangeMode = (mode: RecordTypeMode) =>
    this.setInnerState(prevInnerState => ({
        ...prevInnerState,
        recordTypeMode: mode,
      }),
      () => this.getSupplierOwnerData()
    );

  private getProductTypes(supplierId: any) {
    let productTypes = [] as IProductType[];
    let bannerMessage = {
      show: false,
      warn: false,
      message: '',
      error: false,
    };

    this.get(suppliersDataUrl + supplierId)
      .then((response) => {
        if (response.attributes.length > 0) {
          productTypes = JSON.parse(response.attributes.find((a: any) => a.key === supplierProductAttributeKey).value);
        } else {
          bannerMessage = {
            show: true,
            warn: true,
            message: 'The selected supplier does not have any associated product types',
            error: false,
          };
        }

        this.setState({
          loading: false,
          innerState: {
            ...this.state.innerState,
            productTypes,
            bannerMessage,
            dataCapture: undefined,
          },
        });
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  handleChangeProductType(value: any) {
    this.setState({
      loading: true,
      innerState: {
        ...this.state.innerState,
        productType: value,
        recordTypes: [],
        recordType: this.initialRecordType,
      },
    });
    let recordTypes = [] as IRecordType[];
    let bannerMessage = {
      show: false,
      warn: false,
      message: '',
      error: false,
    };
    this.get(productTypesUrl + value.id)
      .then((response) => {
        if (response.attributes.length > 0) {
          const productAttributes = response.attributes.filter((a: any) => a.key === keys.recordTypeAttributeKey);
          recordTypes = JSON.parse(productAttributes[0].value).map((json: any) => ({
            name: json.name,
            id: json.id,
          }));
        } else {
          bannerMessage = {
            show: true,
            warn: true,
            message: 'The selected product type does not have any associated record types',
            error: false,
          };
        }

        this.setState({
          loading: false,
          innerState: {
            ...this.state.innerState,
            recordTypes,
            bannerMessage,
            dataCapture: undefined,
          },
        });
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  handleChangeRecordType(value: any) {
    let bannerMessage = {
      show: false,
      warn: false,
      message: '',
      error: false,
    };

    this.get(recordTypesUrl + value.id)
      .then((response) => {
        let dataCapture = undefined;
        let formLayout = undefined;

        if (response) {
          dataCapture = response.valueType;
          formLayout = response.attributes.find((a: any) => a.key === keys.dataEntryFormAttributeKey);

          if (formLayout === undefined && (dataCapture === 'form' || dataCapture === 'both')) {
            bannerMessage = {
              show: true,
              warn: true,
              message: 'Selected record type does not have an associated data entry form.',
              error: false,
            };
          }
        }

        this.setState({
          loading: false,
          innerState: { ...this.state.innerState, bannerMessage, recordType: value, dataCapture, hasFormLayout: formLayout !== undefined },
        });
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  handleButtonClick() {
    const { productType, recordType, supplier, owner } = this.state.innerState;

    const params = new URLSearchParams({
      producttype: productType.id.toString(),
      recordtype: recordType.id.toString(),
    });

    if (supplier.id > 0)
      params.append('supplier', supplier.id.toString());

    if (owner)
      params.append('owner', owner.id.toString());

    this.props.history.push(`/DataEntry?${params}`);
  }

  showFileUpload(): boolean {
    let state = this.state.innerState;

    return state.productType.id !== -1 && state.recordType.id !== -1 && (state.dataCapture === 'file' || state.dataCapture === 'both');
  }

  showDataEntryButton(): boolean {
    let state = this.state.innerState;

    return (
      state.productType.id !== -1 &&
      state.recordType.id !== -1 &&
      (state.dataCapture === 'form' || state.dataCapture === 'both') &&
      state.hasFormLayout
    );
  }

  onfileUpload(files: any) {
    const submissionUrl = config.apiGateway.SUBMISSION_API + '/api/InstrumentTest/upload-csv/';

    const body = JSON.stringify({
      supplierId: this.state.innerState.supplier.id,
      productTypeId: this.state.innerState.productType.id,
      recordTypeId: this.state.innerState.recordType.id,
      fileName: files[0].name,
      data: files[0].base64.split(',')[1],
    });

    this.post(submissionUrl, body)
      .then(() => {
        this.setState({
          loading: false,
          innerState: {
            ...this.state.innerState,
            apiResult: 'File uploaded successfully. You will be notified once the file is proccessed.',
          },
        });
      })
      .catch((error) => {
        this.handleApiError(error);
      });
  }

  handleSummaryButtonClick(e: any) {
    let value = e.target.value;

    if (value === 'duplicate') {
      this.setState({
        loading: false,
        innerState: {
          ...this.state.innerState,
          apiResult: undefined,
        },
      });
    } else if (value === 'new') {
      this.setState({
        loading: false,
        innerState: {
          ...this.state.innerState,
          productType: { id: -1, name: '', recordTypes: [] },
          recordType: { id: -1, name: '', captureType: '', category: '' },
          recordTypes: [],
          dataCapture: undefined,
          hasFormLayout: false,
          apiResult: undefined,
        },
      });
    } else if (value === 'finish') {
      this.props.history.push(`/Home`);
    }
  }

  render() {
    const {
      supplierView,
      recordTypeMode,
      owners,
      owner,
      suppliers,
      supplier
    } = this.state.innerState;

    return this.state.innerState.apiResult !== undefined ? (
      <ResultSummary
        result={this.state.innerState.apiResult}
        productType={this.state.innerState.productType}
        recordType={this.state.innerState.recordType}
        handleButtonClick={this.handleSummaryButtonClick.bind(this)}
      />
    ) : (
      <React.Fragment>
        <InfoBanner message={this.state.innerState.bannerMessage} />
        <BusyOverlay show={this.state.loading} />
        <div className="title-container">
          <h1 className="page-header">Add New Record</h1>
        </div>
        <Form>
          {!supplierView ? (
            <React.Fragment>
              <ModeSwitch
                label="Category"
                modes={RECORD_TYPE_MODES}
                currentMode={recordTypeMode}
                onModeChange={this.handleChangeMode.bind(this)}
              />
              <SupplierOwnerDropdown
                currentMode={recordTypeMode}
                suppliers={suppliers}
                supplier={supplier}
                onSupplierChange={this.handleChangeSupplier.bind(this)}
                owners={owners}
                owner={owner}
                onOwnerChange={this.handleChangeOwner.bind(this)}
              />
            </React.Fragment>
          ) : null}

          {
            recordTypeMode === 'Calibration'
              ? null
              : (
                <>
                  <Form.Label className="mt-2">Product Type</Form.Label>
                  <CustomDropdownList
                    data={this.state.innerState.productTypes}
                    onChange={this.handleChangeProductType.bind(this)}
                    defaultValue={
                      this.state.innerState.productType.id === -1
                        ? ''
                        : this.state.innerState.productTypes.find((t: { id: number }) => t.id === this.state.innerState.productType.id)?.name
                    }
                  />
                </>
              )
          }
          <div>
            <Form.Label className="mt-2">Record Type</Form.Label>
            <CustomDropdownList
              data={this.state.innerState.recordTypes}
              onChange={this.handleChangeRecordType.bind(this)}
              defaultValue={
                this.state.innerState.recordType.id === -1
                  ? ''
                  : this.state.innerState.recordTypes.find((t: { id: number }) => t.id === this.state.innerState.recordType.id)?.name
              }
            />
          </div>
          <Container>
            {this.showFileUpload() ? (
              <Row className="mt-5 justify-content-center">
                <Col lg="5">
                  <DynamicUpload
                    multiple={false}
                    name="fileUpload"
                    label="Upload Files"
                    accept={'.csv'}
                    callBack={this.onfileUpload.bind(this)}
                  />
                </Col>
              </Row>
            ) : null}
            {this.showDataEntryButton() ? (
              <Row className="mt-5 justify-content-center">
                <Col xs="auto">
                  <Button variant="primary" onClick={this.handleButtonClick.bind(this)}>
                    Enter Data
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Form>
      </React.Fragment>
    );
  }
}

export default AddNewTestRecord;
