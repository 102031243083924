import * as React from "react";
import { Alert, Button, ButtonGroup, Collapse } from "react-bootstrap";
import { TransitionGroup } from "react-transition-group";

import { LoadingButton } from "../LoadingButton";

import styles from "./ViewUploadedDocument.module.css";

interface ViewUploadedDocumentContentsProps {
  loading: boolean;
  error: string | undefined;
  savedFilename: string;
  onView: () => void;
  onDownload: () => void;
}

export function ViewUploadedDocumentContents(props: ViewUploadedDocumentContentsProps) {
  const { error, onDownload, onView, loading, savedFilename } = props;

  if (error)
    return (
      <Alert variant="danger" className={styles.documentErrorPadding}>
        {error}
      </Alert>
    );

  if (loading)
    return (
      <Collapse key={`loading-${savedFilename}-document`}>
        <LoadingButton/>
      </Collapse>
    );

  return (
    <TransitionGroup>
      <Collapse key={`${savedFilename}-document-actions`}>
        <ButtonGroup>
          <Button variant="primary" onClick={onView}>View</Button>
          <Button variant="primary" onClick={onDownload}>Download</Button>
        </ButtonGroup>
      </Collapse>
    </TransitionGroup>
  );
}