import { Badge } from 'react-bootstrap';
import React from 'react';
import { nameSimilarityThresholds } from '../../config';
import styles from "./SimilarNameBadge.module.css";

interface SimilarNameBadgeProps {
  name: string;
  similarity: number;
}

export function SimilarNameBadge({ name, similarity }: SimilarNameBadgeProps) {
  const variant = similarity >= nameSimilarityThresholds.danger
    ? 'danger'
    : 'warning';

  return (
    <Badge variant={variant} className={styles.badgeText}>{name}</Badge>
  );
}