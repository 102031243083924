import { useCallback } from 'react';

import { ChangeCallback } from './ChangeCallback';
import { HasId } from '../Types';

/**
 * Add/edit/delete callbacks for components which manage editable items.
 * @param existingItems Existing items
 * @param onChange Callback to invoke when a change occurs
 * @param createNewItem Callback to create a new default item
 * @param setEditId Callback to set the ID of the item under edit
 */
export function useChangeCallbacks<T extends HasId>(existingItems: T[], onChange: ChangeCallback<T>, createNewItem: () => T, setEditId: (id: number) => void) {
  const handleItemAdd = useCallback(
    () => {
      const newItem = createNewItem();
      onChange('add', newItem);
      setEditId(newItem.id);
    },
    [onChange, createNewItem]
  );

  const handleItemEdit = useCallback(
    (itemToEdit: T) => setEditId(itemToEdit.id),
    [setEditId]
  );

  const handleItemDelete = useCallback(
    (itemToDelete: T) => onChange('delete', itemToDelete),
    [onChange]
  );

  return [
    handleItemAdd,
    handleItemEdit,
    handleItemDelete,
  ] as const;
}