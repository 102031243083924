import React, { ChangeEventHandler } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSerialNumberScanner } from './Hooks';
import { parseUnknownSerialNumber } from '../../../Model/AssetQRCode';

interface AddTestEquipmentSerialProps {
  value: string;
  onChange: (serialNumber: string) => void;
  onAdd: (serialNumber: string) => void;
}

export function AddTestEquipmentSerial({ value, onChange, onAdd }: AddTestEquipmentSerialProps) {
  const { SerialNumberScanner, startScanner } = useSerialNumberScanner(onAdd);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => onChange(event.target.value);
  const handleAdd = () => {
    const serialNumber = parseUnknownSerialNumber(value);
    onAdd(serialNumber);
  };

  return (
    <>
      <SerialNumberScanner />

      <InputGroup className="mt-3" style={{ maxWidth: '480px' }}>
        <FormControl
          type="text"
          placeholder="Test Equipment ID Number"
          value={value}
          onChange={handleChange}
        />
        <Button variant="outline-primary" onClick={handleAdd} disabled={!value}>
          <FontAwesomeIcon icon="plus" className="mr-1" />
          Add
        </Button>
        <Button variant="outline-primary" onClick={startScanner}>
          <FontAwesomeIcon icon="barcode" className="mr-1" />
          Scan
        </Button>
      </InputGroup>
    </>
  );
}