const NEW_CALIBRATOR_ID = 0;

export class Calibrator {
  id: number;
  name: string;

  public constructor(id?: number, name?: string) {
    this.id = id ?? NEW_CALIBRATOR_ID;
    this.name = name ?? '';
  }

  public isNew = () => this.id === NEW_CALIBRATOR_ID;
}