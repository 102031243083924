import React from 'react';
import Routes from './routes';
import {
  faHome,
  faTrashAlt,
  faBoxes,
  faEdit,
  faSave,
  faProjectDiagram,
  faSyncAlt,
  faCube,
  faCheckCircle,
  faTimesCircle,
  faMinusCircle,
  faFilter,
  faFileAlt,
  faCloudUploadAlt,
  faClipboardList,
  faQuestionCircle,
  faSearch,
  faTools,
  faWarehouse,
  faLaptopHouse,
  faTimes,
  faPlus,
  faBarcode,
  faVial,
  faSlash,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { JwtManager } from './components/Shared/JwtManager';
import { SplashScreen } from './components/SplashScreen/SplashScreen';
import { LoginModal } from './components/Login/LoginModal';
import config from './config';

library.add(
  faHome,
  faTrashAlt,
  faBoxes,
  faEdit,
  faSave,
  faProjectDiagram,
  faSyncAlt,
  faCube,
  faCheckCircle,
  faTimesCircle,
  faMinusCircle,
  faFilter,
  faFileAlt,
  faCloudUploadAlt,
  faClipboardList,
  faQuestionCircle,
  faSearch,
  faTools,
  faWarehouse,
  faLaptopHouse,
  faTimes,
  faPlus,
  faBarcode,
  faVial,
  faCircle,
  faSlash,
);

var tokenManager = new JwtManager({});
const token = tokenManager.getToken();
const tokenExpires = tokenManager.getExpires();
const claim = tokenManager.getClaims();
let route = window.location.pathname;
route = route.toLowerCase();
const mainRoute = route.split('/')[1];

const App: React.FC = () => {
  if (token === null || (tokenExpires !== null && tokenExpires < new Date())) {
    return (
      <SplashScreen>
        <LoginModal showLoginModal={true} projectTitle="ITR Login"/>
      </SplashScreen>
    );
  } else if (mainRoute !== '' && ((claim === config.externalSupplierView && !config.externalUserUrl.includes(mainRoute)) || (claim === config.ITRInternalUserView && !config.internalUserUrl.includes(mainRoute)))) {
    return (<div><p>You are not authorised to see this page</p></div>);
  } else {
    return <Routes/>;
  }
};

export default App;
