import config from "../../../config";
import IProductType from "../../Model/IProductType";
import ApiProductType from "../../Model/API/ApiProductType";

export async function getProductTypes(get: (address: string) => Promise<any>): Promise<IProductType[]> {
  const productTypesUrl = config.apiGateway.ITR_API + '/api/productTypes';
  const response = await get(productTypesUrl);
  if (!response || !Array.isArray(response))
    return [];

  return response.map<IProductType>((productType: ApiProductType) => ({
    name: productType.name,
    id: productType.id,
    recordTypes: [],
    parent: productType.parent?.id ?? -1,
    parentName: productType.parent?.name,
    attributes: productType.attributes
  }));
}
