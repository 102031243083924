import styles from './TestEquipmentCard.module.css';

export type CardVariant = 'valid' | 'warning' | 'danger' | 'info';

type VariantClasses = Record<CardVariant, string>;

export const TITLE_CLASSES: VariantClasses = {
  valid: 'text-success',
  info: 'text-info',
  warning: styles.warningTitle,
  danger: styles.dangerTitle,
};

export const BACKGROUND_CLASSES: VariantClasses = {
  valid: 'bg-white',
  info: 'bg-white',
  warning: 'bg-white',
  danger: styles.dangerBackground,
};

export const BORDER_CLASSES: VariantClasses = {
  valid: 'border-success',
  info: 'border-info',
  warning: styles.borderWarning,
  danger: styles.borderDanger,
};
