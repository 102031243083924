import EALogo from '../../../images/EABlack.svg';
import React, { CSSProperties, forwardRef } from 'react';
import { PrefixedSerialNumber } from '../../Model/TestEquipment';
import QRCode from 'qrcode.react';

interface TestEquipmentLabelProps {
  serialNumber: PrefixedSerialNumber;
  qrCodePayload: string;
}

const LABEL_STYLE: CSSProperties = {
  width: '80mm',
  height: '20mm',
  display: 'flex',
  justifyContent: 'space-around',
  border: '1px solid black',
};

const CONTAINER_STYLE: CSSProperties = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

const LOGO_STYLE: CSSProperties = {
  height: '10mm',
};

export const TestEquipmentLabel = forwardRef<HTMLDivElement, TestEquipmentLabelProps>((props, ref) => {
  const { serialNumber, qrCodePayload } = props;

  return (
    <div ref={ref} style={LABEL_STYLE}>
      <div style={CONTAINER_STYLE}>
        <img src={EALogo} alt="EA Technology Logo" style={LOGO_STYLE} />
        <span>{serialNumber.full}</span>
      </div>

      <div style={CONTAINER_STYLE}>
        <QRCode value={qrCodePayload} size={60} renderAs="svg" />
      </div>
    </div>
  );
});
