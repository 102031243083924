import React from "react";
import ExcelDatePicker from "./ExcelDatePicker";
import { ListItem, ListItemText, ListItemIcon, Checkbox, ListItemProps } from "@material-ui/core";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";


interface DateProps {
    enabled?: boolean;
    dateValue?: Date;
    label?: string;
    onToggle?: () => void;
    dateChanged?: (event: DatePickerChangeEvent) => void;
};

type ExcelDateListItemProps = ListItemProps & DateProps;

const ExcelDateListItem = (props: ExcelDateListItemProps) => {

    return (
        <ListItem disabled={!props.enabled} style={props.style}>
            <ListItemIcon>
                <Checkbox color="primary" checked={props.enabled} onChange={props.onToggle}/>
            </ListItemIcon>
            <ListItemText primary={<ExcelDatePicker disabled={!props.enabled} value={props.dateValue} onChange={props.dateChanged} />} secondary={props.label}/>
        </ListItem>
    );

}


export default ExcelDateListItem;