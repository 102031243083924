import React, { useCallback, useMemo } from 'react';

import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GridActionCallback } from '../GridActionsCell';

interface DeleteActionButtonProps<T> {
  dataItem: T;
  onClick: GridActionCallback<T>;
  isVisible?: (item: T) => boolean;
}

export function DeleteActionButton<T>({ dataItem, isVisible, onClick }: DeleteActionButtonProps<T>) {
  const handleClick = useCallback(() => onClick(dataItem), [onClick, dataItem]);

  const visible = useMemo(() => isVisible == null || isVisible(dataItem), [isVisible, dataItem]);
  if (!visible)
    return null;

  return (
    <OverlayTrigger
      trigger="focus"
      placement="left"
      overlay={
        <Popover id="delete-popover">
          <Popover.Title as="h3">Are you sure?</Popover.Title>
          <Popover.Content>
            <Button variant="danger" className="m-1" size="sm" onClick={handleClick}>
              Delete <FontAwesomeIcon icon={'trash-alt'}/>
            </Button>
          </Popover.Content>
        </Popover>
      }>
      <Button variant="danger" className="m-1" size="sm">
        <FontAwesomeIcon icon={'trash-alt'}/>
      </Button>
    </OverlayTrigger>
  );
}