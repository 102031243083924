import * as React from 'react';
import FormControl from 'react-bootstrap/FormControl';

export function SerialInput(props: any) {
   const onTextChange = (e: any) => {
      let newText = parseText(e.target.value);

      e.target.value = newText;

      if (props.onChange) props.onChange(e);
   };

   const parseText = (text: string) => {
      try {
         let json = JSON.parse(text);
         return json.t ? `${json.t}:${json.s}` : text;
      } catch (e) {
         return text;
      }
   };

   return <FormControl {...props} onChange={onTextChange} value={props.value === null ? '' : props.value} />;
}
