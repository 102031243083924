import config from '../../../config';

/**
 * Function to be used to separate serial number from prefix
 * @param serialNumber The Serial Number
 * @returns Serial Number with prefix removed
 */
export default function separateSerialFromPrefix(serialNumber: string, getSerial: boolean = true): string {
  const separator = config.serialNumberPrefixSeparator;
  if (serialNumber.includes(separator)) {
    let serialNumberComponents = serialNumber.split(separator, 2);
    if(getSerial)
      return serialNumberComponents[1];
    else
      return serialNumberComponents[0];
  }
  return getSerial ? serialNumber : '';
}

export function getSerialNumberFilter(serialNumber: string)
{
  let serialFilter = [];
  const serialPrefix  = separateSerialFromPrefix(serialNumber, false);
  if (serialPrefix !== "")
  serialFilter.push({ field: 'serialNumberPrefix', value: serialPrefix });
  serialFilter.push({ field: 'productSerialNumber', value: separateSerialFromPrefix(serialNumber) });

  return serialFilter;
}