import React, { useCallback } from 'react';

import { Col, Collapse, Row } from 'react-bootstrap';

import { TestEquipmentCalibrationDetails } from './TestEquipmentCalibrationDetails';
import { AddTestEquipmentSerial } from './AddTestEquipmentSerial';
import { TransitionGroup } from 'react-transition-group';

interface TestEquipmentFormControlProps {
  serialNumbers: string[];
  value: string;
  onChange: (serialNumber: string) => void;
  onAdd: (serialNumber: string) => void;
  onDelete: (serialNumber: string) => void;
  disabled: boolean;
}

export default (props: TestEquipmentFormControlProps) => {
  const { serialNumbers, onDelete, disabled } = props;
  const handleDelete = useCallback((deletedSerialNumber: string) => onDelete(deletedSerialNumber), [onDelete]);

  return (
    <>
      <TransitionGroup>
        <Row md={2} lg={3} className="g-4" style={{ rowGap: '1rem' }}>
          {
            serialNumbers.map(serial =>
              <Collapse in={true}>
                <Col key={`${serial}-row`}>
                  <TestEquipmentCalibrationDetails
                    key={serial}
                    serialNumber={serial}
                    onDelete={handleDelete}
                    disabled={disabled}
                  />
                </Col>
              </Collapse>
            )
          }
        </Row>
      </TransitionGroup>

      { !disabled && <AddTestEquipmentSerial {...props} /> }
    </>
  );
}

