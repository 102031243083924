import React from 'react';
import { TestEquipmentCard, TestEquipmentCardBody } from '../TestEquipmentCard';
import { CommonTestEquipmentCardProps } from './Types';

export function TestEquipmentNotFoundCard(props: CommonTestEquipmentCardProps) {
  return (
    <TestEquipmentCard
      {...props}
      variant="danger"
    >
      <TestEquipmentCardBody>
        <p>Could not find any test equipment with this serial number.</p>
        <p>Please ensure this device has been created.</p>
      </TestEquipmentCardBody>
    </TestEquipmentCard>
  );
}