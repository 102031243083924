import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ISupplier from '../Model/ISupplier';
import IProductType from '../Model/IProductType';
import ISupplierProduct from '../Model/ISupplierProduct';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { GridActionsCell } from '../Shared/GridActionsCell';
import config, { newId, keys } from '../../config';
import SupplierProduct from './SupplierProduct';
import DropDownGridCell from '../DropDownGridCell';
import FormButtons from '../Shared/FormButtons';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import { getProductTypes } from "../Shared/Data/GetProductTypes";
import { PageTitle } from "../Shared/PageTitle";
import { AttributeKeys } from '../Model/Attribute';
import { TableNames } from '../Model/API/TableNames';

export interface SupplierProps extends RouteComponentProps<any> {
   id: string;
}

export interface SupplierState {
   supplier: ISupplier;
   productTypes: IProductType[];
   dataState: any;
   allowAddNewProduct: boolean;
   bannerMessage: InfoMessage;
   suppliers: any;
}

const dataState = {
   take: 5,
   skip: 0,
};

class Supplier extends ApiResponseHandler<SupplierProps, SupplierState> {
   actionCell: any;
   productTypeCell: any;

   suppliersDataUrl = config.apiGateway.META_API + '/api/assetmanufacturers/';
   supplierProductAttributeKey = AttributeKeys.SupplierProducts;

   constructor(props: SupplierProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: {
            supplier: { id: 0, name: '', products: [], attributes: [] } as ISupplier,
            dataState,
            productTypes: [],
            allowAddNewProduct: true,
            bannerMessage: { message: '', show: false, warn: false, error: false },
            suppliers: this.props.location.state,
         },
      };
      this.actionCell = GridActionsCell({ onDelete: this.handleDeleteProductType.bind(this) });
      this.expandChange = this.expandChange.bind(this);
      this.productTypeCell = (gridProps: any) => (
         <DropDownGridCell {...gridProps} list={this.state.innerState.productTypes} handleChange={this.handleChangeProductType.bind(this)} />
      );
   }

   componentDidMount() {
      this.setState({
         loading: true,
      });
      this.getSupplier(this.props.match.params.id);
   }

   getSupplier(id: string) {
      let supplier: ISupplier;

      if (id !== newId) {
         var suppliersDataUrl = config.apiGateway.META_API + '/api/assetmanufacturers/' + id;

         this.get(suppliersDataUrl)
            .then((response) => {
               if (response) {
                  supplier = {
                     id: response.id,
                     name: response.name,
                     products: response.attributes.some((a: any) => a.key === this.supplierProductAttributeKey)? JSON.parse(response.attributes.find((a: any) => a.key === this.supplierProductAttributeKey).value): [],
                     attributes: response.attributes
                  };

                     supplier.products = supplier.products.map((p: ISupplierProduct) => ({
                        ...p,
                        updateSerialRange: this.handleUpdateSerialRange.bind(this),
                     }));
   
                    this.getProductTypes(supplier);
               }
            })
            .catch((error) => {
               this.handleApiError(error);
            });
      } else {
         supplier = { id: 0, name: '', products: [], attributes: [] };

         this.getProductTypes(supplier);
      }
   }

   getProductTypes(supplier: ISupplier) {
      getProductTypes(url => this.get(url))
        .then((response) => this.setState({ loading: false, innerState: { ...this.state.innerState, supplier, productTypes: response } }))
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   handleButtonClick(e: any) {
      if (e.target.value === 'save') this.validateForm() && this.saveSupplier();
      else this.props.history.push(`/Suppliers`);
   }

   saveSupplier() {
      const supplier = this.state.innerState.supplier;
      if (supplier.id === 0) {
         // New
         this.post(this.suppliersDataUrl, JSON.stringify({ name: supplier.name }))
            .then((response) => {
               if (response) {
                  supplier.id = response;
                  this.saveSupplierProducts(supplier, true);
               }
            })
            .catch((error) => {
               this.handleApiError(error);
            });
      } else {
         // Existing
         this.put(`${this.suppliersDataUrl + supplier.id}/`, JSON.stringify({ name: supplier.name }))
            .then(() => {
               this.saveSupplierProducts(supplier, false);
            })
            .catch((error) => {
               this.handleApiError(error);
            });
      }
   }

   private saveSupplierProducts(supplier: ISupplier, isNew: boolean) {
      const value = JSON.stringify(
         supplier.products.map((p: ISupplierProduct) => ({
            id: p.id,
            serialRanges: p.serialRanges,
            name: p.name,
         }))
      );

      if (isNew || !supplier.attributes.some((a: any) => a.key === this.supplierProductAttributeKey)) {
         const body = JSON.stringify({
            tableName: TableNames.AssetManufacturers,
            key: this.supplierProductAttributeKey,
            value: value,
            name: this.supplierProductAttributeKey,
            description: this.supplierProductAttributeKey,
         });

         this.query(this.suppliersDataUrl + `attributes/${supplier.id}`, 'POST', body)
            .then(() => {
               this.props.history.push(`/Suppliers?success=true`);
            })
            .catch((error) => {
               this.handleApiError(error);
            });
      } else {
         const url =
            this.suppliersDataUrl + `attributes/${supplier.id}/${this.supplierProductAttributeKey}/${value}/${this.supplierProductAttributeKey}`;

         this.query(url, 'PUT')
            .then(() => {
               this.props.history.push(`/Suppliers`);
            })
            .catch((error) => {
               this.handleApiError(error);
            });
      }
   }

   handleChangeName(e: any) {
      const { value } = e.target;
      const supplier = this.state.innerState.supplier;
      supplier.name = value;

      this.setState({
         innerState: { ...this.state.innerState, supplier },
      });
   }

   handleDeleteProductType(value: any) {
      const supplier = this.state.innerState.supplier;
      supplier.products = this.state.innerState.supplier.products.filter((p: { id: number }) => p.id !== value.id);

      const allowAddNewProduct = supplier.products.find((p) => p.id === 0) == undefined;

      this.setState({
         innerState: { ...this.state.innerState, supplier, allowAddNewProduct },
      });
   }

   handleAddProductType() {
      if (this.state.innerState.allowAddNewProduct) {
         const supplier = this.state.innerState.supplier;

         supplier.products.push({
            id: 0,
            name: '',
            serialRanges: [],
            attributes:[],
            updateSerialRange: this.handleUpdateSerialRange.bind(this),
         } as ISupplierProduct);

         this.setState({
            innerState: { ...this.state.innerState, supplier, allowAddNewProduct: false },
         });
      }
   }

   handleChangeProductType(productId: number, newProductType: IProductType) {
      let supplier = this.state.innerState.supplier;
      let bannerMessage = this.state.innerState.bannerMessage;
      let newProductId = newProductType.id;

      if (this.state.innerState.supplier.products.some((sp) => sp.id === newProductType.id)) {
         bannerMessage.message = `The product type: "${newProductType.name}" has already been selected.`;
         bannerMessage.show = true;
         bannerMessage.error = true;
         newProductId = 0;
      } else {
         bannerMessage.message = '';
         bannerMessage.show = false;
         bannerMessage.error = false;
      }
      const newProducts = supplier.products.map((p: { id: number }) =>
         p.id === productId ? { ...p, id: newProductId, name: newProductType.name } : p
      ) as ISupplierProduct[];

      supplier.products = newProducts;

      const allowAddNewProduct = newProducts.find((p) => p.id === 0) == undefined;

      this.setState({
         innerState: { ...this.state.innerState, supplier, allowAddNewProduct, bannerMessage },
      });
   }

   expandChange(event: any) {
      event.dataItem.expanded = event.value;
      this.setState({
         innerState: { ...this.state.innerState },
      });
   }

   handleUpdateSerialRange(supplierProduct: ISupplierProduct) {
      let supplier = this.state.innerState.supplier;
      supplier.products = supplier.products.map((p) => (p.id === supplierProduct.id ? supplierProduct : p));

      this.setState({
         innerState: { ...this.state.innerState, supplier },
      });
   }

   validateForm() {
      if (!this.state.innerState.supplier.name) return this.setValidationError('You must give the supplier a name.');

      try {
         this.state.innerState.supplier.products.forEach((p) => {
            if (!p.name) throw 'You cannot select an empty product for this supplier';

            const selectedProduct = this.state.innerState.productTypes.filter((product: any) => {
               return product.id == p.id
            })

            const noPrefixAttribute = selectedProduct[0].attributes &&  selectedProduct[0].attributes.find((a: any) => a.key === keys.NoSerialNumberPrefixKey) !== undefined;
            p.serialRanges.forEach((r) => {
               if (!r.prefix && !noPrefixAttribute) throw 'You must provide a prefix for each serial range';

               if (r.min === null || r.max === null) throw 'You must provide a maximum and minimum value for each serial range';

               if (r.min >= r.max) throw 'Maximum serial range value must be greater than the minimum value';

              // this.checkDuplicateSerialRanges(r.prefix, r.max, r.min);
            });
         });
      } catch (error) {
         return this.setValidationError(error);
      }

      return true;
   }

   checkDuplicateSerialRanges(prefix: string, max: number, min: number) {
      this.state.innerState.suppliers.forEach((s: ISupplier) => {
         if (s.id !== this.state.innerState.supplier.id) {
            if(s.products !== undefined && s.products.length > 0 )
            {
               s.products.forEach((p) => {
                  p.serialRanges.forEach((sr) => {
                     if (sr.prefix === prefix) {
                        if ((min <= sr.min && max >= sr.min) || (min >= sr.min && max <= sr.max) || (min <= sr.max && max >= sr.max))
                           throw `Serial range is already used for supplier ${s.name}`;
                     }
                  });
               });
            }
           
         }
      });
   }

   setValidationError(errorMessage: string) {
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: errorMessage,
               error: true,
            },
         },
      });

      return false;
   }

   render() {
      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <PageTitle title={this.props.match.params.id === 'New' ? 'Create New Supplier' : 'Edit Supplier'}/>
            <Form>
               <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                     name="supplierName"
                     required={true}
                     value={this.state.innerState.supplier.name}
                     type="text"
                     placeholder="Enter supplier name"
                     onChange={this.handleChangeName.bind(this)}
                  />
                  <Form.Text className="sm text-muted m-1">ID:{this.state.innerState.supplier.id}</Form.Text>
                  <Form.Label>Product Types</Form.Label>
                  <Grid
                     pageable
                     sortable
                     data={process(this.state.innerState.supplier.products, this.state.innerState.dataState)}
                     {...this.state.innerState.dataState}
                     onDataStateChange={(e) => {
                        this.setState({ innerState: { ...this.state.innerState, dataState: e.data } });
                     }}
                     detail={SupplierProduct}
                     expandField="expanded"
                     onExpandChange={this.expandChange}>
                     <Column
                        field="name"
                        title="Name"
                        cell={this.productTypeCell}
                        columnMenu={(p) => <GridSortFilterColumn {...p} data={this.state.innerState.supplier.products} expanded={true} />}
                     />
                     <Column title="Actions" width="100em" cell={this.actionCell} filterable={false} />
                  </Grid>
                  <Button
                     className="mt-3"
                     variant="primary"
                     onClick={() => this.handleAddProductType()}
                     disabled={!this.state.innerState.allowAddNewProduct}>
                     Add new product type
                  </Button>
               </Form.Group>
               <FormButtons handleButtonClick={this.handleButtonClick.bind(this)} />
            </Form>
         </React.Fragment>
      );
   }
}

export default withRouter(Supplier);
