import React, { useState, useEffect } from 'react'
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import TestResultIcon from '../Shared/TestResultIcon';
import config from '../../config';

import './FormTestField.css';

interface TestResult {
    value: string;
    passOrFail: string;
}

export interface NumericTestFieldProps {
    label: string;
    min?: number;
    max?: number;
    unit?: string;
    na?: boolean;
    value: TestResult;
    onChange?: (e:any) => void;
    disabled?: boolean;
    criteria?: string;
    testNumber?: number;
}

const calculateDecimalPlaces = (digitString: string) => {
    const [_, fractionalPart] = digitString.split('.');
    return fractionalPart ? fractionalPart.length : 0;
}

const NumericTestField = (props: NumericTestFieldProps) => {
    const [passOrFail, setPassOrFail] = useState<string>('-');
    const [naChecked, setNaChecked] = useState<boolean>(false);
    const [valNum, setValNum] = useState<number>();

    useEffect(() => {
            const testResult = props.value.value.toLowerCase() === config.naTestResultText ? config.naTestResultText : checkTestResult(Number(props.value.value));
            if (testResult === config.naTestResultText) setNaChecked(true);
            setPassOrFail(testResult);
    }, []);


    const calculateStepSize = () => {
        let minValueString: string = '';
        let maxValueString: string = '';
        // Explicit checks for undefined as 0 is a valid value for min and max
        if(props.min === undefined && props.max === undefined) {
            return 1;
        } 
        if(props.min !== undefined) minValueString = String(props.min);
        if(props.max !== undefined) maxValueString = String(props.max);
        const decimalPlaces = Math.max(calculateDecimalPlaces(minValueString), calculateDecimalPlaces(maxValueString));
        return 10**(-decimalPlaces);
    }

    const valueIsInRange = (value: number) => {
        let valueIsInRange = Number.isNaN(value) ? false : true;
        if(props.min != null && value < props.min) valueIsInRange = false;
        if(props.max != null && value > props.max) valueIsInRange = false;
        return valueIsInRange;
    }


    const checkTestResult = (value: number | undefined) => {
        let testResult = '-';
        if (value) {
            const valueInRange = valueIsInRange(Number(value));
            testResult = valueInRange ? 'Pass' : 'Fail';
        }

        return testResult;
    }


    const updateResultObject = (value: number | undefined, na: boolean) => {
        const testResult = na ? config.naTestResultText : checkTestResult(value);
        let testResultObj: TestResult = {value: value ? value.toString() : '', passOrFail:testResult};
        if (na) {
            testResultObj.value = config.naTestResultText;
        }
        setValNum(value);
        setPassOrFail(testResult);
        props.onChange?.(testResultObj);
    }

    const handleChange = (e: any) => {
        const value = e.target.value;
        updateResultObject(value, naChecked);
    }

    const handleNA = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.na) return;
        setNaChecked(e.currentTarget.checked);
        updateResultObject(valNum, e.currentTarget.checked);
    }

    const renderPassRange = () => {
        let passRangeText: string = 'Pass Range: ';

        if(props.min) {
            passRangeText += `Min: ${props.min}`;
            if(props.unit) passRangeText += props.unit;
            if(props.max) passRangeText += ' - ';
        }
        if(props.max) {
            passRangeText += `Max: ${props.max}`;
            if(props.unit) passRangeText += props.unit;
        }

        return (
           <p className="form-test-field-item">{passRangeText}</p>
        );
    }

 
    const renderWithoutPassRange = () => {
        return props.unit ? (<p className="form-test-field-item">Unit: {props.unit}</p>) : null;
    }


    const renderTestLabel = () => {
        const testNumString = `Test No. ${props.testNumber}: `;
        return (
            <>
                {props.testNumber != null
                ? <Form.Label>{testNumString}<br />{props.label}</Form.Label>
                : <Form.Label>{props.label}</Form.Label>}
                {props.na ? 
                <ButtonGroup toggle>
                <ToggleButton
                    className="mb-2 ml-2"
                    type="checkbox"
                    // gives the illusion the button is checked when disabled and the test value is n/a
                    variant={props.disabled && naChecked ? 'danger' : 'outline-danger'}
                    checked={naChecked}
                    value="1"
                    onChange={handleNA}
                    disabled={props.disabled}
                  >
                    {config.naTestResultText}
                  </ToggleButton>
                  </ButtonGroup>
                : null}

            </>
        );
    }


    const renderPassFailCriteria = () => {
        return (
            <div className="criteria-layout">
                {props.criteria
                 ? <p className="form-test-field-item">Pass criteria: {props.criteria}</p>
                 : null}
                {/* Explicit check for undefined as 0 is allowed as a value */}
                {(props.min !== undefined || props.max !== undefined) ? renderPassRange() : renderWithoutPassRange()}
            </div>
        );
    }


    return (
        <React.Fragment>
            {renderTestLabel()}
            <div className="form-test-field">
                <Form.Control className={`form-test-field-item ${props.value.value? 'highlight': ''}`}
                    type="number"
                    onChange={handleChange}
                    value={naChecked || !valNum ? '' : valNum}
                    disabled={props.disabled || naChecked}
                    step={calculateStepSize()}
                    onWheel={(e:any) => e.target.blur()}
                />
                <div className="ml-1 form-test-field-item">
                    <TestResultIcon
                        value={passOrFail}
                        size="2x"
                    />
                </div>
            </div>
            {renderPassFailCriteria()}
        </React.Fragment>
    );
}

export default NumericTestField;