import * as React from 'react';
import { useCallback, useState } from 'react';

import { Grid, GridDataStateChangeEvent, GridItemChangeEvent, GridProps, GridRowClickEvent } from '@progress/kendo-react-grid';
import { State as DataState } from '@progress/kendo-data-query';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from 'react-bootstrap';

import { SerialRange } from '../Model/SerialRange';
import { DEFAULT_DATA_STATE } from '../Shared/KendoConstants';
import { useProcessedGridData } from '../Shared/Grids/UseProcessedGridData';
import { EDIT_FIELD } from '../Shared/Utils/SetInEdit';
import { useActionCell } from '../Shared/Grids/UseActionCell';

interface SerialRangeGridProps extends GridProps {
  serialRanges: SerialRange[];
  editId?: number;
  onAdd: () => void;
  onDelete: (item: SerialRange) => void;
  onRowClick: (event: GridRowClickEvent) => void;
  onItemChange: (event: GridItemChangeEvent) => void;
  className?: string;
}

export function SerialRangeGrid({ serialRanges, editId, onAdd, onDelete, onRowClick, onItemChange, className, ...gridProps }: SerialRangeGridProps) {
  const [ dataState, setDataState ] = useState<DataState>(DEFAULT_DATA_STATE);
  const handleDataStateChange = useCallback((e: GridDataStateChangeEvent) => setDataState(e.data), []);

  const data = useProcessedGridData(serialRanges, dataState, editId);

  const actionCell = useActionCell({ onDelete });

  return (
    <div className={className}>
      <Grid
        pageable
        {...dataState}
        data={data}
        onDataStateChange={handleDataStateChange}
        editField={EDIT_FIELD}
        onRowClick={onRowClick}
        onItemChange={onItemChange}
        {...gridProps}
      >
        <Column field="prefix" title="Prefix" editor="text"/>
        <Column field="min" title="Min" editor="numeric"/>
        <Column field="max" title="Max" editor="numeric"/>
        <Column title="Actions" width="100em" cell={actionCell} filterable={false}/>
      </Grid>
      <Button className="mt-2" variant="primary" onClick={onAdd}>
        Add new serial range
      </Button>
    </div>
  );
}