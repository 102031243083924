import * as React from 'react';
import { Button } from 'react-bootstrap';
import ISupplier from '../Model/ISupplier';
import { Grid, GridColumn as Column, GridColumnMenuProps } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { GridActionsCell } from '../Shared/GridActionsCell';
import { RouteComponentProps } from 'react-router-dom';
import config, { newId, keys } from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner, BLANK_BANNER_MESSAGE } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import queryString from 'query-string';
import { JwtManager } from '../Shared/JwtManager';
import { PageTitle } from "../Shared/PageTitle";
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';

export interface SupplierListProps extends RouteComponentProps<any> {}

export interface SupplierListState {
   suppliers: ISupplier[];
   dataState: any;
   bannerMessage: InfoMessage;
}

const dataState = {
   take: 10,
   skip: 0,
};
const tokenManager = new JwtManager({});

class SupplierList extends ApiResponseHandler<SupplierListProps, SupplierListState> {
   actionCell: any;

   constructor(props: SupplierListProps) {
      super(props);
      const claim = tokenManager.getClaims();
      this.state = {
         innerState: { suppliers: [], dataState, bannerMessage: BLANK_BANNER_MESSAGE },
         loading: true,
         redirect: false,
      };
      this.actionCell = GridActionsCell({
         onEdit: this.onEdit.bind(this),
         onDelete: claim === config.viewAllClaim ? this.onDelete.bind(this) : undefined,
      });
   }

   componentDidMount() {
      const values = queryString.parse(this.props.location.search);
      let message = this.state.innerState.bannerMessage;

      let success = values.success || false;
      if (success) message = { message: 'Successfully added new supplier', show: true, warn: false, error: false, successMessage: true };

      this.setState({
         loading: true,
         innerState: {
            ...this.state.innerState,
            bannerMessage: message,
         },
      });

      this.getSuppliers();
   }

   getSuppliers() {
      var suppliersDataUrl = config.apiGateway.META_API + '/api/assetmanufacturers';

      this.get(suppliersDataUrl)
         .then((response) => {
            if (response) {
               var suppliers: ISupplier[] = response.map((supplierJson: any) => ({
                  name: supplierJson.name,
                  id: supplierJson.id,
                  products: supplierJson.attributes.some((a: any) => a.key === keys.supplierProductAttributeKey)
                     ? JSON.parse(supplierJson.attributes.find((a: any) => a.key === keys.supplierProductAttributeKey).value)
                     : undefined,
               }));

               this.setState({
                  loading: false,
                  innerState: {
                     ...this.state.innerState,
                     suppliers:suppliers,
                  },
               });
            }
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   handleAddNewSupplier(): void {
      this.props.history.push({ pathname: `/Suppliers/${newId}`, state: this.state.innerState.suppliers });
   }

   onEdit(dataItem: any) {
      this.props.history.push({ pathname: `/Suppliers/${dataItem.id}`, state: this.state.innerState.suppliers });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   onDelete(dataItem: any) {
      var suppliersDataUrl = config.apiGateway.META_API + '/api/assetmanufacturers/' + dataItem.id;

      this.delete(suppliersDataUrl)
         .then(() => {
            this.getSuppliers();
            this.setState({
               ...this.state,
               innerState: {
                  ...this.state.innerState,
                  bannerMessage: BLANK_BANNER_MESSAGE,
               },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   render() {
      const suppliers = this.state.innerState.suppliers;

      return (
        <React.Fragment>
           <InfoBanner message={this.state.innerState.bannerMessage}/>
           <PageTitle title="Suppliers"/>
           <BusyOverlay show={this.state.loading}/>
           <Grid
             className="mt-5"
             pageable
             sortable
             data={process(this.state.innerState.suppliers, this.state.innerState.dataState)}
             {...this.state.innerState.dataState}
             onDataStateChange={(e) => {
                this.setState({ innerState: { ...this.state.innerState, dataState: e.data, suppliers: this.state.innerState.suppliers } });
             }}>
              <Column
                field="id"
                title="ID"
                width="80em"
                columnMenu={(props: GridColumnMenuProps) => <GridSortFilterColumn {...props} fuzzy data={suppliers} expanded/>}
              />
              <Column
                field="name"
                title="Name"
                columnMenu={(props: GridColumnMenuProps) => <GridSortFilterColumn {...props} fuzzy data={suppliers} expanded/>}
              />
              <Column title="Actions" width="130em" cell={this.actionCell} filterable={false}/>
           </Grid>
           <Button className="mt-2" variant="primary" onClick={() => this.handleAddNewSupplier()}>
              Add new supplier
           </Button>
        </React.Fragment>
      );
   }
}

export default SupplierList;
