import React from 'react';
import { Typography, withStyles, createStyles } from '@material-ui/core';
import classNames from 'classnames';

interface FooterProps {
    copyrightText: string;
    classes: {
        footerBar: any
    }
}
const styles = (theme: any) => createStyles({
  footerBar: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: '#220639',
    color: 'white', 
    textAlign: 'center',
  }
});


class Footer extends React.Component<FooterProps>{
    render()
    {
      return (
          <div  className={classNames(this.props.classes.footerBar)} >
              <Typography variant="overline" color="inherit">{this.props.copyrightText}</Typography>
          </div>
      )
    }
}

export default withStyles(styles, { withTheme: true })(Footer);