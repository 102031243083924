import * as React from "react";
import { Component } from "react";
import { InfoMessage, InfoBanner } from "../Shared/Infobanner";
import ApiResponseHandler from "../Shared/ApiResponseHandler";
import BusyOverlay from "../Shared/BusyOverlay";
import { IDataRecord } from "../Model/IDataRecord";
import mockDataRecords from "../../mocks/records.json";
import { RouteComponentProps, withRouter } from "react-router-dom";
import config from "../../config";

export interface RecordProps extends RouteComponentProps<any> {}

export interface RecordState {
   dataState: any;
   bannerMessage: InfoMessage;
   record: any;
}

const dataState = {
   take: 10,
   skip: 0,
};

class Record extends ApiResponseHandler<RecordProps, RecordState> {
   constructor(props: RecordProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: {
            record: {},
            dataState,
            bannerMessage: { message: "", show: false, warn: false, error: false },
         },
      };
   }

   componentDidMount() {
      this.setState({
         loading: true,
      });

      const recordUrl = config.apiGateway.DATA_API + "/api/InstrumentTestRecord/Record/" + this.props.match.params.id;

      this.get(recordUrl).then((response) => {
         this.setState({
            loading: false,
            innerState: { ...this.state.innerState, record: response },
         });
      });
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   render() {
      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <div className="title-container">
               <h1 className="page-header">Record</h1>
            </div>
            <div>
               {Object.keys(this.state.innerState.record).map((key, i) => (
                  <p key={i}>
                     <strong>{key}: </strong>
                     {this.state.innerState.record[key]}
                  </p>
               ))}
            </div>
         </React.Fragment>
      );
   }
}

export default withRouter(Record);
