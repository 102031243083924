import dice from 'fast-dice-coefficient/dice';

export interface StringSimilarity {
  value: string;

  /**
   * Decimal value from 0 to 1, where 0 is no match and 1 indicates a full match
   */
  similarity: number;
}

export const sortBySimilarity = (source: string, values: string[]) => {
  const withSimilarity = calculateStringSimilarity(source, values);
  return withSimilarity.sort((a, b) => b.similarity - a.similarity);
};

/**
 * Calculate how similar a collection of strings is to a source value, ignoring case sensitivity.
 * This uses the [Sørensen–Dice coefficient algorithm]{@link https://en.wikipedia.org/wiki/S%C3%B8rensen%E2%80%93Dice_coefficient} to generate a number between
 * 0 and 1 which corresponds to how similar each value is.
 * @param source Source value to compare against
 * @param values Values to compare against the source
 */
export const calculateStringSimilarity = (source: string, values: string[]): StringSimilarity[] => {
  // Lowercase the source to ensure comparison is case-insensitive
  const lowercaseSource = source.toLowerCase();
  return values.map<StringSimilarity>(v => ({
    value: v,
    similarity: dice(lowercaseSource, v.toLowerCase())
  }));
};
