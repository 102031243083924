import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Owner } from '../Model/Owner';
import { getOwners } from '../Shared/Data/Owners/GetOwners';
import { newId } from '../../config';
import GridPage from '../Shared/Grids/GridPage';

export default class OwnerList extends GridPage<Owner> {
  constructor(props: RouteComponentProps) {
    super(props, "Device Owner");

    this.get = this.get.bind(this);
  }

  protected fetchData(): Promise<Owner[]> {
    return getOwners(this.get);
  }

  protected handleAddNewItem(): void {
    this.props.history.push({
      pathname: `/Owners/${newId}`,
      state: {
        owners: this.state.innerState.data
      }
    });
  }

  protected handleEdit(item: Owner): void {
    this.props.history.push({
      pathname: `/Owners/${item.id}`,
      state: {
        owner: item,
        owners: this.state.innerState.data
      }
    });
  }
}
