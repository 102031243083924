import React, { useMemo } from 'react';
import { Owner } from '../Model/Owner';
import { SimilarNameBadge } from './SimilarNameBadge';
import { nameSimilarityThresholds } from '../../config';
import { sortBySimilarity } from '../Shared/Utils/StringSimilarity';

interface SimilarOwnersProps {
  owner: Owner;
  owners: Owner[];
}

export function SimilarOwners({ owner, owners }: SimilarOwnersProps) {
  const similarOwnerNames = useMemo(() => {
    const allOwnerNames = owners.map(o => o.name);
    return sortBySimilarity(owner.name, allOwnerNames)
      .filter(d => d.similarity >= nameSimilarityThresholds.min);
  }, [owner, owners]);

  if (owner.name.trim() === '' || similarOwnerNames.length === 0) {
    return null;
  }

  return (
    <div className="my-2">
      <label className="font-weight-bold">Device owners with similar names</label>
      <div>
        {similarOwnerNames.map(o =>
          <SimilarNameBadge key={o.value} name={o.value} similarity={o.similarity}/>
        )}
      </div>
    </div>
  );
}