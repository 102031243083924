import React, { FC, useCallback, useState } from 'react';
import { Grid, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { EDIT_FIELD } from '../../Shared/Utils/SetInEdit';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from 'react-bootstrap';
import { State as DataState } from '@progress/kendo-data-query/dist/npm/state';
import { DEFAULT_DATA_STATE } from '../../Shared/KendoConstants';
import { useActionCell } from '../../Shared/Grids/UseActionCell';
import EmailAddressCell from '../../Shared/Grids/EmailAddressCell';
import { useContactsChangeHandlers, useContactsGridData, useContactsGridItemChangeHandler } from './Hooks';

interface CalibrationReminderContactsProps {
  contacts: string[];
  onChange: (updated: string[]) => void;
  className?: string;
}

const CalibrationReminderContacts: FC<CalibrationReminderContactsProps> = ({ contacts, onChange, className }) => {
  const [dataState, setDataState] = useState<DataState>(DEFAULT_DATA_STATE);
  const handleDataStateChange = useCallback((e: GridDataStateChangeEvent) => setDataState(e.data), []);

  const [editingIndex, setEditingIndex] = useState<number>();

  const data = useContactsGridData(contacts, editingIndex);
  const handleItemChange = useContactsGridItemChangeHandler(editingIndex, contacts, onChange);
  const { handleEdit, handleDelete, handleAdd } = useContactsChangeHandlers(setEditingIndex, contacts, onChange);

  const actionCell = useActionCell({ onEdit: handleEdit, onDelete: handleDelete });

  return (
    <div className={className}>
      <Grid
        pageable
        sortable
        data={data}
        {...dataState}
        onDataStateChange={handleDataStateChange}
        editField={EDIT_FIELD}
        onItemChange={handleItemChange}
      >
        <Column
          field="value"
          title="Email"
          cell={EmailAddressCell}
        />
        <Column
          title="Actions"
          width="140em"
          cell={actionCell}
          filterable={false}
        />
      </Grid>
      <Button
        className="mt-3"
        variant="primary"
        onClick={handleAdd}
        disabled={contacts.some(contact => contact === '')}
      >
        Add new contact
      </Button>
    </div>
  );
};

export default CalibrationReminderContacts;