import * as React from 'react';

export class SplashScreen extends React.Component<{},{}>{
    render() {
        return (
            <div className="splash-screen">
                {this.props.children}
            </div>
        )
    }
}