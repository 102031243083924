import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import GridSortFilterColumn from '../Shared/GridFilters/GridSortFilterColumn';
import { Button } from 'react-bootstrap';
import { process } from '@progress/kendo-data-query';
import { RouteComponentProps } from 'react-router-dom';
import IProductType from '../Model/IProductType';
import { GridActionsCell } from '../Shared/GridActionsCell';
import config, { productTypeId, newId } from '../../config';
import ApiResponseHandler from '../Shared/ApiResponseHandler';
import { InfoMessage, InfoBanner } from '../Shared/Infobanner';
import BusyOverlay from '../Shared/BusyOverlay';
import queryString from 'query-string';
import { getProductTypes } from '../Shared/Data/GetProductTypes';

export interface ProductTypesProps extends RouteComponentProps<any> {}

export interface ProductTypesState {
   productTypes: IProductType[];
   dataState: any;
   bannerMessage: InfoMessage;
}

const dataState = {
   take: 10,
   skip: 0,
};

class ProductTypes extends ApiResponseHandler<ProductTypesProps, ProductTypesState> {
   actionCell: any;
   blankBannerMessage = { message: '', show: false, warn: false, error: false };

   constructor(props: ProductTypesProps) {
      super(props);
      this.state = {
         loading: true,
         redirect: false,
         innerState: { productTypes: [], dataState, bannerMessage: this.blankBannerMessage },
      };

      this.actionCell = GridActionsCell({
         onEdit: this.onEdit.bind(this),
         onDelete: this.onDelete.bind(this),
         // Prevent users from deleting test equipment product type
         deleteVisible: (productType: IProductType) => productType.id !== productTypeId.testEquipment,
         onReport: this.onShowReports.bind(this),
      });
   }

   componentDidMount() {
      const values = queryString.parse(this.props.location.search);
      let message = this.state.innerState.bannerMessage;

      let success = values.success || false;
      if (success) message = { message: 'Successfully added new product type', show: true, warn: false, error: false, successMessage: true };

      this.setState({
         loading: true,
         innerState: {
            ...this.state.innerState,
            bannerMessage: message,
         },
      });

      this.getProductTypes();
   }

   getProductTypes() {
      getProductTypes(url => this.get(url))
        .then(productTypes => this.setState({ loading: false, innerState: { ...this.state.innerState, productTypes } }))
        .catch(error => this.handleApiError(error));
   }

   handleApiError(error: string) {
      console.error(error);
      this.setState({
         loading: false,
         innerState: {
            ...this.state.innerState,
            bannerMessage: {
               show: true,
               warn: true,
               message: `API Error: ${error}`,
               error: true,
            },
         },
      });
   }

   onEdit(dataItem: any) {
      this.props.history.push(`/ProductTypes/${dataItem.id}`);
   }

   onDelete(dataItem: any) {
      const productTypesUrl = config.apiGateway.META_API + '/api/assetmodels/' + dataItem.id;
      const mappingsUrl = config.apiGateway.META_API + '/api/Mapping';

      this.delete(productTypesUrl)
         .then(() => {
            if (dataItem.parent !== -1) {
               const mappingBody = JSON.stringify({
                  subjectTableName: 'assetmodels',
                  subjectId: dataItem.id,
                  parentTableName: 'assetmodels',
                  parentId: dataItem.parent,
               });
               this.delete(mappingsUrl, mappingBody);

               // TODO: Delete all mappings that have this object as a parent
            }
         })
         .then(() => {
            this.getProductTypes();
            this.setState({
               ...this.state,
               innerState: {
                  ...this.state.innerState,
                  bannerMessage: this.blankBannerMessage,
               },
            });
         })
         .catch((error) => {
            this.handleApiError(error);
         });
   }

   onShowReports(dataItem: any) {
      this.props.history.push(`/Records?filter=producttypeid&value=${dataItem.id}`);
   }

   render() {
      const productTypes = this.state.innerState.productTypes;

      return (
         <React.Fragment>
            <InfoBanner message={this.state.innerState.bannerMessage} />
            <BusyOverlay show={this.state.loading} />
            <div className="title-container">
               <h1 className="page-header">Product Types</h1>
            </div>
            <Grid
               className="mt-5"
               pageable
               sortable
               data={process(productTypes, this.state.innerState.dataState)}
               {...this.state.innerState.dataState}
               onDataStateChange={(e) => {
                  this.setState({ innerState: { ...this.state.innerState, dataState: e.data } });
               }}>
               <Column field="id" title="ID" width="80em" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={productTypes} expanded={true} />} />
               <Column field="name" title="Name" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={productTypes} expanded={true} />} />
               <Column field="parentName" title="Parent" columnMenu={(p) => <GridSortFilterColumn {...p} fuzzy data={productTypes} expanded={true} />} />
               <Column title="Actions" width="150em" cell={this.actionCell} filterable={false} />
            </Grid>
         </React.Fragment>
      );
   }
}

export default ProductTypes;
