import React, { ReactNode, useCallback } from 'react';

import { Button, Card } from 'react-bootstrap';

import styles from './TestEquipmentCard.module.css';
import { BACKGROUND_CLASSES, BORDER_CLASSES, CardVariant, TITLE_CLASSES } from './CardVariant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TestEquipmentCardProps {
  serialNumber: string;
  assetTag?: string;
  variant: CardVariant;
  onDelete: (serialNumber: string) => void;
  disabled: boolean;
  children?: ReactNode;
}

export default ({ assetTag, serialNumber, variant, onDelete, disabled, children }: TestEquipmentCardProps) => {
  const handleRemoveClick = useCallback(() => {
    onDelete(serialNumber);
  }, [serialNumber, onDelete]);

  return (
    <Card className={`${styles.teCard} ${BACKGROUND_CLASSES[variant]} ${BORDER_CLASSES[variant]}`}>
      <Card.Body>
        <Card.Title className={TITLE_CLASSES[variant]}>
          {serialNumber}

          { !disabled &&
            <a href="#" onClick={handleRemoveClick} className={styles.removeXButton} aria-label="Remove">
              <FontAwesomeIcon icon="times" size="xs"/>
            </a>
          }
        </Card.Title>

        {
          assetTag && (
            <Card.Subtitle className="text-secondary">
              {assetTag}
            </Card.Subtitle>
          )
        }

        {children}

        { !disabled &&
          <Button variant="outline-danger" onClick={handleRemoveClick}>
            <FontAwesomeIcon icon="trash-alt" className="mr-1"/>
            Remove
          </Button>
        }
      </Card.Body>
    </Card>
  );
}

