import { createErrorInfoMessage } from '../Shared/Infobanner';
import config from '../../config';
import * as React from 'react';
import { SerialRange } from '../Model/SerialRange';

export const invalidSerialRangePrefixSeparators = (invalidRanges: SerialRange[]) =>
  createErrorInfoMessage(
    <>
      The following prefixes are invalid:
      <ul>
        {invalidRanges.map(range => <li key={`${range.prefix}-${range.min}-${range.max}`}>{range.prefix}</li>)}
      </ul>

      Prefixes must end with the separator <pre>{config.serialNumberPrefixSeparator}</pre>
    </>
  );