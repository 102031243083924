/**
 * Find all duplicate values in a collection.
 * @param items Collection to search for duplicates
 * @param caseInsensitive Controls if check should be case-insensitive
 */
export const duplicates = (items: string[], caseInsensitive: boolean = true) => {
  const seenItems: Record<string, boolean> = {};
  const duplicates: string[] = [];

  for (const item of items) {
    const key = caseInsensitive ? item.toLowerCase() : item;
    if (seenItems[key])
      duplicates.push(item);
    else
      seenItems[key] = true;
  }

  return duplicates;
}
