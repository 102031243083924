import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import TestResultIcon from '../Shared/TestResultIcon';
import config from '../../config';

interface TestResult {
    value: string;
    passOrFail: string;
}

export interface CheckboxTestFieldProps {
    label: string;
    value: TestResult;
    option1?: string;
    option2?: string;
    na?: boolean;
    passChoice?: string;
    onChange?: (e: any) => void;
    disabled?: boolean;
    criteria?: string;
    testNumber?: number;
}

const CheckboxTestField = (props: CheckboxTestFieldProps) => {
    const [passOrFail, setPassOrFail] = useState<string>('-');
    const [selected, setSelected] = useState<string>('');

    useEffect(() => {
        if(props.value.value) 
            setSelected(props.value.value);
        if(props.value.passOrFail)
            setPassOrFail(props.value.passOrFail);
    }, []);


    const handleChange = (e: any) => {
        const choice = e.currentTarget.value;
        let testResult = '-';
        if(choice) {
            if(choice === props.passChoice)
                testResult = "Pass";
            else if (props.na && choice === config.naTestResultText)
                testResult = config.naTestResultText;
            else
                testResult = "Fail";
        }
        setPassOrFail(testResult);
        const testResultObj: TestResult = {value: choice, passOrFail: testResult};
        props.onChange?.(testResultObj);
        setSelected(choice);
    }

    const renderTestLabel = () => {
        const testNumString = `Test No. ${props.testNumber}: `;
        return props.testNumber != null
               ? <Form.Label>{testNumString}<br />{props.label}</Form.Label>
               : <Form.Label>{props.label}</Form.Label>;
    }

    const renderPassFailCriteria = () => {
        return (
            <div className="criteria-layout">
                {props.criteria
                 ? <p className="form-test-field-item">Pass criteria: {props.criteria}</p>
                 : null}
            </div>
        );
    }

    return (
        <React.Fragment>
            {renderTestLabel()}
            <div className="form-test-field">
                <div className="form-test-field-item">
                    <Form.Check 
                        type="radio"
                        label={props.option1}
                        onChange={handleChange}
                        value={props.option1}
                        checked={selected === props.option1}
                        disabled={props.disabled}
                    />
                    <Form.Check 
                        type="radio"
                        label={props.option2}
                        onChange={handleChange}
                        value={props.option2}
                        checked={selected === props.option2}
                        disabled={props.disabled}
                    />
                    {props.na ? 
                    <Form.Check 
                    type="radio"
                    label={config.naTestResultText}
                    onChange={handleChange}
                    value={config.naTestResultText}
                    checked={selected === config.naTestResultText}
                    disabled={props.disabled}
                    />
                    : null}
                </div>
                <div className="ml-1 form-test-field-item">
                    <div className="float-right">
                        <TestResultIcon
                            value={passOrFail}
                            size="2x"
                        />
                    </div>
                </div>
            </div>
            {renderPassFailCriteria()}
        </React.Fragment>
    );
}

export default CheckboxTestField;