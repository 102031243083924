import * as React from 'react';
import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import NavigationItem from '../Model/NavigationItem';

interface NavigationProps {
    navigationItems: NavigationItem[];
    classes: {
        List: string,
        ListItem: string,
        selected: string,
        ListText: string
    }
}

const styles = (theme: any) => ({
    ListItem: {
        background:'#fff',
        borderRadius: '5px',
        margin: '3px',
        border: '1px solid white',
        "&:hover": {
            background: '#E6F7FC'
        },
        color: "#6c757d"
    },
    selected: {
    backgroundColor: "#00AAE4",
    color: "#220639",
    '& span, & i': {
        color: 'white'
    }
    },
    List: {
        paddingRight: '5px'
    },
    ListText: {
        marginLeft: "18px"
    }
  });

 

class Navigation extends React.Component<NavigationProps,{}> {

    public render(){

        return <List className={this.props.classes.List}>
        {this.props.navigationItems.map((navItems: NavigationItem) => (
            <ListItem component={NavLink} to={navItems.route} button key={navItems.text} className={this.props.classes.ListItem}  activeClassName={this.props.classes.selected}>
                {navItems.icon}
                <ListItemText className={this.props.classes.ListText} primary={navItems.text} />
            </ListItem>
        ))}
    </List>
    }
}

export default withStyles(styles, { withTheme: true })(Navigation);

