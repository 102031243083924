import * as React from "react";
import { useCallback } from "react";

import { Form, InputGroup } from "react-bootstrap";
import moment from "moment";

import { parseNumber } from "../Shared/Utils/ParseNumber";
import { UnitOfTime } from "../Model/UnitOfTime";
import styles from "./TimeSpanField.module.css";

export interface TimeSpanFieldProps {
  value: string;
  onChange: (value: TimeSpanChangeEvent) => void;
  unit: UnitOfTime;
  disabled: boolean;
}

export interface TimeSpanChangeEvent {
  target: {
    value: string;
  }
}

export function TimeSpanField(props: TimeSpanFieldProps) {
  const { onChange, unit , disabled } = props;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newDurationLength = parseNumber(e.target.value);
    if (isNaN(newDurationLength))
      return;

    // Encode the length with the unit into an ISO 8601 duration string (e.g. 12 months -> 'P12M')
    const newDuration = moment.duration(newDurationLength, unit);
    const event: TimeSpanChangeEvent = {
      target: {
        value: newDuration.toISOString()
      }
    };

    onChange(event);
  }, [onChange, unit]);

  const duration = moment.duration(props.value);
  const durationLength = duration.as(unit);

  return (
    <InputGroup className={styles.timeSpanGroup}>
      <div className="d-flex">
        <Form.Control
          type="number"
          onChange={handleChange}
          value={durationLength}
          min={0}
          disabled={disabled}
        />
        <InputGroup.Text>
          {unit}
        </InputGroup.Text>
      </div>
    </InputGroup>
  );
}