import { TestEquipmentCard } from '../TestEquipmentCard';
import React from 'react';
import TestEquipmentCardBody from '../TestEquipmentCard/TestEquipmentCardBody';
import { CommonTestEquipmentCardProps } from './Types';

interface TestEquipmentErrorCardProps extends CommonTestEquipmentCardProps {
  error: string;
}

export function TestEquipmentErrorCard(props: TestEquipmentErrorCardProps) {
  return (
    <TestEquipmentCard
      {...props}
      variant="danger"
    >
      <TestEquipmentCardBody>
        {props.error}
      </TestEquipmentCardBody>
    </TestEquipmentCard>
  );
}