import { useMemo } from 'react';
import { EDIT_FIELD } from '../../../Shared/Utils/SetInEdit';
import { ContactGridData } from '../Types';

// Convert email addresses into objects to make them easier to manage from the grid
export default (contacts: string[], editingIndex: number | undefined) => useMemo(() =>
  contacts.map<ContactGridData>((contact, index) => ({
    index: index,
    value: contact,
    [EDIT_FIELD]: editingIndex === index
  })), [contacts, editingIndex]);
