import React, { useCallback, useState } from 'react';
import { BarcodeScannerModal } from '../../../BarcodeScannerModal';
import { parseSerialNumberFromAssetQRCode } from '../../../../Model/AssetQRCode';

export default (onAdd: (serialNumber: string) => void) => {
  const [showScanner, setShowScanner] = useState(false);
  const startScanner = useCallback(() => {
    setShowScanner(true);
  }, []);

  const handleScanningStopped = useCallback(() => {
    setShowScanner(false);
  }, []);

  const handleBarcodeScanned = useCallback((scanned: string) => {
    const scannedSerial = parseSerialNumberFromAssetQRCode(scanned);
    onAdd(scannedSerial);
    setShowScanner(false);
  }, [onAdd]);

  const SerialNumberScanner = () => (
    <BarcodeScannerModal
      onScanned={handleBarcodeScanned}
      onClose={handleScanningStopped}
      show={showScanner}
      title="Scan Test Equipment Barcode"
    />
  );

  return {
    SerialNumberScanner,
    startScanner
  };
}