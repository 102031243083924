import * as React from 'react';
import { GridColumnMenuSort, GridColumnMenuFilter, GridColumnMenuCheckboxFilter, GridColumnMenuProps, GridColumnMenuCheckboxFilterProps } from '@progress/kendo-react-grid';
import CustomTextFilter from './CustomTextFilter';
import CustomDateFilter from './CustomDateFilter';
import CustomSerialFilter from './CustomSerialFilter';
import CustomStatusFilter from './CustomStatusFilter';
import GridFuzzyFilterColumn from './GridFuzzyFilterColumn';

export interface GridSortFilterColumnOptions {
   custom?: string;
   fuzzy?: boolean;
}

export type GridSortFilterColumnProps = GridColumnMenuProps & GridColumnMenuCheckboxFilterProps & GridSortFilterColumnOptions;



export default function GridSortFilterColumn(props: GridSortFilterColumnProps) {
   const getFilter = () => {
      switch (props.custom) {
         case 'date':
            return CustomDateFilter;
         case 'serial':
            return CustomSerialFilter;
         case 'status':
            return CustomStatusFilter;
         default:
            return CustomTextFilter;
      }
   };

   if (props.custom) {
      return (
         <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} expanded={true} filterUI={getFilter()} />
         </div>
      );
   } else {
      return props.fuzzy ? (
         <div>
            <GridColumnMenuSort {...props} />
            <GridFuzzyFilterColumn {...props} expanded={true} />
         </div>
      ) : (
         <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuCheckboxFilter {...props} expanded={true} />
         </div>
      )
   }
}
