import { ChangeEvent, useCallback } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

// Handler which forwards an input element change to a grid cell change event
export const useGridCellChangeHandler = ({ onChange, dataItem, dataIndex, field }: GridCellProps) =>
  useCallback((event: ChangeEvent<HTMLInputElement>) =>
    onChange?.({
      dataItem,
      dataIndex,
      field,
      value: event.target.value,
      syntheticEvent: event
    }), [onChange, dataItem, dataIndex, field]);
