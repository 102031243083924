import { HasName } from "./Types";

/**
 * Create a callback which sorts objects by a field.
 * @param fieldSelector Select the field to sort on
 */
export const fieldSorter = <T>(fieldSelector: (item: T) => string) =>
  (left: T, right: T) => fieldSelector(left).localeCompare(fieldSelector(right));

/**
 * Create a callback which sorts objects by the `name` field.
 */
export const nameSorter = fieldSorter<HasName>(item => item.name);