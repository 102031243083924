import * as React from 'react';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { getEnabledCategories } from 'trace_events';

export interface RecordOptionsBarProps {
   categories: string[];
   options: any;
   handleChangeType: any;
   exportToExcel: any;
}

export interface RecordOptionsBarState {}

class RecordOptionsBar extends React.Component<RecordOptionsBarProps, RecordOptionsBarState> {
   constructor(props: RecordOptionsBarProps) {
      super(props);
      //this.state = { :  };
   }
   render() {
      return (
         <div className="clearfix">
            {this.props.categories.length > 1 ? (
               <DropdownButton className="float-left" id="typedropdown" title="Category">
                  {this.props.categories.map((cat, index) => (
                     <Dropdown.Item key={index} onClick={this.props.handleChangeType.bind(this)}>
                        {cat}
                     </Dropdown.Item>
                  ))}
               </DropdownButton>
            ) : null}
            <Button className="float-right" variant="primary" onClick={this.props.exportToExcel.bind(this)}>
               Export to Excel
            </Button>
         </div>
      );
   }
}

export default RecordOptionsBar;
