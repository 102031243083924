/**
 * Remove the header from a base64-encoded string.
 * @param base64 value to string header from
 * @example
 * const content = 'data:application/pdf;base64,abc123'
 * const withoutHeader = stripBase64Header(content)
 * withoutHeader === 'abc123'
 * @example
 */
export const stripBase64Header = (base64: string): string =>
  base64.substring(base64.indexOf(',') + 1);