import * as React from 'react';
import { withStyles, createStyles, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { Row, Col } from 'react-bootstrap';
import { JwtManager } from './Shared/JwtManager';

const logo = require('../images/EAWhite.svg');

interface HeaderProps {
   isNavigationDrawerOpen: boolean;
   projectTitle: string;
   handleDrawerOpen: any;
   classes: {
      appBar: string;
      appBarShift: string;
      menuButton: string;
      hide: string;
   };
}
const drawerWidth = 340;
const styles = (theme: any) =>
   createStyles({
      appBar: {
         transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         zIndex: theme.zIndex.drawer + 1,
         background: '#220639',
      },
      appBarShift: {
         width: `calc(100% - ${drawerWidth}px)`,
         marginLeft: drawerWidth,
         transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      menuButton: {
         marginLeft: 12,
         marginRight: 20,
         float: 'left',
      },
      hide: {
         display: 'none',
      },
   });

class Header extends React.Component<HeaderProps, {}> {
   tokenManager = new JwtManager({});

   toggleLoginModal() {
      this.tokenManager.removeToken();
      window.location.reload();
      this.tokenManager.removeSupplierId();
      this.tokenManager.removeClaims();
   }

   render() {
      var open = this.props.isNavigationDrawerOpen;
      return (
         <AppBar
            position="fixed"
            className={classNames(this.props.classes.appBar, {
               [this.props.classes.appBarShift]: open,
            })}>
            <Toolbar disableGutters={!open}>
               <div className="container-fluid">
                  <Row>
                     <Col className="col-sm-8">
                        <IconButton
                           color="inherit"
                           aria-label="Open drawer"
                           onClick={this.props.handleDrawerOpen}
                           className={classNames(this.props.classes.menuButton, open && this.props.classes.hide)}>
                           <MenuIcon />
                        </IconButton>
                        <div style={{ borderRight: '1px solid white', float: 'left' }}>
                           <img alt="EA Technology" src={logo} className="logo" />
                        </div>
                        <Typography className="project-title-text" variant="h4" color="inherit" noWrap>
                           {this.props.projectTitle}
                        </Typography>
                     </Col>
                     <Col className="col-4">
                        <Button
                           variant="primary"
                           style={{ marginRight: 10, float: 'right', marginTop: 12 }}
                           onClick={() => this.toggleLoginModal()}
                           className="blue-fill btn">
                           <InputIcon className="icon-size" />
                           Log Out
                        </Button>
                        <div style={{ marginRight: 10, float: 'right' }}></div>
                     </Col>
                  </Row>
               </div>
            </Toolbar>
         </AppBar>
      );
   }
}

export default withStyles(styles, { withTheme: true })(Header);
