import * as React from "react";

interface PageTitleProps {
  title: string;
}

export const PageTitle = ({ title }: PageTitleProps) => (
  <div className="title-container">
    <h1 className="page-header">{title}</h1>
  </div>
);