import { Image } from "react-bootstrap";
import * as React from "react";
import { useCallback } from "react";

import { useFormDocumentContent } from "../../Shared/Data/UseFormDocumentContent";
import { UploadedDocument } from "../../Model/UploadedDocument";
import { ViewUploadedDocumentContents } from "./ViewUploadedDocumentContents";

import PDFDocument from "../../../images/PDFDocument.svg";

interface ViewUploadedDocumentProps {
  document: UploadedDocument;
  onViewDocument: (content: string) => void;
  onDownloadDocument: (content: string) => void;
}

const PDF_FILE_EXTENSION = '.pdf';

export function ViewUploadedDocument(props: ViewUploadedDocumentProps) {
  const { document, onViewDocument, onDownloadDocument } = props;
  const { originalFilename, savedFilename } = document;

  const { loading, error, content } = useFormDocumentContent(savedFilename);

  const handleView = useCallback(() =>
      content && onViewDocument(content)
    , [content, onViewDocument]
  );

  const handleDownload = useCallback(async () =>
      content && onDownloadDocument(content),
    [content, onDownloadDocument]
  );

  const formattedFilename = originalFilename.endsWith(PDF_FILE_EXTENSION)
    ? originalFilename.substr(0, originalFilename.length - PDF_FILE_EXTENSION.length)
    : originalFilename;

  return (
    <div className="d-inline-flex border p-3">
      <Image src={PDFDocument} height="64px"/>
      <div className="d-flex flex-column ml-1">
        <label>{formattedFilename}</label>
        <ViewUploadedDocumentContents
          loading={loading}
          error={error}
          savedFilename={savedFilename}
          onView={handleView}
          onDownload={handleDownload}
        />
      </div>
    </div>
  );
}
