import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import SideDrawer from './SidePanel/Sidedrawer';
import { withStyles, createStyles, WithStyles, CssBaseline } from '@material-ui/core';
import classNames from 'classnames';
import Navigation from './Navigation/Navigation';
import NavigationItem from './Model/NavigationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdateNavItems } from './Shared/UpdateNavItems';

export interface LayoutProps extends WithStyles<typeof styles> {
   userIsAuthenticated: boolean;
   children?: React.ReactNode;
   classes: {
      root: string;
      toolbar: string;
      content: string;
      contentShift: string;
      drawerHeader: string;
   };
}

export interface LayoutState {
   leftPannelExapanded: boolean;
   navItems: NavigationItem[];
}

const styles = (theme: any) =>
   createStyles({
      root: {
         display: 'flex',
         height: '100%',
      },
      toolbar: theme.mixins.toolbar,
      content: {
         flexGrow: 1,
         overflowY: 'auto',
         padding: theme.spacing(6),
         transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
      },
      contentShift: {
         transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      drawerHeader: {
         display: 'flex',
         alignItems: 'center',
         padding: '0 8px',
         ...theme.mixins.toolbar,
         justifyContent: 'flex-end',
      },
   });

class Layout extends React.Component<LayoutProps, LayoutState> {
   constructor(props: LayoutProps, context?: LayoutState) {
      super(props, context);

      this.state = {
         leftPannelExapanded: false,
         navItems: [],
      };
   }

   componentDidMount() {
      this.filterNavItems();
   }

   filterNavItems() {
      var nav = UpdateNavItems();
      this.setState({ navItems: nav });
   }
   componentDidUpdate(prevProps: LayoutProps) {
      if (prevProps !== this.props) this.filterNavItems();
   }

   handleLeftPanelToggle = () => {
      this.setState({ leftPannelExapanded: !this.state.leftPannelExapanded });
   };

   handleLeftPanelStateChange = (componentState: any) => {
      this.setState({ leftPannelExapanded: componentState.isOpen });
   };

   public render() {
      return (
         <div className={this.props.classes.root}>
            <CssBaseline />
            <Header
               isNavigationDrawerOpen={this.state.leftPannelExapanded}
               projectTitle="EA Technology"
               handleDrawerOpen={this.handleLeftPanelToggle.bind(this)}
            />
            <SideDrawer anchor="left" isOpen={this.state.leftPannelExapanded} handleDrawerClose={this.handleLeftPanelToggle.bind(this)}>
               <Navigation navigationItems={this.state.navItems} />
            </SideDrawer>
            <main
               className={classNames(this.props.classes.content, {
                  [this.props.classes.contentShift]: this.state.leftPannelExapanded,
               })}>
               <div className={this.props.classes.drawerHeader} id="back-to-top-anchor" />
               {this.props.children}
               <div className={this.props.classes.toolbar} />
               <div className={this.props.classes.toolbar} />
            </main>
            <Footer copyrightText="Copyright © EA Technology" />
         </div>
      );
   }
}

export default withStyles(styles, { withTheme: true })(Layout);
