import * as React from 'react';
import { SerialRangeGrid } from '../ProductTypes/SerialRangeGrid';
import { SerialRange, SerialRangeUtils } from '../Model/SerialRange';
import { useGridItemChangeCallback } from '../Shared/Utils/Changes/UseGridItemChangeCallback';
import { useChangeCallbacks } from '../Shared/Utils/Changes/UseChangeCallbacks';
import { useCallback, useState } from 'react';
import { updateById } from '../Shared/Utils/Changes/UpdateById';
import { ChangeType } from '../Shared/Utils/Changes/ChangeCallback';
import { GridRowClickEvent } from '@progress/kendo-react-grid';

interface TestEquipmentSerialRangesProps {
  serialRanges: SerialRange[];
  onChange: (ranges: SerialRange[]) => void;
  className?: string;
}

export function TestEquipmentSerialRanges({ serialRanges, onChange, className }: TestEquipmentSerialRangesProps) {
  const [editId, setEditId] = useState<number>();

  const reduceChange = useCallback((type: ChangeType, changed: SerialRange) => {
    switch (type) {
      case 'add':
        onChange([...serialRanges, changed]);
        break;
      case 'update':
        const updater = updateById(changed.id, (existing: SerialRange) => ({ ...existing, ...changed }));
        const updatedRanges = serialRanges.map(updater);
        onChange(updatedRanges);
        break;
      case 'delete':
        const withoutDeleted = serialRanges.filter(range => range.id !== changed.id);
        onChange(withoutDeleted);
        break;
    }
  }, [serialRanges, onChange]);

  // Ensure ID is incremented when creating a new serial range
  const createNewSerialRange = useCallback((): SerialRange => {
    const ids = serialRanges.map(range => range.id);
    const nextId = ids.length === 0 ? 0 :Math.max(...ids) + 1;
    return { ...SerialRangeUtils.default, id: nextId }
  }, [serialRanges]);

  // Split reducer into individual callbacks
  const [
    handleAdd,
    handleItemEdit,
    handleItemDelete,
  ] = useChangeCallbacks(serialRanges, reduceChange, createNewSerialRange, setEditId);

  // Grid event handlers
  const handleGridItemChange = useGridItemChangeCallback(serialRanges, reduceChange);
  const handleRowClick = useCallback((event: GridRowClickEvent) => handleItemEdit(event.dataItem), [handleItemEdit]);

  return (
    <SerialRangeGrid
      serialRanges={serialRanges}
      onAdd={handleAdd}
      onDelete={handleItemDelete}
      onRowClick={handleRowClick}
      onItemChange={handleGridItemChange}
      editId={editId}
      className={className}
    />
  );
}