import React from 'react';
import { RecordedCalibrationDetails } from '../../../Model/TestEquipment';
import { TestEquipmentCard, TestEquipmentCardBody, warningCardText } from '../TestEquipmentCard';
import { useCalibrationExpiry } from './Hooks/UseCalibrationExpiry';
import { CommonTestEquipmentCardProps } from './Types';
import TestEquipmentDescription from './TestEquipmentDescription';

interface CalibratedTestEquipmentCardProps extends CommonTestEquipmentCardProps {
  assetTag: string;
  description: string;
  deviceOwner: string;
  location: string;
  calibration: RecordedCalibrationDetails;
}

export function CalibratedTestEquipmentCard({ calibration, deviceOwner, location, description, ...cardProps }: CalibratedTestEquipmentCardProps) {
  const { expires } = calibration;
  const { expired, validityDuration } = useCalibrationExpiry(expires);

  return (
    <TestEquipmentCard
      {...cardProps}
      variant={expired ? 'warning' : 'valid'}
    >
      <TestEquipmentCardBody>
        <TestEquipmentDescription description={description}/>
        <p>Last calibrated by {calibration.house.name} on {calibration.date.toDateString()}</p>
        {
          expired
            ? <p className={warningCardText}>Calibration expired {validityDuration.humanize()} ago!</p>
            : <p className="text-success">Valid for {validityDuration.humanize()}</p>
        }
      </TestEquipmentCardBody>
    </TestEquipmentCard>
  );
}

