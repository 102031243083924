import React from 'react';
import { useActionCell } from '../Shared/Grids/UseActionCell';
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps';
import { ActionButton } from '../Shared/ActionButton';
import IProduct from '../Model/IProduct';

interface ProductsGridActionsCellProps extends GridCellProps {
  onFilterParent: (dataItem: IProduct) => void;
  onReport: (dataItem: IProduct) => void;
  onShowTestEquipment: (dataItem: IProduct) => void;
}

export const ProductsGridActionsCell: React.FC<ProductsGridActionsCellProps> = ({ onReport, onFilterParent, onShowTestEquipment, ...gridCellProps }) => {
  const ActionCell = useActionCell({
    onFilter: onFilterParent,
    onReport: onReport,
    additionalButtons: (dataItem: IProduct) => [
      <ActionButton icon="vial" label="Test Equipment" dataItem={dataItem} onClick={onShowTestEquipment}/>
    ],
  });

  return (
    <ActionCell {...gridCellProps} />
  );
};